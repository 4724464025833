import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "../styles/views/securityrules";


const SecurityRules = (props: any) => {
  const classes = useStyles();
  const url = `${process.env.REACT_APP_REDIRECT_URL}Safety and security information.pdf`;

  useEffect(() => {
    props.updateHeaderTitle("Security rules");
  }, []);

  return (
    <div style={{marginTop: '50px'}}>
      <Typography variant="h5">To read the Safety & security information follow the link below.</Typography><br />
      <Typography variant="h5">
        <a className={classes.link} href={url} target="_blank" rel="noopener noreferrer">Safety & security information</a>
      </Typography>
    </div>
  );
};

export default SecurityRules;