import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";

import { Link } from "react-router-dom";
import useStyles from "../styles/views/for04page";

const Four04Page = (props: any) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <Typography variant="h3">
          The world is <span className={classes.upsideDown}>!nwod edispu </span>
        </Typography>
        <Typography variant="h5">
          You have found a page that doesn't exist. How is that even possible?
        </Typography>
        <Typography variant="h5">
          Nothing will happen here so I suggest you to{" "}
          <Link style={{ color: "#0077c1", textDecoration: "none" }} to="/">
            go back{" "}
          </Link>
          where things are for real.
        </Typography>
      </div>
    </>
  );
};

export default Four04Page;
