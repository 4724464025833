import { withWidth } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingLinear: {
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    iconButton: {
      padding: theme.spacing(0),
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(6),
      marginTop: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 250,
    },
    dateTimeWrapper: {
      paddingBottom: theme.spacing(4),
      display: "flex",
      width: "1000px",
      justifyContent: "space-around",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "50px",
    },
    createTourButton: {
      backgroundColor: "#0077c1",
      fontSize: "18px",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#0084d6",
      },
    },
    dialog: {
      width: "70%",
    },
    selectLabel: {
      color: "#0077c1",
    },
    underlineSelect: {
      "&:before": {
        borderColor: "#0077c1",
      },
      "&:after": {
        borderColor: "#0077c1",
      },
    },
    dialogButtons: {
      color: "#0077c1",
    },
    label: {
      "&$focusedLabel": {
        color: "#0077c1",
      },
      "&$erroredLabel": {
        /* color: "orange" */
      },
    },
    inputLabel: {
      "&.Mui-focused": {
        color: "#0077c1",
      },
    },
    focusedLabel: {},
    erroredLabel: {},
    underline: {
      "&$error:after": {
        /*  borderBottomColor: "orange" */
      },
      "&:after": {
        borderBottom: `2px solid #0077c1`,
      },
      "&:before": {
        borderColor: "#0077c1",
      },
    },
    dialogPaper: {
      minHeight: "350px",
      maxHeight: "350px",
    },
    errorMsg: {
      color: "#ff4f4f",
    },
    teamsLink: {
      width: "100%",
      display: "flex",
      marginTop: '10px'
    },
    teamsLinkWrapper: {
      display: "flex",
      alignItems: "flex-end"
    },
    unlockTeamsLinkButton: {
      
      
    }
  })
);

export default useStyles