import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import WebIcon from "@material-ui/icons/Web";
import LinkIcon from "@material-ui/icons/Link";
import SaveIcon from "@material-ui/icons/Save";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import MessageIcon from "@material-ui/icons/Message";
import HelpIcon from "@material-ui/icons/Help";
import ExcelExport from "../components/ExcelExport";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import CommentIcon from '@material-ui/icons/Comment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import {
  Paper,
  Grid,
  IconButton,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  TextField,
  InputAdornment,
  Snackbar,
  Divider,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";

import { UseUser } from "../context/UserContext";
import axios from "axios";
import moment from "moment";
import useStyles from "../styles/views/tourdetails";

const TourDetails = (props: any) => {
  // det ifrån row ska också in.

  const classes = useStyles();

  const [time, setTime] = useState(moment().set({ hour: 10, minute: 0 }));
  const [date, setDate] = useState(undefined);
  const [errorMsgTeams, setErrorMsgTeams] = useState("");
  const [teamsLink, setTeamsLink] = useState("");
  const [isAdminEditor, setIsAdminEditor] = useState(false);
  const [openSnackBarComment, setOpenSnackBarComment] = useState(false);
  const [openSnackBarCustomMessage, setOpenSnackBarCustomMessage] =
    useState(false);
  const [openSnackBarResendSignDocLink, setOpenSnackBarResendSignDocLink] =
    useState(false);
  const [openSnackBarDelete, setOpenSnackBarDelete] = useState(false);
  const [openSnackBarTourDone, setOpenSnackBarTourDone] = useState(false);
  const [user, setUser] = useState({});
  const [adminEditor, setAdminEditor] = useState(true);
  const [adminRole, setAdminRole] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDeleteBooking, setOpenDialogDeleteBooking] = useState(false);
  const [openDoneDialog, setOpenDoneDialog] = useState(false);
  const [openSendMessageDialog, setOpenSendMessageDialog] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [contentLoader, setContentLoader] = useState(false);
  const [activeCell, setActiveCell] = useState();
  const [tableData, setTableData] = useState({
    row: {
      bookings: [],
      slotid: "",
      slottime: "",
      slotstatus: "",
      locationName: "",
      expires: "",
      format: "",
      nbrofbookings: "",
      slotcount: "",
      createdby: "",
      lastchangedby: "",
      id: "",
      meetinglink: "",
      hasalreadybeenclosed: "",
      comment: "",
      user: "",
      cds: "",
      serix: "",
      anonymize: "",
      created: "",
    },
  });
  const [CDSLoading, setCDSLoading] = useState(false);
  const [bookingToDelete, setBookingToDelete] = useState("");
  const [adminSites, setAdminSites] = useState([]);
  const [propFromTours, setPropFromTours] = useState(props.location.state);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortHeader, setSortHeader] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgTourEdit, setErrorMsgTourEdit] = useState("");
  const [arrow, setArrow] = useState(
    <ArrowDropUpIcon style={{ pointerEvents: "none" }}></ArrowDropUpIcon>
  );
  const [locations, setLocations] = useState([]);
  const [currLocation, setCurrLoction] = useState("");
  const [tourComment, setTourComment] = useState("");
  const [errorMsgTourCommentsAndPart, setErrorMsgTourCommentsAndPart] =
    useState("");
  const [errorMsgResendSignDocLink, setErrorMsgResendSignDocLink] =
    useState("");
  const [errorMsgDelete, setErrorMsgDelete] = useState("");
  const [errorMsgCustomMessage, setErrorMsgCustomMessage] = useState("");
  const [tourDoneMessage, setTourDoneMessage] = useState("");
  const [resendSignDocLinkMessage, setResendSignDocLinkMessage] = useState("");

  const [sendMessageToParticipants, setSendMessageToParticipants] =
    useState("");

  const [idToUseInMessageAndComments, setIdToUseInMessageAndComments] =
    useState("");
  const [copyTextToClipboard, setCopyTextToClipboard] = useState("");
  const [openDialogEditTour, setOpenDialogEditTour] = useState(false);
  const [CDSIndex, setCDSIndex] = useState(undefined as any);
  const [commentDiff, setCommentDiff] = useState(false);
  const [openCommentDialog, setOpenCommentDialog] = useState(false)
  const [commentDialogUser, setCommentDialogUser] = useState('')
  const [userToDelete, setUserToDelete] = useState('')
  const [userComment, setUserComment] = useState('')
  const [openSnackBarUserComment, setOpenSnackBarUserComment] = useState(false)
  const [userId, setUserId] = useState('')
  const [errorMsgUserComment, setErrorMsgUserComment] = useState('')
  const [resendCalendarEvent, setResendCalendarEvent] = useState(false);
  const commentRef = useRef(null);

  const myAdminSites: any = [];
  const getUserFromContext = async () => {
    const getUser = await UseUser();
    setUser(getUser);
    //@ts-ignore
  };

  getUserFromContext();

  useEffect(() => {
    props.updateHeaderTitle("Tour details");
  }, []);

  useEffect(() => {
    const responseArr: any = [];

    const func = async () => {
      if (Object.keys(user).length === 0) {
        return;
      }
      //@ts-ignore
      user.admineditorlocations.forEach((location: any) => {
        myAdminSites.push({
          adminType: "editor",
          locationId: location,
        });
      });
      //@ts-ignore
      user.adminreaderlocations.forEach((location: any) => {
        //@ts-ignore
        if (user.admineditorlocations.includes(location)) {
          return;
        }
        myAdminSites.push({
          adminType: "reader",
          locationId: location,
        });
      });

      if (myAdminSites.length < 1) {
        setIsLoading(false);
        return;
      }

      const tourDate = props.match.params.id.split("+");
      myAdminSites.forEach((site: any) => {
        const url = `/admin/locations/${site.locationId}?includeSlots=true&date=${moment(
          tourDate[1]
        ).format("YYYY-MM-DD")}&month=true`;

        const response = axios({
          method: "GET",
          url: url,
        });

        responseArr.push(response);
      });

      await axios
        .all(responseArr)
        .then(
          axios.spread((...responses: any) => {
            setLocations(responses);
            setIsLoading(false);
            setAdminSites(myAdminSites);

            const tourId = props.match.params.id.split("+");
            let foundTour = {};
            let foundLocation = {};

            responses.forEach((location: any) => {
              const found = location.data.slots.find((tour: any) => {
                return tour.id === tourId[0];
              });

              if (found) {
                foundLocation = location;
                foundTour = found;
              }
            });

            if (Object.keys(foundTour).length === 0) {
              props.history.push("/404");
              return;
            }

            //@ts-ignore

            if (Object.keys(foundLocation).length === 0) {
              return;
            }

            //@ts-ignore
            setCurrLoction(foundLocation.data.name);
            //@ts-ignore
            setTourComment(foundTour.comment);
            //@ts-ignore
            changeDate(foundTour.slottime);
            //@ts-ignore
            changeTime(foundTour.slottime);

            const findObj = myAdminSites.find((sites: any) => {
              //@ts-ignore
              if (sites.locationId === foundLocation.data.id) {
                return sites;
              }
            });
            if (!findObj) {
              return;
            }

            //@ts-ignore
            if (findObj.adminType === "editor") {
              setIsAdminEditor(true);
            }

            //@ts-ignore
            //setTableData(foundTour)
            setTableData({ row: foundTour } as any);
          })
        )
        .catch((err: any) => {
          console.log(err);
        });
    };

    func();
  }, [user]);

  const handleChangeTeamsLink = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setErrorMsgTeams("");
    //@ts-ignore
    setTeamsLink(event.target.value as any);
  };

  const createData = (
    format: any,
    fullname: any,
    username: any,
    attended: any,
    manager: any,
    slotid: any,
    id: any,
    termsaccepted: any,
    signeddoc: any,
    cds: any,
    anonymize: any,
    givenname: any,
    surname: any,
    serix: any,
    comment: any
  ) => {
    return {
      format,
      fullname,
      username,
      attended,
      manager,
      slotid,
      id,
      termsaccepted,
      signeddoc,
      cds,
      anonymize,
      givenname,
      surname,
      serix,
      comment
    };
  };

  let rows: any = [];
  try {
    //@ts-ignore
    rows = tableData.row.bookings.map((booking: any) => {
      //@ts-ignore
      return createData(
        booking.format,
        booking.fullname,
        booking.user,
        booking.attended,
        booking.manager,
        booking.slotid,
        booking.id,
        booking.termsaccepted,
        booking.signeddoc,
        booking.cds,
        booking.anonymize,
        booking.givenname,
        booking.surname,
        booking.serix,
        booking.comment
      );
    });
  } catch (err) {
    props.history.push("/");
  }

  const headCells = [
    "Participant email",
    "Participant name",
    "Participated",
    "Signed doc",
    "Serix",
    "CDS",
    "Anonymize",
    "Delete",
    "Comment"
  ];

  // test sorting table functions
  const sortFunction = (head: any, sortOrder: any) => {
    let value = "";

    if (head === "Participant email") {
      value = "user";
    } else if (head === "Participant name") {
      value = "name";
    } else if (head === "Participated") {
      value = "attended";
    } else if (head === "Signed doc") {
      value = "signeddoc";
    } else if (head === "Serix") {
      value = "serix";
    } else if (head === "CDS") {
      value = "cds";
    } else if (head === "Anonymize") {
      value = "anonymize";
    } else if (value === "") {
      return;
    }
    const newData = { ...tableData };

    if (value !== sortHeader) {
      newData.row.bookings.sort(function (a: any, b: any) {
        if (a[value] < b[value]) {
          return -1;
        }
        if (b[value] < a[value]) {
          return 1;
        }
        return 0;
      });
      setSortOrder("desc");
      setSortHeader(value);
      setTableData(newData);
      setArrow(
        <ArrowDropDownIcon
          style={{ pointerEvents: "none" }}
        ></ArrowDropDownIcon>
      );
    } else if (sortOrder === "asc") {
      newData.row.bookings.sort(function (a: any, b: any) {
        if (a[value] < b[value]) {
          return -1;
        }
        if (b[value] < a[value]) {
          return 1;
        }
        return 0;
      });
      setSortOrder("desc");
      setSortHeader(value);
      setTableData(newData);
      setArrow(
        <ArrowDropDownIcon
          style={{ pointerEvents: "none" }}
        ></ArrowDropDownIcon>
      );
    } else if (sortOrder === "desc") {
      newData.row.bookings.sort(function (a: any, b: any) {
        if (a[value] > b[value]) {
          return -1;
        }
        if (b[value] > a[value]) {
          return 1;
        }
        return 0;
      });
      setSortOrder("asc");
      setSortHeader(value);
      setTableData(newData);
      setArrow(
        <ArrowDropUpIcon style={{ pointerEvents: "none" }}></ArrowDropUpIcon>
      );
    }
  };
  const activeCellFn = (e: any) => {
    setActiveCell(e.target.textContent);
  };

  const prepareDeleteBooking = (bool: boolean, id: any, username: string) => {
    setBookingToDelete(id as any);
    setUserToDelete(username)
    setOpenDialogDeleteBooking(true);
  };

  const handleClose = async () => {
    setErrorMsg("");
    setOpenDialog(false);
  };
  const handleCloseDialogDeleteBooking = async () => {
    setErrorMsgDelete("");
    setOpenDialogDeleteBooking(false);
  };

  const handleDoneClose = async () => {
    setErrorMsg("");
    setOpenDoneDialog(false);
  };
  const handleSendMessageDialogClose = async () => {
    setContentLoader(true);

    setTourComment(tableData.row.comment);

    setContentLoader(false);
    setOpenSendMessageDialog(false);

    setErrorMsg("");
  };

  const tourDone = async (bool: boolean) => {
    const slotId = tableData.row.id;
    const slottime = tableData.row.slottime;
    const expires = tableData.row.expires;
    const slotcount = tableData.row.slotcount;
    const format = tableData.row.format;
    const comment = tableData.row.comment;
    const meetinglink = tableData.row.meetinglink;
    const hasalreadybeenclosed = tableData.row.hasalreadybeenclosed;

    const newData = {
      slotstatus: bool,
      slottime,
      expires,
      slotcount,
      format,
      comment,
      meetinglink,
      hasalreadybeenclosed,
      resendsigndoclink: false,
      custommessage: "",
    };
    try {
      const response = await axios({
        method: "PUT",
        url: `/admin/slots/${slotId}`,
        data: newData,
      });

      if (response && response.status === 200) {
        const modifiedData: any = { ...tableData };
        modifiedData.row.slotstatus = bool;
        modifiedData.row.meetinglink = meetinglink;
        modifiedData.row.comment = comment;
        if (bool) {
          modifiedData.row.hasalreadybeenclosed = bool;
        }

        setTableData(modifiedData);

        handleDoneClose();

        if (!bool) {
          setTourDoneMessage("Tour status change to Open");
        } else {
          setTourDoneMessage("Tour status change to closed");
        }

        setOpenSnackBarTourDone(true);
      }
    } catch (err) {
      if (err.response.status === 401) {
        setErrorMsg("Error: You are not Authorized, try again");
      } else if (err.response.status === 403) {
        setErrorMsg("Error: You have no permission, try again");
      } else if (err.response.status === 422) {
        setErrorMsg("Error: You sent bad data, try again");
      } else if (err.request) {
        setErrorMsg("Error: Server responded with and error, try again");
      }
    }
  };

  const deleteTour = async (bool: boolean) => {
    if (bool) {
      const slotId = tableData.row.id;

      try {
        const response = await axios({
          method: "DELETE",
          url: `/admin/slots/${slotId}`,
        });

        if (response && response.status === 204) {
          handleClose();
          setOpenSnackBarDelete(true);
          setTimeout(() => {
            props.history.push("/admin/tours");
          }, 2000);
        }
      } catch (err) {
        if (err.response.status === 401) {
          setErrorMsg("Error: You are not Authorized, try again");
        } else if (err.response.status === 403) {
          setErrorMsg("Error: You have no permission, try again");
        } else if (err.response.status === 422) {
          setErrorMsg("Error: You sent bad data, try again");
        } else if (err.request) {
          setErrorMsg("Error: Server responded with and error, try again");
        }
      }
    } else {
      handleClose();
    }
  };

  const changeStatuses = async (obj: any, clicked: any) => {
    console.log(obj, clicked);
    const id = obj.id;
    if (obj.attended === null) {
      obj.attended = false;
    }
    let attended: any = obj.attended as any;
    let signeddoc: any = obj.signeddoc as any;
    let serix: any = obj.serix as any;
    let anonymize: any = obj.anonymize as any;

    if (clicked === "attended") {
      //@ts-ignore
      attended = !attended;
    }
    if (clicked === "signeddoc") {
      signeddoc = !signeddoc;
    }
    if (clicked === "serix") {
      serix = !serix;
    }
    if (clicked === "anonymize") {
      anonymize = !anonymize;
    }

    console.log("test");

    const newDataObj = {
      attended,
      signeddoc,
      serix,
      cds: obj.cds,
      anonymize,
    };

    try {
      const response = await axios({
        method: "PUT",
        url: `/admin/bookings/${id}`,
        data: newDataObj,
      });

      if (response && response.status === 200) {
        const newData = { ...tableData };

        const findObj: any = newData.row.bookings.find((x: any) => {
          return x.id === id;
        });

        if (findObj) {
          findObj.attended = attended;
          findObj.signeddoc = signeddoc;
          findObj.serix = serix;
          findObj.cds = obj.cds;
          findObj.anonymize = anonymize;
        }

        setTableData(newData);
      }
    } catch (err) {
      if (err.response.status === 401) {
        setErrorMsg("Error: You are not Authorized, try again");
      } else if (err.response.status === 403) {
        setErrorMsg("Error: You have no permission, try again");
      } else if (err.response.status === 422) {
        setErrorMsg("Error: You sent bad data, try again");
      } else if (err.request) {
        setErrorMsg("Error: Server responded with and error, try again");
      }
      //setErrorMsg("Something went wrong, try again.");
    }
  };

  const saveEditTour = async (comment = false) => {
    if (comment) setCommentDiff(false);
    const slotId = tableData.row.id;
    const oldDate = moment(tableData.row.slottime).isDST();
    const newDate = moment(date).isDST();
    let slottime;
    let expires;

    slottime = moment(
      `${moment(date).format("YYYY-MM-DD")}T${moment(time).format("HH:mm")}`
    )
      .tz("Europe/Stockholm")
      .format();
    expires = moment(
      `${moment(date).add(1, "month").format("YYYY-MM-DD")}T${moment(
        time
      ).format("HH:mm")}`
    )
      .tz("Europe/Stockholm")
      .format();
    /*  if (oldDate === newDate) {
      slottime = `${moment(date).format("YYYY-MM-DD")}T${moment(time)
        .utc()
        .format("HH:mm:ss.ms")}Z`;
      expires = `${moment(date).add(1, "month").format("YYYY-MM-DD")}T${moment(
        time
      )
        .utc()
        .format("HH:mm:ss.ms")}Z`;
    } else {
      slottime = moment(time).isDST()
        ? `${moment(date).format("YYYY-MM-DD")}T${moment(time)
            .utc()
            .add(1, "hours")
            .format("HH:mm:ss.ms")}Z`
        : `${moment(date).format("YYYY-MM-DD")}T${moment(time)
            .utc()
            .subtract(1, "hours")
            .format("HH:mm:ss.ms")}Z`;

      expires = moment(time).isDST()
        ? `${moment(date).add(1, "month").format("YYYY-MM-DD")}T${moment(time)
            .utc()
            .add(1, "hours")
            .format("HH:mm:ss.ms")}Z`
        : `${moment(date).add(1, "month").format("YYYY-MM-DD")}T${moment(time)
            .utc()
            .subtract(1, "hours")
            .format("HH:mm:ss.ms")}Z`;
    } */

    const slotcount = tableData.row.slotcount;
    const format = tableData.row.format;
    const slotstatus = tableData.row.slotstatus;
    const meetinglink = comment ? tableData.row.meetinglink : teamsLink;
    const hasalreadybeenclosed = tableData.row.hasalreadybeenclosed;

    const newData = {
      slotstatus,
      slottime,
      expires,
      slotcount,
      format,
      //@ts-ignore
      comment: comment ? commentRef.current.value : tourComment,
      meetinglink,
      hasalreadybeenclosed,
      resendsigndoclink: false,
      custommessage: "",
    };
    if (tableData.row.format === "Online") {
      const testLink = isValidHttpUrl(meetinglink);

      if (!testLink) {
        return setErrorMsgTeams(
          "The link you inserted is not in correct link format."
        );
      }
    }

    try {
      const response = await axios({
        method: "PUT",
        url: `/admin/slots/${slotId}`,
        data: newData,
      });

      if (response && response.status === 200) {
        const modifiedData: any = { ...tableData };
        modifiedData.row.comment = tourComment;
        modifiedData.row.slottime = slottime;
        modifiedData.row.expires = expires;
        modifiedData.row.meetinglink = meetinglink;
        setTableData(modifiedData);
        handleCloseDialogEditTour();
        setOpenSnackBarComment(true);

        const index = props.match.params.id.indexOf("+");
        const id = props.match.params.id.slice(0, index);

        const newDate = slottime.slice(0, 7);
        const newIdAndDate = `${id}+${newDate}`;
        //props.match.params.id = newIdAndDate

        props.history.push({
          pathname: `/admin/tours/tourdetails/${newIdAndDate}`,
          //@ts-ignore
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        setErrorMsgTourEdit(
          "A slot with the same tour time is already created."
        );
      } else if (err.response.status === 401) {
        setErrorMsgTourEdit("Error: You are not Authorized, try again");
      } else if (err.response.status === 403) {
        setErrorMsgTourEdit("Error: You have no permission, try again");
      } else if (err.response.status === 422) {
        setErrorMsgTourEdit("Error: You sent bad data, try again");
      } else if (err.request) {
        setErrorMsgTourEdit(
          "Error: Server responded with and error, try again"
        );
      }
    }
  };

  const updateMessageToParticipants = (e: any) => {
    setSendMessageToParticipants(e.target.value);
  };
  const updateTourComment = (e: any) => {
    setTourComment(e.target.value);
  };
  const handleCloseSnackBarComment = () => {
    setOpenSnackBarComment(false);
  };
  const handleCloseSnackBarResendSignLink = () => {
    setOpenSnackBarResendSignDocLink(false);
  };
  const handleCloseSnackBarDelete = () => {
    setOpenSnackBarDelete(false);
  };
  const handleCloseSnackBarTourDone = () => {
    setOpenSnackBarTourDone(false);
  };
  const handleCloseSnackBarCustomMessage = () => {
    setOpenSnackBarCustomMessage(false);
  };
  const handleCloseSnackBarUserComment = () => {
    setOpenSnackBarUserComment(false)
  }

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const sendCustomMessage = async () => {
    // Do when backend done

    if (!sendMessageToParticipants) {
      return;
    }

    const slotId = tableData.row.id;
    const slottime = tableData.row.slottime;
    const expires = tableData.row.expires;
    const slotcount = tableData.row.slotcount;
    const format = tableData.row.format;
    const slotstatus = tableData.row.slotstatus;
    const meetinglink = tableData.row.meetinglink;
    const hasalreadybeenclosed = tableData.row.hasalreadybeenclosed;
    const comment = tableData.row.comment;

    const newData = {
      slotstatus,
      slottime,
      expires,
      slotcount,
      format,
      comment,
      meetinglink,
      hasalreadybeenclosed,
      resendsigndoclink: false,
      custommessage: sendMessageToParticipants,
      resendCalendarEvent,
    };

    try {
      const response = await axios({
        method: "PUT",
        url: `/admin/slots/${slotId}`,
        data: newData,
      });

      if (response && response.status === 200) {
        setOpenSnackBarCustomMessage(true);
        setSendMessageToParticipants("");
      }
    } catch (err) {
      if (err.response.status === 401) {
        setErrorMsgTourCommentsAndPart(
          "Error: You are not Authorized, try again"
        );
        setTimeout(() => {
          setErrorMsgTourCommentsAndPart("");
        }, 3000);
      } else if (err.response.status === 403) {
        setErrorMsgTourCommentsAndPart(
          "Error: You have no permission, try again"
        );
        setTimeout(() => {
          setErrorMsgTourCommentsAndPart("");
        }, 3000);
      } else if (err.response.status === 422) {
        setErrorMsgTourCommentsAndPart("Error: You sent bad data, try again");
        setTimeout(() => {
          setErrorMsgTourCommentsAndPart("");
        }, 3000);
      } else if (err.request) {
        setErrorMsgTourCommentsAndPart(
          "Error: Server responded with and error, try again"
        );
        setTimeout(() => {
          setErrorMsgTourCommentsAndPart("");
        }, 3000);
      }
    }
  };

  const deleteBooking = async () => {
    try {
      const response = await axios({
        url: `admin/bookings/${bookingToDelete}`,
        method: "DELETE",
      });

      if (response && response.status === 204) {
        refreshData();

        setOpenDialogDeleteBooking(false);
      }
    } catch (err) {
      if (err.response.status === 401) {
        setErrorMsgDelete("Error: You are not Authorized, try again");
      } else if (err.response.status === 403) {
        setErrorMsgDelete("Error: You have no permission, try again");
      } else if (err.response.status === 422) {
        setErrorMsgDelete("Error: You sent bad data, try again");
      } else if (err.request) {
        setErrorMsgDelete("Error: Server responded with and error, try again");
      }
    }
  };

  const changeDate = (date: any) => {
    if (moment(date).format("YYYY-MM-DD-HH:mm") === "Invalid date") {
      return;
    }

    const dateToSet: any = moment(date);

    setDate(dateToSet);
  };
  const changeTime = (time: any) => {
    const timeToSet: any = moment(time);
    setTime(timeToSet);
  };

  const sendSignedDocLinkAgain = async () => {
    // Do when backend done
    const slotId = tableData.row.id;
    const slottime = tableData.row.slottime;
    const expires = tableData.row.expires;
    const slotcount = tableData.row.slotcount;
    const format = tableData.row.format;
    const slotstatus = tableData.row.slotstatus;
    const meetinglink = tableData.row.meetinglink;
    const hasalreadybeenclosed = tableData.row.hasalreadybeenclosed;
    const comment = tableData.row.comment;

    const newData = {
      slotstatus,
      slottime,
      expires,
      slotcount,
      format,
      comment,
      meetinglink,
      hasalreadybeenclosed,
      resendsigndoclink: true,
      custommessage: "",
    };

    try {
      const response = await axios({
        method: "PUT",
        url: `/admin/slots/${slotId}`,
        data: newData,
      });

      if (response && response.status === 200) {
        setOpenSnackBarResendSignDocLink(true);
      }
    } catch (err) {
      if (err.response.status === 401) {
        setErrorMsgTourCommentsAndPart(
          "Error: You are not Authorized, try again"
        );
        setTimeout(() => {
          setErrorMsgTourCommentsAndPart("");
        }, 3000);
      } else if (err.response.status === 403) {
        setErrorMsgTourCommentsAndPart(
          "Error: You have no permission, try again"
        );
        setTimeout(() => {
          setErrorMsgTourCommentsAndPart("");
        }, 3000);
      } else if (err.response.status === 422) {
        setErrorMsgTourCommentsAndPart("Error: You sent bad data, try again");
        setTimeout(() => {
          setErrorMsgTourCommentsAndPart("");
        }, 3000);
      } else if (err.request) {
        setErrorMsgTourCommentsAndPart(
          "Error: Server responded with and error, try again"
        );
        setTimeout(() => {
          setErrorMsgTourCommentsAndPart("");
        }, 3000);
      }
    }
  };
  const copyMeetingLink = (link: any) => {
    const copied = document.execCommand("copy");
    navigator.clipboard.writeText(link);

    if (copied) {
      setCopyTextToClipboard("meeting link copied to clipboard");
    }
  };

  const refreshData = async () => {
    setIsLoading(true);

    const responseArr: any = [];

    const func = async () => {
      if (Object.keys(user).length === 0) {
        return;
      }
      //@ts-ignore
      user.admineditorlocations.forEach((location: any) => {
        myAdminSites.push({
          adminType: "editor",
          locationId: location,
        });
      });
      //@ts-ignore
      user.adminreaderlocations.forEach((location: any) => {
        //@ts-ignore
        if (user.admineditorlocations.includes(location)) {
          return;
        }
        myAdminSites.push({
          adminType: "reader",
          locationId: location,
        });
      });

      if (myAdminSites.length < 1) {
        setIsLoading(false);
        return;
      }
      myAdminSites.forEach((site: any) => {
        const url = `/admin/locations/${site.locationId}?includeSlots=true&date=${moment(
          tableData.row.slottime
        ).format("YYYY-MM-DD")}&month=true`;

        const response = axios({
          method: "GET",
          url: url,
        });

        responseArr.push(response);
      });

      await axios
        .all(responseArr)
        .then(
          axios.spread((...responses: any) => {
            setLocations(responses);
            setAdminSites(myAdminSites);

            const tourId = props.match.params.id.split("+");

            let foundTour = {};
            let foundLocation = {};

            responses.forEach((location: any) => {
              const found = location.data.slots.find((tour: any) => {
                return tour.id === tourId[0];
              });

              if (found) {
                foundLocation = location;
                foundTour = found;
              }
            });

            if (Object.keys(foundTour).length === 0) {
              props.history.push("/404");
              return;
            }

            //@ts-ignore

            if (Object.keys(foundLocation).length === 0) {
              return;
            }

            //@ts-ignore
            setCurrLoction(foundLocation.data.name);
            //@ts-ignore
            setTourComment(foundTour.comment);
            //@ts-ignore
            changeDate(foundTour.slottime);
            //@ts-ignore
            changeTime(foundTour.slottime);

            const findObj = myAdminSites.find((sites: any) => {
              //@ts-ignore
              if (sites.locationId === foundLocation.data.id) {
                return sites;
              }
            });
            if (!findObj) {
              return;
            }

            //@ts-ignore
            if (findObj.adminType === "editor") {
              setIsAdminEditor(true);
            }

            //@ts-ignore
            //setTableData(foundTour)
            setTableData({ row: foundTour } as any);
          })
        )
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
        });
      setIsLoading(false);
    };

    func();
  };
  const refreshParticipantCDS = async (obj: any, index: any) => {
    if (obj.attended === null) {
      obj.attended = false;
    }

    setCDSIndex(index);
    setCDSLoading(true);

    let cds = false;

    const cdsLocation: any = locations.find((location: any) => {
      return location.data.name === currLocation;
    });

    const slotCdsLocation = cdsLocation?.data.cdslocation;

    try {
      const response: any = await axios(`/user/userdata/${obj.username}`);

      if (
        response.data.cdslocation.toLowerCase() ===
        slotCdsLocation.toLowerCase()
      ) {
        cds = true;
      }

      const newDataObj = {
        attended: obj.attended,
        signeddoc: obj.signeddoc,
        serix: obj.serix,
        cds: cds,
        anonymize: obj.anonymize,
      };

      try {
        const response = await axios({
          method: "PUT",
          url: `/admin/bookings/${obj.id}`,
          data: newDataObj,
        });

        if (response && response.status === 200) {
          const newData = { ...tableData };

          const findObj: any = newData.row.bookings.find((x: any) => {
            return x.id === obj.id;
          });
          if (findObj) {
            findObj.attended = obj.attended;
            findObj.signeddoc = obj.signeddoc;
            findObj.serix = obj.serix;
            findObj.cds = cds;
            findObj.anonymize = obj.anonymize;
          }
          setTableData(newData);
          setCDSLoading(false);
          setCDSIndex(undefined);
        }
      } catch (err) {
        console.log(err);
        setCDSLoading(false);
        setCDSIndex(undefined);
      }
    } catch (err) {
      console.log(err);

      if (err.response.status === 404 || err.response.status === 422) {
        const newData = { ...tableData };

        const findObj: any = newData.row.bookings.find((x: any) => {
          return x.id === obj.id;
        });
        if (findObj) {
          findObj.attended = obj.attended;
          findObj.signeddoc = obj.signeddoc;
          findObj.serix = obj.serix;
          findObj.cds = false;
          findObj.anonymize = obj.anonymize;
        }
        const response = await axios({
          method: "PUT",
          url: `/admin/bookings/${obj.id}`,
          data: findObj,
        });
        setTableData(newData);
      }

      setCDSLoading(false);
      setCDSIndex(undefined);
    }
  };

  function isValidHttpUrl(string: any) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const handleCloseDialogEditTour = () => {
    setTourComment(tableData.row.comment);
    setOpenDialogEditTour(false);
  };

  const checkForCommentDiff = () => {
    //@ts-ignore
    if (commentRef.current.value !== tourComment) setCommentDiff(true);
    else setCommentDiff(false);
  };

  const openDialogEdit = () => {
    changeDate(tableData.row.slottime);
    changeTime(tableData.row.slottime);
    setTeamsLink(tableData.row.meetinglink);

    setOpenDialogEditTour(true);
  };

  const setTempGivenname = (user: any) => {
    if (user.toLowerCase() === "deleted") return "deleted";
    const firstNameIndex = user.indexOf(".");
    const lastNameIndex = user.indexOf("@");
    let firstname = user.slice(0, firstNameIndex);

    const regExp = /[0-9]/g;

    if (regExp.test(firstname)) {
      firstname = firstname.replace(regExp, "");
    }

    let lastname = user.slice(firstNameIndex + 1, lastNameIndex);
    lastname = lastname.charAt(0).toUpperCase() + lastname.slice(1);
    firstname = firstname.charAt(0).toUpperCase() + firstname.slice(1);

    return `${firstname} ${lastname}`;
  };
  const handleOpenCommentDialog = (username: string, comment: string, id: string) => {
    setCommentDialogUser(username)
    setUserComment(comment)
    setUserId(id)
    setOpenCommentDialog(true)
  }
  
  const handleCloseCommentDialog = () => {
    setCommentDialogUser('')
    setUserComment('')
    setUserId('')
    setErrorMsgUserComment('')
    setOpenCommentDialog(false)
  }
  const saveUserComment = async () => {
    console.log(userComment)
      try {

        const newData = {
          comment: userComment
        }

        const response = await axios({
          method: "PUT",
          url: `/admin/bookings/${userId}`,
          data: newData,
        });
        const newTableData = { ...tableData };
        console.log(response)

        const found = newTableData.row.bookings.find((booking: any) => {
          return booking.id === userId
        })
        console.log(response)
        console.log(found)
        console.log(userComment)
        if(found) {
          // @ts-ignore
          found.comment = userComment
          setTableData(newTableData)
          handleCloseCommentDialog()
          setOpenSnackBarUserComment(true)
        } else {
          return
        }
        

      } catch (err) {
          if (err.response.status === 401) {
            setErrorMsgUserComment("Error: You are not Authorized, try again");
          } else if (err.response.status === 403) {
            setErrorMsgUserComment("Error: You have no permission, try again");
          } else if (err.response.status === 422) {
            setErrorMsgUserComment("Error: You sent bad data, try again");
          } else if (err.request) {
            setErrorMsgUserComment("Error: Server responded with and error, try again");
          }
        }

      
    


  }

  if (!propFromTours) {
    setPropFromTours({
      row: {
        bookings: [],
        slotid: "",
        slottime: "",
        slotstatus: "",
        locationName: "",
        expires: "",
        format: "",
        nbrofbookings: "",
        slotcount: "",
        createdby: "",
        lastchangedby: "",
        id: "",
      },
    });

    //props.history.push('/')
  }
  if (loading) {
    return (
      <div color="primary" className={classes.loadingLinear}>
        <Typography variant="h6" component="h2">
          Loading...
        </Typography>
        <LinearProgress color="primary" />
      </div>
    );
  } else if (adminSites.length < 1) {
    return (
      <>
        <Typography variant="h4">
          You do not have the correct admin rights to show this page.
        </Typography>
        <Typography variant="h6">
          <Link to="/" style={{ color: "#0077c1" }}>
            Go back to main page
          </Link>
        </Typography>
      </>
    );
  } else {
    return (
      <>
        <div>
          {/* <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h4" component="h2">
                  Tour Details
                </Typography>
              </Paper>
            </Grid>
          </Grid> */}

          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.paperDetail}>
                <div className={classes.detailsTextAreaWrapper}>
                  <div className={classes.detailsAndRefreshWrapper}>
                    <Typography
                      className={classes.paperDetailHeader}
                      variant="h6"
                      component="h2"
                    >
                      {contentLoader ? (
                        <div className={classes.loadingLinear2}>
                          <Typography variant="h6" component="h2">
                            Loading...
                          </Typography>
                          <LinearProgress />
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <b>
                            Details for{" "}
                            {moment(tableData.row.slottime).format(
                              "YYYY-MM-DD - HH:mm"
                            )}
                            {/* {moment(tableData.row.created).format()} */}
                          </b>
                        </div>
                      )}
                      <br />
                    </Typography>
                  </div>

                  {!contentLoader && (
                    <Typography
                      variant="h6"
                      className={classes.paperDetailHeader}
                    >
                      <b>Tour comment</b>
                    </Typography>
                  )}
                </div>
                {contentLoader ? (
                  <div></div>
                ) : (
                  <div className={classes.tourCommentWrapper}>
                    {/* <Paper elevation={2}>
                        <div className={classes.tourCommentOnFirstPageWrapper}>
                          <div className={classes.tourCommentOnFirstPageContent}>
                            {contentLoader ? (
                              <div></div>
                            ) : (
                                <Typography variant="subtitle2">
                                  {tourComment}
                                </Typography>
                              )}
                          </div>
                        </div>
                      </Paper> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        inputRef={commentRef}
                        onChange={() => checkForCommentDiff()}
                        className={classes.textBox}
                        InputLabelProps={{
                          classes: {
                            root: classes.label,
                            focused: classes.focusedLabel,
                            error: classes.erroredLabel,
                          },
                        }}
                        InputProps={{
                          readOnly: !isAdminEditor,
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        id="outlined-multiline-static"
                        multiline
                        rowsMax={10}
                        placeholder={"Enter tour comment here..."}
                        defaultValue={tourComment}
                      />
                      {/* <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => { saveEditTour(true) }}
                          disabled={!commentDiff}
                        >Save</Button> */}
                      <Tooltip title="Save comment" placement="left" arrow>
                        <IconButton
                          onClick={() => saveEditTour(true)}
                          aria-label="save"
                        >
                          <SaveIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                )}

                <div className={classes.filterButtonsWrapper}>
                  <div className={classes.paperDetailHeaderWrapper}>
                    {contentLoader ? (
                      <div></div>
                    ) : (
                      <div className={classes.paperDetailHeaderWrapperLeft}>
                        <Tooltip title="Location" placement="left" arrow>
                          <div className={classes.iconTypoWrapper}>
                            <LocationCityIcon
                              className={classes.icon}
                            ></LocationCityIcon>
                            <Typography
                              className={classes.paperDetailHeader}
                              variant="button"
                              component="h2"
                            >
                              <b>{currLocation.toLowerCase()}</b>
                            </Typography>
                          </div>
                        </Tooltip>
                        {/*                         <Tooltip title="Expire date" placement="left" arrow>
                          <div className={classes.iconTypoWrapper}>
                            <QueryBuilderIcon
                              className={classes.icon}
                            ></QueryBuilderIcon>
                            <Typography
                              className={classes.paperDetailHeader}
                              variant="button"
                              component="h2"
                            >
                              <b>
                              {moment(tableData.row.expires).format(
                                    "YYYY-MM-DD - HH:mm"
                                  )}
                              </b>
                            </Typography>
                          </div>
                        </Tooltip> */}
                        <Tooltip title="Tour format" placement="left" arrow>
                          <div className={classes.iconTypoWrapper}>
                            <WebIcon className={classes.icon}></WebIcon>
                            <Typography
                              className={classes.paperDetailHeader}
                              variant="button"
                              component="h2"
                            >
                              <b>{tableData.row.format}</b>
                            </Typography>
                          </div>
                        </Tooltip>
                        <Tooltip title="Tour status" placement="left" arrow>
                          <div className={classes.iconTypoWrapper}>
                            <AssignmentIcon
                              className={classes.icon}
                            ></AssignmentIcon>
                            <Typography
                              className={classes.paperDetailHeader}
                              variant="button"
                              component="h2"
                            >
                              <b>
                                {!tableData.row.slotstatus ? (
                                  <span className={classes.statusOpen}>
                                    Open
                                  </span>
                                ) : (
                                  <span className={classes.statusClosed}>
                                    Closed
                                  </span>
                                )}
                              </b>
                            </Typography>
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    {contentLoader ? (
                      <div></div>
                    ) : (
                      <div className={classes.paperDetailHeaderWrapperRight}>
                        <Tooltip
                          title="No of Participants"
                          placement="left"
                          arrow
                        >
                          <div className={classes.iconTypoWrapper}>
                            <PeopleIcon className={classes.icon}></PeopleIcon>
                            <Typography
                              className={classes.paperDetailHeader}
                              variant="button"
                              component="h2"
                            >
                              <b>{tableData.row.nbrofbookings}</b>
                            </Typography>
                          </div>
                        </Tooltip>
                        <Tooltip
                          title="Max participants"
                          placement="left"
                          arrow
                        >
                          <div className={classes.iconTypoWrapper}>
                            <PeopleAltIcon
                              className={classes.icon}
                            ></PeopleAltIcon>
                            <Typography
                              className={classes.paperDetailHeader}
                              variant="button"
                              component="h2"
                            >
                              <b>{tableData.row.slotcount}</b>
                            </Typography>
                          </div>
                        </Tooltip>
                        <Tooltip title="Tour created by" placement="left" arrow>
                          <div className={classes.iconTypoWrapper}>
                            <AddIcon className={classes.icon}></AddIcon>
                            <Typography
                              className={classes.paperDetailHeader}
                              variant="button"
                              component="p"
                            >
                              <b style={{ textTransform: "lowercase" }}>
                                {tableData.row.createdby}
                              </b>
                            </Typography>
                          </div>
                        </Tooltip>
                        {/* <Tooltip title="Last edited by" placement="left" arrow>
                          <div className={classes.iconTypoWrapper}>
                            <EditIcon className={classes.icon}></EditIcon>
                            <Typography
                              className={classes.paperDetailHeader}
                              variant="button"
                              component="h2"
                            >
                              <b>{tableData.row.lastchangedby}</b>
                            </Typography>
                          </div>
                        </Tooltip> */}

                        {tableData.row.format === "Online" ? (
                          <div className={classes.iconTypoWrapper}>
                            <LinkIcon className={classes.icon}></LinkIcon>
                            <Tooltip
                              title="Meeting link"
                              placement="left"
                              arrow
                            >
                              <Typography
                                className={classes.paperDetailHeader}
                                variant="button"
                                component="h2"
                              >
                                <b>
                                  {tableData.row.meetinglink.substring(0, 8) +
                                    "..."}
                                </b>
                              </Typography>
                            </Tooltip>
                            <div
                              onClick={() =>
                                copyMeetingLink(tableData.row.meetinglink)
                              }
                            >
                              <Tooltip
                                title="Click to copy meetinglink to clipboard"
                                placement="left"
                                arrow
                              >
                                <FileCopyIcon
                                  className={classes.copyIcon}
                                ></FileCopyIcon>
                              </Tooltip>
                            </div>
                            <Typography
                              className={classes.copyToClipboard}
                              variant="caption"
                            >
                              {copyTextToClipboard}
                            </Typography>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className={classes.deleteDoneWrapper}>
                    {contentLoader ? (
                      <div></div>
                    ) : (
                      <Tooltip
                        title="Refresh tour details except CDS status. Click the refresh button in CDS column to update the CDS status for a co-worker."
                        placement="top-start"
                        arrow
                      >
                        <IconButton
                          onClick={refreshData}
                          aria-label="refresh"
                          className={classes.refreshButton}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    {isAdminEditor && !contentLoader ? (
                      <Tooltip title="Edit tour" placement="top-start" arrow>
                        <IconButton
                          onClick={openDialogEdit}
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <div></div>
                    )}

                    {!contentLoader ? (
                      <ExcelExport csvData={tableData.row} />
                    ) : (
                      <div></div>
                    )}

                    <Typography
                      className={classes.paperDetailHeader}
                      variant="caption"
                      component="h2"
                    >
                      {isAdminEditor && !contentLoader ? (
                        <Tooltip
                          title="Custom message and sign document link"
                          placement="top-start"
                          arrow
                        >
                          <IconButton
                            onClick={() => setOpenSendMessageDialog(true)}
                            className={classes.sendMessagesButton}
                          >
                            <MessageIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <div></div>
                      )}
                    </Typography>
                    <Typography
                      className={classes.paperDetailHeader}
                      variant="caption"
                      component="h2"
                    >
                      {isAdminEditor && !contentLoader ? (
                        <Tooltip
                          title="Change tour status"
                          placement="top-start"
                          arrow
                        >
                          <IconButton
                            onClick={() => setOpenDoneDialog(true)}
                            className={classes.doneButton}
                          >
                            <PlaylistAddCheckIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <div></div>
                      )}
                    </Typography>
                    <Typography
                      className={classes.paperDetailHeader}
                      variant="caption"
                      component="h2"
                    >
                      {isAdminEditor && !contentLoader ? (
                        <Tooltip
                          title="Delete tour"
                          placement="top-start"
                          arrow
                        >
                          <IconButton
                            onClick={() => setOpenDialog(true)}
                            className={classes.deleteButton}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <div></div>
                      )}
                    </Typography>
                  </div>
                </div>

                {contentLoader ? (
                  <div></div>
                ) : (
                  <div>
                    <TableContainer
                      className={classes.tableContainer}
                      elevation={1}
                      component={Paper}
                    >
                      <Table
                        stickyHeader
                        className={classes.table}
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            {headCells.map((head, index) =>
                              activeCell === head ? (
                                <TableCell
                                  className={classes.tableHeader}
                                  onClick={(e) => activeCellFn(e)}
                                  key={index}
                                  align="left"
                                >
                                  <span
                                    className={classes.activeCell}
                                    onClick={() =>
                                      sortFunction(head, sortOrder)
                                    }
                                  >
                                    <b className={classes.activeB}>{head}</b>
                                    <span className={classes.enableArrow}>
                                      {arrow}
                                    </span>
                                  </span>
                                </TableCell>
                              ) : (
                                <TableCell
                                  className={classes.tableHeader}
                                  onClick={(e) => activeCellFn(e)}
                                  key={index}
                                  align="left"
                                >
                                  <span
                                    className={classes.notActiveCell}
                                    onClick={() =>
                                      sortFunction(head, sortOrder)
                                    }
                                  >
                                    <b className={classes.notActiveB}>{head}</b>
                                    <span className={classes.disableArrow}>
                                      {arrow}
                                    </span>
                                  </span>
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row: any, index: any) => (
                            <TableRow style={{ margin: "0px" }} key={row.id}>
                              <TableCell
                                align="left"
                                style={
                                  row.username.toLowerCase() === "deleted" || row.username.toLowerCase() === "anonymized"
                                    ? { opacity: 0.5 }
                                    : undefined
                                }
                              >
                                {row.username === 'anonymized' ? row.username.charAt(0).toUpperCase() + row.username.slice(1) : row.username}
                              </TableCell>
                              {row.givenname ? (
                                <TableCell
                                  align="left"
                                  style={
                                    row.givenname.toLowerCase() === "deleted" || row.givenname.toLowerCase() === "anonymized"
                                      ? { opacity: 0.5 }
                                      : undefined
                                  }
                                >
                                  {row.givenname.toLowerCase() === "deleted"
                                    ? "deleted" : row.givenname.toLowerCase() === 'anonymized' ? 'Anonymized' 
                                    : `${row.givenname} ${row.surname}`}
                                </TableCell>
                              ) : (
                                <TableCell align="left">
                                  {setTempGivenname(row.username)}
                                </TableCell>
                              )}

                              {row.attended ? (
                                <TableCell align="left">
                                  {isAdminEditor &&
                                  !tableData.row.slotstatus ? (
                                    <IconButton
                                      onClick={() =>
                                        changeStatuses(row, "attended")
                                      }
                                      size="small"
                                      className={classes.attendButton}
                                    >
                                      <CheckIcon />
                                    </IconButton>
                                  ) : (
                                    <CheckIcon
                                      className={classes.attendButton}
                                    />
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell align="left">
                                  {isAdminEditor &&
                                  !tableData.row.slotstatus ? (
                                    <IconButton
                                      onClick={() =>
                                        changeStatuses(row, "attended")
                                      }
                                      size="small"
                                      className={classes.notAttendButton}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  ) : (
                                    <CloseIcon
                                      className={classes.notAttendButton}
                                    />
                                  )}
                                </TableCell>
                              )}

                              {row.signeddoc ? (
                                <TableCell align="left">
                                  {isAdminEditor ? (
                                    <IconButton
                                      onClick={() =>
                                        changeStatuses(row, "signeddoc")
                                      }
                                      size="small"
                                      className={classes.attendButton}
                                    >
                                      <CheckIcon />
                                    </IconButton>
                                  ) : (
                                    <CloseIcon
                                      className={classes.notAttendButton}
                                    />
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell align="left">
                                  {isAdminEditor ? (
                                    <IconButton
                                      onClick={() =>
                                        changeStatuses(row, "signeddoc")
                                      }
                                      size="small"
                                      className={classes.notAttendButton}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  ) : (
                                    <CloseIcon
                                      className={classes.notAttendButton}
                                    />
                                  )}
                                </TableCell>
                              )}



                    

                              {row.serix ? (
                                <TableCell align="left">
                                  {isAdminEditor ? (
                                    <IconButton
                                      onClick={() =>
                                        changeStatuses(row, "serix")
                                      }
                                      size="small"
                                      className={classes.attendButton}
                                    >
                                      <CheckIcon />
                                    </IconButton>
                                  ) : (
                                    <CloseIcon
                                      className={classes.notAttendButton}
                                    />
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell align="left">
                                  {isAdminEditor ? (
                                    <IconButton
                                      onClick={() =>
                                        changeStatuses(row, "serix")
                                      }
                                      size="small"
                                      className={classes.notAttendButton}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  ) : (
                                    <CloseIcon
                                      className={classes.notAttendButton}
                                    />
                                  )}
                                </TableCell>
                              )}

                              {/* {isAdminEditor &&
                              !tableData.row.slotstatus ? (
                                <TableCell align="left">
                                  <div style={{ height: "24px" }}>
                                    <IconButton
                                      onClick={() =>
                                        changeStatuses(row, "signeddoc")
                                      }
                                      size="small"
                                      className={classes.notAttendButton}
                                    >
                                      <CheckIcon
                                        className={classes.attendButton}
                                      />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              ) : (
                                <TableCell align="left">
                                  <div style={{ height: "24px" }}>
                                    <CloseIcon
                                      className={classes.notAttendButton}
                                    />
                                  </div>
                                </TableCell>
                              )} */}

                              {row.cds ? (
                                <TableCell align="left">
                                  {
                                    <div style={{ display: "flex" }}>
                                      <div style={{ height: "24px" }}>
                                        <CheckIcon
                                          className={classes.attendButton}
                                        />
                                      </div>

                                      {CDSLoading && index === CDSIndex ? (
                                        <div>
                                          <CircularProgress
                                            style={{
                                              position: "relative",
                                              top: "3px",
                                              left: "2px",
                                            }}
                                            size={18}
                                          />
                                        </div>
                                      ) : (
                                        <Tooltip
                                          title="Update CDS status"
                                          placement="top-start"
                                          arrow
                                        >
                                          <IconButton
                                            style={{ padding: "0px" }}
                                            size="small"
                                            onClick={() =>
                                              refreshParticipantCDS(row, index)
                                            }
                                            className={
                                              classes.refreshParticipantCDSButton
                                            }
                                          >
                                            <div style={{ height: "24px" }}>
                                              <RefreshIcon />
                                            </div>
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </div>
                                  }
                                </TableCell>
                              ) : (
                                <TableCell align="left">
                                  {
                                    <div style={{ display: "flex" }}>
                                      <CloseIcon
                                        className={classes.notAttendButton}
                                      />

                                      {CDSLoading && index === CDSIndex ? (
                                        <div>
                                          <CircularProgress
                                            style={{
                                              position: "relative",
                                              top: "3px",
                                              left: "2px",
                                            }}
                                            size={18}
                                          />
                                        </div>
                                      ) : (
                                        <Tooltip
                                          title="Update CDS status"
                                          placement="top-start"
                                          arrow
                                        >
                                          <IconButton
                                            style={{ padding: "0px" }}
                                            size="small"
                                            onClick={() =>
                                              refreshParticipantCDS(row, index)
                                            }
                                            className={
                                              classes.refreshParticipantCDSButton
                                            }
                                          >
                                            <RefreshIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </div>
                                  }
                                </TableCell>
                              )}
                              {row.anonymize ? (
                                <TableCell align="left">
                                  {isAdminEditor ? (
                                    <IconButton
                                      onClick={() =>
                                        changeStatuses(row, "anonymize")
                                      }
                                      className={classes.attendButton}
                                      size="small"
                                    >
                                      <CheckIcon />
                                    </IconButton>
                                  ) : (
                                    <CheckIcon
                                      className={classes.attendButton}
                                    />
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell align="left">
                                  {isAdminEditor ? (
                                    <IconButton
                                      onClick={() =>
                                        changeStatuses(row, "anonymize")
                                      }
                                      className={classes.notAttendButton}
                                      size="small"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  ) : (
                                    <CloseIcon
                                      className={classes.notAttendButton}
                                    />
                                  )}
                                </TableCell>
                              )}

                              <TableCell align="left">
                                {isAdminEditor && !tableData.row.slotstatus ? (
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      prepareDeleteBooking(true, row.id, row.username)
                                    }
                                    className={classes.deleteButtonList}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : (
                                  <div></div>
                                )}
                              </TableCell>
                              <TableCell align="left">
                          
                              <Tooltip title={<div style={{fontSize: '12px'}} >{row.comment ? row.comment : 'No Comment'}</div>} placement="top">
                                <div className={classes.userCommentButton}>
                              <IconButton onClick={() => handleOpenCommentDialog(row.username, row.comment, row.id)} size="small">
                              
                                    <CommentIcon style={row.comment ? { color: "#e0a500" } : { color: '' }} />
                                  </IconButton>
                                  </div>
                              </Tooltip>                         
                                  
                              </TableCell>
                            
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </Paper>
            </Grid>
          </Grid>
          {/* Delete dialog */}
          <Dialog
            classes={{ paper: classes.dialogPaper }}
            fullWidth={true}
            maxWidth={"md"}
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Delete tour?"}</DialogTitle>
            <DialogContent>
              {props.location.state ? (
                <Typography variant="subtitle2">
                  You are about to delete the tour:{" "}
                  <b>
                    {moment(props.location.state.row.slottime).format(
                      "YYYY-MM-DD - HH:mm"
                    )}
                  </b>{" "}
                  <br /> {`Are you sure?`} <br />
                  <br />
                  <span className={classes.errorMsg}>{errorMsg}</span>
                </Typography>
              ) : (
                <Typography variant="subtitle2">
                  You are about to delete the tour:{" "}
                  <b>
                    {moment(tableData.row.slottime).format(
                      "YYYY-MM-DD - HH:mm"
                    )}
                  </b>{" "}
                  <br /> {`Are you sure?`} <br />
                  <br />
                  <span className={classes.errorMsg}>{errorMsg}</span>
                </Typography>
              )}

              {/* {(
                props.history.push("/")
              )} */}
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.dialogButtons}
                onClick={() => deleteTour(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                className={classes.dialogButtons}
                onClick={() => deleteTour(true)}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          {/* Tour done dialog */}
          <Dialog
            classes={{ paper: classes.dialogPaper }}
            fullWidth={true}
            maxWidth={"md"}
            open={openDoneDialog}
            onClose={handleDoneClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {!tableData.row.slotstatus ? "Close tour?" : "Reopen tour?"}
            </DialogTitle>
            {!tableData.row.slotstatus ? (
              <DialogContent>
                {props.location.state ? (
                  <Typography variant="subtitle2">
                    {!tableData.row.slotstatus &&
                    !tableData.row.hasalreadybeenclosed
                      ? "When closing the tour an e-mail with the sign document link will automatically be sent"
                      : ""}{" "}
                    {!tableData.row.slotstatus &&
                    !tableData.row.hasalreadybeenclosed ? (
                      <br />
                    ) : (
                      <span></span>
                    )}{" "}
                    {!tableData.row.slotstatus &&
                    !tableData.row.hasalreadybeenclosed
                      ? "to all attended participants for this specific tour. "
                      : ""}{" "}
                    {!tableData.row.slotstatus &&
                    !tableData.row.hasalreadybeenclosed ? (
                      <div>
                        <br />
                      </div>
                    ) : (
                      <span></span>
                    )}
                    {!tableData.row.slotstatus &&
                    !tableData.row.hasalreadybeenclosed
                      ? "Do you want to close the tour: "
                      : ""}
                    {!tableData.row.slotstatus &&
                    tableData.row.hasalreadybeenclosed
                      ? "You have already closed this tour once."
                      : ""}{" "}
                    {!tableData.row.slotstatus &&
                    tableData.row.hasalreadybeenclosed ? (
                      <br></br>
                    ) : (
                      <span></span>
                    )}{" "}
                    {!tableData.row.slotstatus &&
                    tableData.row.hasalreadybeenclosed
                      ? "An e-mail with the sign document link will not be sent when closing the tour this time. "
                      : ""}
                    {!tableData.row.slotstatus &&
                    tableData.row.hasalreadybeenclosed ? (
                      <div>
                        <br />
                      </div>
                    ) : (
                      <span></span>
                    )}
                    {!tableData.row.slotstatus &&
                    tableData.row.hasalreadybeenclosed
                      ? "Do you want to close the tour: "
                      : ""}
                    <b>
                      {moment(props.location.state.row.slottime).format(
                        "YYYY-MM-DD - HH:mm"
                      )}
                    </b>
                    ?
                    <br />
                    <br />
                    <span className={classes.errorMsg}>{errorMsg}</span>
                  </Typography>
                ) : (
                  <Typography variant="subtitle2">
                    {!tableData.row.slotstatus &&
                    !tableData.row.hasalreadybeenclosed
                      ? "When closing the tour an e-mail with the sign document link will automatically be sent"
                      : ""}{" "}
                    {!tableData.row.slotstatus &&
                    !tableData.row.hasalreadybeenclosed ? (
                      <br />
                    ) : (
                      <span></span>
                    )}{" "}
                    {!tableData.row.slotstatus &&
                    !tableData.row.hasalreadybeenclosed
                      ? "to all attended participants for this specific tour. "
                      : ""}{" "}
                    {!tableData.row.slotstatus &&
                    !tableData.row.hasalreadybeenclosed ? (
                      <div>
                        <br />
                      </div>
                    ) : (
                      <span></span>
                    )}
                    {!tableData.row.slotstatus &&
                    !tableData.row.hasalreadybeenclosed
                      ? "Do you want to close the tour: "
                      : ""}
                    {!tableData.row.slotstatus &&
                    tableData.row.hasalreadybeenclosed
                      ? "You have already closed this tour once."
                      : ""}{" "}
                    {!tableData.row.slotstatus &&
                    tableData.row.hasalreadybeenclosed ? (
                      <br></br>
                    ) : (
                      <span></span>
                    )}{" "}
                    {!tableData.row.slotstatus &&
                    tableData.row.hasalreadybeenclosed
                      ? "An e-mail with the sign document link will not be sent when closing the tour this time. "
                      : ""}
                    {!tableData.row.slotstatus &&
                    tableData.row.hasalreadybeenclosed ? (
                      <div>
                        <br />
                      </div>
                    ) : (
                      <span></span>
                    )}
                    {!tableData.row.slotstatus &&
                    tableData.row.hasalreadybeenclosed
                      ? "Do you want to close the tour: "
                      : ""}
                    <b>
                      {moment(tableData.row.slottime).format(
                        "YYYY-MM-DD - HH:mm"
                      )}
                    </b>
                    ?
                    <br />
                    <br />
                    <span className={classes.errorMsg}>{errorMsg}</span>
                  </Typography>
                )}

                {/* {(
                props.history.push("/")
              )} */}
              </DialogContent>
            ) : (
              <DialogContent>
                <Typography variant="subtitle2">
                  Do you want to reopen the tour:{" "}
                  <b>
                    {moment(tableData.row.slottime).format(
                      "YYYY-MM-DD - HH:mm"
                    )}
                  </b>
                </Typography>
              </DialogContent>
            )}

            <DialogActions>
              <Button
                className={classes.dialogButtons}
                onClick={() => setOpenDoneDialog(false)}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
              <Button
                disabled={tableData.row.slotstatus ? false : true}
                className={classes.dialogButtons}
                onClick={() => tourDone(false)}
                color="primary"
              >
                Reopen tour
              </Button>
              <Button
                disabled={tableData.row.slotstatus ? true : false}
                className={classes.dialogButtons}
                onClick={() => tourDone(true)}
                color="primary"
                autoFocus
              >
                Close tour
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            classes={{ paper: classes.sendMessageDialog }}
            fullWidth={true}
            maxWidth={"md"}
            open={openSendMessageDialog}
            onClose={handleSendMessageDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Custom message and sign document link"}
            </DialogTitle>
            <DialogContent>
            <FormGroup>
              <div style={{ display: "flex", alignItems: "center"}}>
                <FormControlLabel control={<Checkbox color="primary" onChange={(e) => setResendCalendarEvent(e.target.checked)} />} label="Include calendar event" />
                <Tooltip title="Include the calendar event to the introduction when sending a custom message."
                  placement="top-start"
                >
                  <HelpIcon style={{ color: "#828282" }} />
                </Tooltip>
                </div>
              </FormGroup>
              <div className={classes.insideDialogWrapperDown}>
                <TextField
                  onChange={(e: any) => updateMessageToParticipants(e)}
                  className={classes.textBoxInside}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.underline,
                      /* error: classes.error */
                    },
                  }}
                  id="outlined-multiline-static"
                  label="Send custom message to tour participants"
                  multiline
                  rows={2}
                  focused
                  value={sendMessageToParticipants}
                />
                <div style={{ display: "flex" }}>
                  <Button
                    className={classes.insideDialogSendMessageButtonSendMessage}
                    onClick={sendCustomMessage}
                    variant="contained"
                  >
                    Send message
                  </Button>
                  <div style={{ alignSelf: "flex-end", marginLeft: "10px" }}>
                    <Tooltip
                      title="Send a custom message to all tour participants that are booked
              on this specific tour."
                      placement="top-start"
                    >
                      <HelpIcon style={{ color: "#828282" }} />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className={classes.resendLinkButtonAndIcon}>
                <Button
                  disabled={tableData.row.hasalreadybeenclosed ? false : true}
                  className={classes.insideDialogSendMessageButtonResendLink}
                  onClick={sendSignedDocLinkAgain}
                  variant="contained"
                >
                  Resend sign link
                </Button>
                <Tooltip
                  title="Sends an e-mail containing the sign document link.
              The e-mail will be sent to all tour participants for this
              specific tour that has not already signed the document."
                  placement="top-start"
                >
                  <HelpIcon
                    style={{
                      alignSelf: "center",
                      marginLeft: "10px",
                      color: "#828282",
                    }}
                  />
                </Tooltip>
              </div>

              <Typography variant="caption">
                <span className={classes.errorMsg}>
                  {errorMsgTourCommentsAndPart}
                </span>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.dialogButtons}
                onClick={handleSendMessageDialogClose}
                color="primary"
              >
                Exit
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            classes={{ paper: classes.dialogPaper }}
            fullWidth={true}
            maxWidth={"md"}
            open={openDialogDeleteBooking}
            onClose={handleCloseDialogDeleteBooking}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete booking?"}
            </DialogTitle>
            <DialogContent>
              <Typography variant="subtitle2">
                You are about to delete the booking for:{" "}
                <b>{userToDelete}</b> <br /> {`Are you sure?`} <br />
                <br />
                <span className={classes.errorMsg}>{errorMsg}</span>
              </Typography>

              <Typography variant="caption">
                <span className={classes.errorMsg}>{errorMsgDelete}</span>
              </Typography>

              {/* {(
                props.history.push("/")
              )} */}
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.dialogButtons}
                onClick={handleCloseDialogDeleteBooking}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                className={classes.dialogButtons}
                onClick={deleteBooking}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            classes={{ paper: classes.dialogEdit }}
            fullWidth={true}
            maxWidth={"md"}
            open={openDialogEditTour}
            onClose={handleCloseDialogEditTour}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Edit tour?"}</DialogTitle>
            <DialogContent>
              <div style={{ display: "flex", marginBottom: "20px" }}>
                <InfoIcon
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    color: "#0077c1",
                  }}
                ></InfoIcon>
                {tableData.row.format === "Online" ? (
                  <Typography
                    style={{ color: "#5c5c5c", marginTop: "10px" }}
                    variant="subtitle2"
                  >
                    If you change Date, Time or Teams link below, make sure all
                    the participants get the information.
                  </Typography>
                ) : (
                  <Typography
                    style={{ color: "#5c5c5c", marginTop: "10px" }}
                    variant="subtitle2"
                  >
                    If you change Date or Time below, make sure all the
                    participants get the information.
                  </Typography>
                )}
              </div>
              <Divider style={{ marginBottom: "20px" }}></Divider>
              <Typography style={{ marginBottom: "30px" }} variant="subtitle1">
                Change date and time:
              </Typography>

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <form className={classes.container} noValidate>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            root: classes.label,
                            focused: classes.focusedLabel,
                            error: classes.erroredLabel,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        disableToolbar
                        readOnly={tableData.row.slotstatus ? true : false}
                        helperText={
                          tableData.row.slotstatus
                            ? "You can't edit tour date when tour is closed."
                            : ""
                        }
                        variant="inline"
                        format="yyyy-MM-DD"
                        margin="normal"
                        id="date-picker-inline"
                        label="Tour date"
                        value={date}
                        onChange={changeDate}
                        autoOk={true}
                        allowKeyboardControl={true}
                        disablePast={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                  </form>
                  <form className={classes.container} noValidate>
                    <Grid container justify="space-around">
                      <KeyboardTimePicker
                        readOnly={tableData.row.slotstatus ? true : false}
                        helperText={
                          tableData.row.slotstatus
                            ? "You can't edit tour time when tour is closed."
                            : ""
                        }
                        className={classes.textField}
                        autoOk={true}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            root: classes.label,
                            focused: classes.focusedLabel,
                            error: classes.erroredLabel,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        format="HH:mm"
                        variant="inline"
                        views={["hours", "minutes"]}
                        ampm={false}
                        margin="normal"
                        id="time-picker"
                        label="Tour time"
                        value={time}
                        /* moment(time).isDST() ? moment(time).subtract(1, 'hours') : moment(time) */
                        onChange={changeTime}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </Grid>
                  </form>
                </div>
              </MuiPickersUtilsProvider>

              <div className={classes.insideDialogWrapperUpp}>
                {/* {isAdminEditor ? (
                  <Button
                    className={classes.saveCommentButton}
                    onClick={saveComment}
                    variant="contained"
                  >
                    Save comment
                  </Button>
                ) : (
                  <div></div>
                )} */}
              </div>
              {tableData.row.format === "Online" ? (
                <TextField
                  variant="outlined"
                  error={errorMsgTeams ? true : false}
                  helperText={errorMsgTeams}
                  style={{ marginTop: "40px", width: "100%" }}
                  multiline
                  rows={3}
                  /* required={tourFormat === "Online" ? true : false} */
                  fullWidth
                  /* error={errorMsgTeams ? true : false} */
                  /* helperText={errorMsgTeams} */
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                  }}
                  label="Insert Teams link"
                  id="slotAmountSelect"
                  //@ts-ignore
                  value={teamsLink}
                  onChange={handleChangeTeamsLink}
                  InputProps={{
                    classes: {
                      root: classes.underline,
                      /* error: classes.error */
                    },
                  }}
                ></TextField>
              ) : (
                <div style={{ marginTop: "80px" }}></div>
              )}
              <Typography variant="subtitle2" style={{ color: "red" }}>
                {errorMsgTourEdit}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.dialogButtons}
                onClick={handleCloseDialogEditTour}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                className={classes.dialogButtons}
                onClick={() => saveEditTour()}
                color="primary"
                autoFocus
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth open={openCommentDialog} onClose={handleCloseCommentDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Comments for {commentDialogUser}</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            rows={4}
            multiline
            autoFocus
            margin="dense"
            id="name"
            label="Insert comment"
            type="text"
            fullWidth
            value={userComment}
            onChange={(e) => setUserComment(e.target.value)}
            error={errorMsgUserComment ? true : false}
            helperText={errorMsgUserComment}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCommentDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => saveUserComment()} color="primary">
            Save comment
          </Button>
        </DialogActions>
      </Dialog>
        </div>
        <Snackbar
          open={openSnackBarComment as any}
          autoHideDuration={6000}
          onClose={handleCloseSnackBarComment}
        >
          <Alert onClose={handleCloseSnackBarComment} severity="success">
            Tour edited successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackBarDelete as any}
          autoHideDuration={6000}
          onClose={handleCloseSnackBarDelete}
        >
          <Alert onClose={handleCloseSnackBarDelete} severity="success">
            Tour deleted successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackBarTourDone as any}
          autoHideDuration={6000}
          onClose={handleCloseSnackBarTourDone}
        >
          <Alert onClose={handleCloseSnackBarTourDone} severity="success">
            {tourDoneMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackBarResendSignDocLink as any}
          autoHideDuration={6000}
          onClose={handleCloseSnackBarResendSignLink}
        >
          <Alert onClose={handleCloseSnackBarResendSignLink} severity="success">
            Sign document link is sent to participants.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackBarCustomMessage as any}
          autoHideDuration={6000}
          onClose={handleCloseSnackBarCustomMessage}
        >
          <Alert onClose={handleCloseSnackBarCustomMessage} severity="success">
            Custom message sent to all participants.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackBarUserComment as any}
          autoHideDuration={6000}
          onClose={handleCloseSnackBarUserComment}
        >
          <Alert onClose={handleCloseSnackBarUserComment} severity="success">
            Co-worker comment saved.
          </Alert>
        </Snackbar>
      </>
    );
  }
};

export default TourDetails;
