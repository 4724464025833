import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CheckUserEmail from "./CheckUserEmail";
import UserData from "./UserData";
import TimeAndLocation from "./TimeAndLocation";
import SummaryAndConfirm from "./SummaryAndConfirm";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import axios from "axios";
import useStyles from '../../styles/components/userwizard'

export default function UserWizard(props: any) {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [user, setUser] = useState(undefined as any);
  const [errMsg, setErrMsg] = useState("");
  const [locationAndSlot, setLocationAndSlot] = useState(undefined as any);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarError, setOpenSnackBarError] = useState(false);
  const [loadingWhenRwquest, setLoadingWhenRequest] = useState(false)

  const steps = getSteps();


  const classes = useStyles();

  function getSteps() {
    return [
      "Check participant email",
      "Participant details",
      "When & Where",
      "Summary",
    ];
  }
  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        //@ts-ignore
        return <CheckUserEmail showWelcomeMessageFn={props.showWelcomeMessageFn} updateInfoText={props.updateInfoText} nextStep={handleNext} />;
      case 1:
        //@ts-ignore
        return (
          <UserData
            showWelcomeMessageFn={props.showWelcomeMessageFn}
            updateInfoText={props.updateInfoText}
            handleInput={handleUserInput}
            nextStep={() => setActiveStep(2)}
            prevStep={() => setActiveStep(0)}
            userData={user}
          />
        );
      case 2:
        //@ts-ignore
        return (
          <TimeAndLocation
            showWelcomeMessageFn={props.showWelcomeMessageFn}
            updateInfoText={props.updateInfoText}
            prevStep={() => setActiveStep(0)}
            handleInput={handleLocationInput}
            nextStep={() => setActiveStep(3)}
          />
        );
      case 3:
        return (
          <SummaryAndConfirm
            showWelcomeMessageFn={props.showWelcomeMessageFn}
            updateInfoText={props.updateInfoText}
            confirm={createBooking}
            loadingWhenRwquest={loadingWhenRwquest}
            prevStep={() => setActiveStep(0)}
            handleInput={handleLocationInput}
            booking={{ locationAndSlot, user }}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }
  const createBooking = async () => {
    setLoadingWhenRequest(true)
    let cds = false;

    const locationResult = await axios(`/user/locations`);
    const userResult = await axios(`/user/userdata/${user.username}`);

    const findLocation = locationResult.data.some((x: any) => {
      return x.cdslocation.toLowerCase() === userResult.data.cdslocation.toLowerCase()
    });

    if (findLocation) {
      cds = true
    }

    const bookingToCreate = {
      locationid: locationAndSlot.location.id,
      slotid: locationAndSlot.slot.id,
      user: user.username,
      fullname: user.fullname,
      companyname: user.companyname,
      manager: user.manager,
      termsaccepted: true,
      cds: cds
    };

    try {
      const response = await axios({
        method: "POST",
        url: `/user/bookings`,
        data: bookingToCreate,
      });

      if (response.status === 200) {
        setOpenSnackBar(true);
        
        setTimeout(() => {
          setLoadingWhenRequest(false)
          history.push("/bookings");
          
        }, 3000);
      }
    } catch (err) {
      console.log(err.response)
      if (err.response.status === 400 && err.response.data.status.includes('already exists')) {
        setErrMsg("Co-worker is already booked on this tour.");
        setOpenSnackBarError(true);
      } else {
        setErrMsg("Something went wrong. Please try again.");
        setOpenSnackBarError(true);
      }
      setLoadingWhenRequest(false)
    }
  };
  const handleNext = (userData: any, skipStep: boolean) => {
    if (activeStep === 0) {
      userData.givenname
        ? (userData.fullname = (
          userData.givenname +
          " " +
          userData.surname
        ).trim())
        : (userData.fullname = "");
      setUser(userData);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleUserInput = (user: any) => {
    setUser(user);
  };

  const handleLocationInput = (locAndSlot: any) => {
    setLocationAndSlot(locAndSlot);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };
  const handleCloseSnackBarError = () => {
    setOpenSnackBarError(false);
  };
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <div>


      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.icon,
                    active: classes.activeIcon,
                    completed: classes.completedIcon,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
            </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
              <div>
                <div className={classes.instructions}>
                  {getStepContent(activeStep)}
                </div>
              </div>
            )}
        </div>
        <Snackbar
          open={openSnackBar as any}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          <Alert onClose={handleCloseSnackBar} severity="success">
            Booking created successfully!
        </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackBarError as any}
          autoHideDuration={6000}
          onClose={handleCloseSnackBarError}
        >
          <Alert onClose={handleCloseSnackBarError} severity="error">
            {errMsg}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
