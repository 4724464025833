import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { updateDateQuery, updateLocation, updateTourFormat, updateTourStatus} from '../utilities/localstorage'
import {
  IconButton,
  Paper,
  Grid,
  Typography,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress,
  DialogTitle,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpIcon from '@material-ui/icons/Help';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import moment, { utc } from "moment";
import 'moment/locale/en-gb'
import "moment-timezone"
import { UseUser } from "../context/UserContext";
import axios from "axios";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import useStyles from "../styles/views/createslot";

const CreateSlot = (props: any) => {
  const classes = useStyles();

  const [adminRole, setAdminRole] = useState("");
  const [loading, setIsLoading] = useState(true);
  const [loadingWhenRwquest, setLoadingWhenRequest] = useState(false)
  const [date, setDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
  const [time, setTime] = useState(moment().set({ hour: 10, minute: 0 }));
  const [currLocation, setCurrLocation] = useState("");
  const [locations, setLocations] = useState([]);
  const [user, setUser] = useState({});
  const [slotAmount, setSlotAmount] = useState(50);
  const [openDialogNow, setOpenDialogNow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgTeams, setErrorMsgTeams] = useState("");
  const [tourFormat, setTourFormat] = useState("Online");
  const [teamsLink, setTeamsLink] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [teamsLinkReadOnly, setTeamsLinkReadOnly] = useState(true)

  const getUserFromContext = async () => {
    const getUser = await UseUser();
    setUser(getUser);
    //@ts-ignore
    if (!user.admineditorlocations || !user.adminreaderlocations) {
      return;
    }
    //@ts-ignore
    if (user.admineditorlocations.length > 0) {
      setAdminRole("adminEditor");
      setIsLoading(false);
      //@ts-ignore
    } else if (user.adminreaderlocations.length > 0) {
      setAdminRole("adminReader");
      setIsLoading(false);
    } else {
      setAdminRole("");
      setIsLoading(false);
    }
  };

  getUserFromContext();

  useEffect(() => {
    props.updateHeaderTitle("Create tour");
  }, []);

  useEffect(() => {
    if (adminRole === "adminEditor") {
      const func = async () => {
        const responseArr = [] as any;
        //@ts-ignore
        user.admineditorlocations.forEach((location: any) => {
          const url = `/admin/locations/${location}`;

          const response = axios({
            method: "GET",
            url: url,
          });

          responseArr.push(response);
        });
        axios
          .all(responseArr)
          .then(
            axios.spread(async (...responses: any) => {
              setLocations(responses);
              setCurrLocation(responses[0].data.name);
              setTeamsLink(responses[0].data.defaultmeetinglink)
              console.log(responses[0])
            })
          )
          .catch((err: any) => {
            console.log(err);
          });
      };
      func();
    }
  }, [adminRole, user]);

  useEffect(() => {

    const findLocation = locations.find((loc) => {
      //@ts-ignore
      return loc.data.name === currLocation
    })

    if(findLocation) {
      //@ts-ignore
      setTeamsLink(findLocation.data.defaultmeetinglink)
    }
    
  }, [currLocation])

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const changeDate = (date: any) => {
    const dateToSet: any = moment(date);
    setDate(dateToSet);
  };
  const changeTime = (time: any) => {
    const timeToSet: any = moment(time);
    setTime(timeToSet);
  };

  const handleChangeLocation = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    //@ts-ignore
    setCurrLocation(event.target.value as any);
  };
  const handleChangeAmount = (event: React.ChangeEvent<{ value: unknown }>) => {
    if ((event.target.value as any) < 1 || (event.target.value as any) > 200) {
      return;
    }

    //@ts-ignore
    setSlotAmount(event.target.value as any);
  };
  const handleChangeTourFormat = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    //@ts-ignore
    setTourFormat(event.target.value as any);
  };
  const handleChangeTeamsLink = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setErrorMsgTeams("");
    //@ts-ignore
    setTeamsLink(event.target.value as any);




  };
  const handleOpenDialog = () => {

    if (tourFormat === 'Online') {

      const testLink = isValidHttpUrl(teamsLink)

      if (!testLink) {
        return setErrorMsgTeams("The link you inserted is not in correct link format.");
      } else {
        setOpenDialogNow(true);
      }

    }
    setOpenDialogNow(true);

  };

  const changeTeamsLinkReadOnly = () => {
    teamsLinkReadOnly ? setTeamsLinkReadOnly(false) : setTeamsLinkReadOnly(true)
  }

  const createTourSlot = async () => {
    setLoadingWhenRequest(true)
    const found: any = locations.find((loc: any) => {
      return loc.data.name === currLocation;
    });
    if (!found) {
      return;
    }

    // let slottime = `${moment(date).format("YYYY-MM-DD")} ${moment(time).format("HH:mm")}`
    // let expires = `${moment(date).add(1, "month").format("YYYY-MM-DD")} ${moment(time).format("HH:mm")}`;
    const locationId = found.data.id;
    const url = `/admin/locations/${locationId}/slots`;
    console.log(moment(time).format("HH:mm").toString())
    const data = {
      slottime: moment(`${moment(date).format("YYYY-MM-DD")}T${moment(time).format("HH:mm")}`).tz('Europe/Stockholm').format(),
      expires: moment(`${moment(date).add(1, 'month').format("YYYY-MM-DD")}T${moment(time).format("HH:mm")}`).tz('Europe/Stockholm').format(),
      slotcount: slotAmount,
      format: tourFormat,
      meetinglink: teamsLink,
    };
    console.log(data)
    try {
      const response = await axios({
        method: "POST",
        url: url,
        data: data,
      });

      if (response && response.status === 200) {
        console.log(found.data)
        updateDateQuery(moment(data.slottime).format('YYYY-MM-DD').slice(0, 7))
        updateLocation(found.data.name)
        updateTourFormat(null)
        updateTourStatus(null)
        handleClose();
        setLoadingWhenRequest(false)
        setOpenSnackBar(true);
        setTimeout(() => {
          props.history.push("/admin/tours");
        }, 1000);
      }
    } catch (err) {
      if (err.response.status === 400) {
        setErrorMsg(err.response.data.error);
      } else if (err.response.status === 401) {
        setErrorMsg("Error: You are not Authorized, try again");
      } else if (err.response.status === 403) {
        setErrorMsg("Error: You have no permission, try again");
      } else if (err.response.status === 422) {
        setErrorMsg("Error: You sent bad data, try again");
      } else if (err.request) {
        setErrorMsg("Error: Server responded with and error, try again");
      }
      updateDateQuery(null)
        updateLocation(null)
        updateTourFormat(null)
        updateTourStatus(null)
      setLoadingWhenRequest(false)
    }
  };
  const handleClose = async () => {
    setOpenDialogNow(false);
  };
  const cancelCreateTour = () => {
    handleClose();
    setErrorMsg("");
  };

  function isValidHttpUrl(string: any) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const tourList = (locations: any, currentLocation: any) => {

    const findLocation = locations.find(
      (location: any) => location.data.name === currentLocation
    );

    if (findLocation) {
      return findLocation.data.slots.map((slot: any) => {
        return (
          <Typography
            key={slot.id}
            style={{
              color: "#5c5c5c",
              paddingLeft: "10px",
              paddingTop: "10px",
            }}
          >
            {moment(slot.slottime).format("YYYY-MM-DD HH:mm")}
          </Typography>
        );
      });
    }

    return [];
  };

  if (loading) {
    return (
      <div className={classes.loadingLinear}>
        <Typography variant="h6" component="h2">
          Loading...
        </Typography>
        <LinearProgress />
      </div>
    );
  } else if (adminRole !== "adminEditor") {
    return (
      <>
        <Typography variant="h4">You do not have the correct admin rights to show this page.</Typography>
        <Typography variant="h6"><Link to="/" style={{ color: '#0077c1' }}>Go back to main page</Link></Typography>
      </>
    )
  }

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        fullWidth={true}
        maxWidth={"md"}
        open={openDialogNow}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Create new Tour slot?"}
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2">
            {`Tour slot details:`} <br />
            <br />
            {`Tour date: ${moment(date).format("YYYY-MM-DD")}`} <br />
            {`Tour time: ${moment(time).format("HH:mm")}`} <br />
            {`Location: ${currLocation}`} <br />
            {`Total participants: ${slotAmount}`} <br />
            {`Tour format: ${tourFormat}`} <br />
            {tourFormat === "Online"
              ? `Teams link: ${teamsLink.slice(0, 30)}...`
              : ""}{" "}
            <br />
            <br />
            <span className={classes.errorMsg}>{errorMsg}</span>
          </Typography>
        </DialogContent>
        <DialogActions>
          {loadingWhenRwquest && <CircularProgress size={18} />}
          <Button
            className={classes.dialogButtons}
            onClick={cancelCreateTour}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={classes.dialogButtons}
            onClick={createTourSlot}
            color="primary"
            autoFocus
            disabled={loadingWhenRwquest ? true : false}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div
        style={{ display: "flex", justifyContent: "center", color: "#5c5c5c" }}
      >
        <HelpIcon
          style={{ alignSelf: "center", marginRight: "10px", color: "#0077c1" }}
        ></HelpIcon>
        <Typography
          style={{ color: "#5c5c5c", marginTop: "10px" }}
          variant="subtitle2"
        >
          This is the section for creating a new tour. Choose Location, date and
          time for the tour.
          <br />
          You can set the Maximum participants if you like.
          <br />
          Decide if it's an online or an on site tour. 
          <br />
          Klara picks the default meeting link setting from the settings page. If you want to use another meeting link press the "unlock" button right to the text field and paste your meeting link. 
          <br />
          When done press "CREATE TOUR".
        </Typography>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}></Paper>
        </Grid>
      </Grid>
      <div style={{ display: "flex", justifyContent: "space-around" }}>

        <div>
          <div className={classes.dateTimeWrapper}>
            <FormControl className={classes.formControl}>
              <InputLabel
                className={classes.inputLabel}
                id="demo-simple-select-label"
              >
                Location
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                className={classes.underlineSelect}
                labelId="locationSelect"
                id="locationSelect"
                //@ts-ignore
                value={currLocation}
                onChange={handleChangeLocation}
              >
                {locations.map((location: any) => {
                  return (
                    <MenuItem key={location.data.id} value={location.data.name}>
                      {location.data.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <MuiPickersUtilsProvider utils={MomentUtils}>
              <form className={classes.container} noValidate>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.label,
                        focused: classes.focusedLabel,
                        error: classes.erroredLabel,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.underline,
                      },
                    }}
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-DD"
                    margin="normal"
                    id="date-picker-inline"
                    label="Tour date"
                    value={date}
                    onChange={changeDate}
                    autoOk={true}
                    allowKeyboardControl={true}
                    disablePast={true}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </form>
              <form className={classes.container} noValidate>
                <Grid container justify="space-around">
                  <KeyboardTimePicker
                    className={classes.textField}
                    autoOk={true}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.label,
                        focused: classes.focusedLabel,
                        error: classes.erroredLabel,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.underline,
                      },
                    }}
                    format="HH:mm"
                    variant="inline"
                    views={["hours", "minutes"]}
                    ampm={false}
                    margin="normal"
                    id="time-picker"
                    label="Tour time"
                    value={time}
                    onChange={changeTime}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </Grid>
              </form>
            </MuiPickersUtilsProvider>
          </div>

          <div className={classes.dateTimeWrapper}>
            <FormControl className={classes.formControl}>
              <TextField
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                label="Max participants"
                type="number"
                id="slotAmountSelect"
                //@ts-ignore
                value={slotAmount}
                onChange={handleChangeAmount}
                InputProps={{
                  inputProps: { min: 1, max: 200 },
                  classes: {
                    root: classes.underline,
                    /* error: classes.error */
                  },
                }}
              ></TextField>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel
                className={classes.inputLabel}
                id="demo-simple-select-label"
              >
                Tour format
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                className={classes.underlineSelect}
                labelId="locationSelect2"
                id="locationSelect2"
                //@ts-ignore
                value={tourFormat}
                onChange={handleChangeTourFormat}
              >
                <MenuItem key={"Online"} value="Online">
                  Online
                </MenuItem>
                <MenuItem key={"Offline"} value="Offline">
                  On site
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.teamsLinkWrapper} style={{ width: "650px", marginLeft: "190px" }}>
            <div className={classes.teamsLink}>
              {tourFormat === "Online" ? (
                <FormControl
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  className={classes.teamsLink}
                >
                  <TextField
                    multiline
                    variant="outlined"
                    rows='6'
                    required={tourFormat === "Online" ? true : false}
                    fullWidth
                    error={errorMsgTeams ? true : false}
                    helperText={errorMsgTeams}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                        focused: classes.focusedLabel,
                        error: classes.erroredLabel,
                      },
                    }}
                    label="Teams link"
                    id="slotAmountSelect"
                    //@ts-ignore
                    value={teamsLink}
                    onChange={handleChangeTeamsLink}
                    InputProps={{
                      readOnly: teamsLinkReadOnly,
                      classes: {
                        root: classes.underline,
                        /* error: classes.error */
                      },
                    }}
                  ></TextField>
                </FormControl>
              ) : (
                  ""
                )}
            </div>
            
            {tourFormat === 'Online' ? <div className={classes.buttonWrapper}>
            <IconButton
              className={classes.unlockTeamsLinkButton}
              onClick={changeTeamsLinkReadOnly}
              size="small"
            >
              {teamsLinkReadOnly ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon /> }
            </IconButton>
          </div> : ''}

            
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              disabled={moment(date).isBefore(moment().subtract("1", "days"))}
              className={classes.createTourButton}
              onClick={handleOpenDialog}
              size="large"
              variant="contained"
              color="primary"
            >
              Create Tour
            </Button>
          </div>
          <div
            style={{
              marginTop: "50px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Accordion style={{ width: "50%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Already created Tours at {currLocation}</Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
              >
                {tourList(locations, currLocation)}
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>

      <Snackbar
        open={openSnackBar as any}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          Tour created successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateSlot;
