import React, { useEffect, useState } from "react";
import UserWizard from "../components/Wizard/UserWizard";
import { UseUser } from "../context/UserContext";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import HelpIcon from "@material-ui/icons/Help";
import Divider from "@material-ui/core/Divider";
import { LinearProgress } from "@material-ui/core";

import useStyles from "../styles/views/user";

export default function User(props: any) {
  const classes = useStyles();

  const [infoText, setInfoText] = useState("");
  const [user, setUser] = useState({} as any);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const [showLinkToSerix, setShowLinkToSerix] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const getUserFromContext = async () => {
    const getUser = await UseUser();
    if (Object.keys(getUser).length) {
      setUser(getUser);
      setIsLoading(false);
    }
  };

  getUserFromContext();

  useEffect(() => {
    props.updateHeaderTitle("Assign co-worker");
  }, []);

  const showWelcomeMessageFn = (bool: boolean) => {
    console.log(bool);
    setShowWelcomeMessage(bool);
    setShowLinkToSerix(bool);
  };

  const updateInfoText = (text: string) => {
    setInfoText(text);
  };

  if (isLoading) {
    return (
      <div className={classes.loadingLinear}>
        <Typography variant="h6" component="h2">
          Loading...
        </Typography>
        <LinearProgress />
      </div>
    );
  } else {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#5c5c5c",
          }}
        >
          <HelpIcon
            style={{
              alignSelf: "flex-end",
              marginRight: "10px",
              color: "#0077c1",
            }}
          ></HelpIcon>

          <div>
            {showWelcomeMessage ? (
              <Typography style={{ color: "#5c5c5c" }} variant="subtitle1">
                {Object.keys(user).length === 0 ? (
                  <span></span>
                ) : (
                  `Hej ${user.givenname} ${user.surname}!`
                )}
              </Typography>
            ) : (
              <span></span>
            )}
            <Typography
              style={{ color: "#5c5c5c", marginTop: "10px" }}
              variant="subtitle2"
            >
              {infoText}
            </Typography>
          </div>
        </div>

        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.paper}>
                <UserWizard
                  showWelcomeMessageFn={showWelcomeMessageFn}
                  updateInfoText={updateInfoText}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#5c5c5c",
          }}
        >
          {/* {showLinkToSerix ? (
            <InfoIcon
              style={{
                alignSelf: "flex-end",
                marginRight: "10px",
                color: "#0077c1",
              }}
            ></InfoIcon>
          ) : (
            <div></div>
          )} */}
          {/* {showLinkToSerix ? (
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <Typography style={{ color: "#5c5c5c"}} variant="subtitle2">
              PLEASE NOTE* Before the safety and security introdcution you need
              to add the following to{" "}
              <a
                style={{ color: "#0077c1" }}
                href="https://ikea.serix.seriline.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                Serix (seriline.com)
              </a>
              < br/>
              <ul>
                <li>A photo of your co-worker from shoulders and up. The photo should be taken with a light background making your face very visible.  </li>
                <li>A four-digit pin code that will be linked to your card.</li>
              </ul>
              
            </Typography>
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
    );
  }
}
