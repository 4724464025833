import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import useStyles from '../../styles/components/userdata'
import { checkValidEmail } from '../../utilities/validation';

const UserData = (props: any) => {
  const classes = useStyles();

  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgFullName, setErrorMsgFullName] = useState("");
  const [errorMsgManager, setErrorMsgManager] = useState("");
  const [errorMsgCompany, setErrorMsgCompany] = useState("");

  const [inputEmail, setInputEmail] = useState(props.userData.username);
  const [inputManager, setInputManager] = useState(
    props.userData.manager ? props.userData.manager : ""
  );
  const [inputCompanyName, setInputCompanyName] = useState(
    props.userData.companyname ? props.userData.companyname : ""
  );
  const [inputFullName, setInputFullName] = useState(
    props.userData.fullname ? props.userData.fullname : ""
  );
  const [cds, setCds] = useState(
    props.userData.cds ? true : false
  );

  const handleNext = () => {
    const checkEmail = checkValidEmail(inputManager);

    if (!inputEmail) {
      return setErrorMsgEmail("Required");
    }
    if (!inputFullName) {
      return setErrorMsgFullName("Required");
    }
    if (!inputManager) {
      return setErrorMsgManager("Required");
    }
    if (!inputCompanyName) {
      return setErrorMsgCompany("Required");
    }

    if (!checkEmail) {
      setErrorMsgManager("E-mail must be valid");
      return;
    }

    const user = {
      username: inputEmail,
      manager: inputManager,
      companyname: inputCompanyName,
      fullname: inputFullName,
      cds: cds
    };
    props.handleInput(user);
    props.nextStep();
  };

  return (
    <>
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          handleNext();
        }}
      >
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
          InputProps={{
            readOnly: true,
            classes: {
              root: classes.underline,
              /* error: classes.error */
            },
          }}
          onChange={(e: any) => {
            setErrorMsgEmail("");
            setInputEmail(e.target.value);
          }}
          value={inputEmail}
          label="Participant's email"
          type="email"
          error={errorMsgEmail ? true : false}
          helperText={errorMsgEmail}
        />{" "}
        <br />
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
          InputProps={{
            readOnly: props.userData.fullname ? true : false,
            classes: {
              root: classes.underline,
              /* error: classes.error */
            },
          }}
          onChange={(e: any) => {
            setErrorMsgFullName("");
            setInputFullName(e.target.value);
          }}
          value={inputFullName}
          label="Participant's full name"
          type="text"
          error={errorMsgFullName ? true : false}
          helperText={errorMsgFullName}
        />{" "}
        <br />
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
          InputProps={{
            readOnly: props.userData.manager ? true : false,
            classes: {
              root: classes.underline,
              /* error: classes.error */
            },
          }}
          onChange={(e: any) => {
            setErrorMsgManager("");
            setInputManager(e.target.value);
          }}
          value={inputManager}
          label="Participant's manager E-mail address"
          type="email"
          error={errorMsgManager ? true : false}
          helperText={errorMsgManager}
        />{" "}
        <br />
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
          InputProps={{
            readOnly: props.userData.companyname ? true : false,
            classes: {
              root: classes.underline,
              /* error: classes.error */
            },
          }}
          onChange={(e: any) => {
            setErrorMsgCompany("");
            setInputCompanyName(e.target.value);
          }}
          value={inputCompanyName}
          label="Participant's company name"
          type="text"
          error={errorMsgCompany ? true : false}
          helperText={errorMsgCompany}
        />
      </form>
      <div>
      <Button
        onClick={() => { props.prevStep(); props.showWelcomeMessageFn(true) }}
        className={classes.buttons}
        variant="contained"
      >
        Back
      </Button>
      
      <Button
        onClick={handleNext}
        className={classes.buttons}
        variant="contained"
      >
        Next
      </Button>
      </div>
    </>
  );
};

export default UserData;
