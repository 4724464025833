import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import Typography from "@material-ui/core/Typography";
const changelogFile = require("../assets/changelog.md");

const Changelog = (props: any) => {
  const [logToShow, setLogToShow] = useState("");

  useEffect(() => {
    props.updateHeaderTitle("Changelog");
  }, []);

  useEffect(() => {
    fetch(changelogFile)
      .then((res) => res.text())
      .then((text) => setLogToShow(text as any));
  }, []);

  return (
    <div>
      
        <div>
        <Typography style={{color: '#5c5c5c'}} component="div">
          <Markdown source={logToShow}></Markdown>
        </Typography>
        </div>
     
    </div>
  );
};

export default Changelog;
