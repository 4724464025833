import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(3),
        width: "35ch",
      },
    },
    buttons: {
      margin: theme.spacing(1),
      backgroundColor: "#0077c1",
      color: "white",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#0084d6",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "300px",
      maxWidth: "300px",
      marginBottom: '40px'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    inputLabel: {
      "&.Mui-focused": {
        color: "#0077c1",
      },
    },
    underlineSelect: {
      "&:before": {
        borderColor: "#0077c1",
      },
      "&:after": {
        borderColor: "#0077c1",
      },
    },
    label: {
      "&$focusedLabel": {
        color: "#0077c1",
      },
      "&$erroredLabel": {
        /* color: "orange" */
      },
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 250,
    },
    focusedLabel: {},
    erroredLabel: {},
    underline: {
      "&$error:after": {
        /*  borderBottomColor: "orange" */
      },
      "&:after": {
        borderBottom: `2px solid #0077c1`,
      },
      "&:before": {
        borderColor: "#0077c1",
      },
    },
    calendarTourWrapper: {
      display: 'flex', 
      minWidth: '600px',
      justifyContent: 'space-between'
    },
  })
);

export default useStyles