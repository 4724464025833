import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(3),
        width: "35ch",
      },
    },
    buttons: {
      margin: theme.spacing(1),
      backgroundColor: "#0077c1",
      color: "white",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#0084d6",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "350px",
      maxWidth: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export default useStyles