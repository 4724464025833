import React, { useState } from "react";
import BurgerMenu from "./BurgerMenu";
import { withRouter, useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const Header = (props: any) => {
  let history = useHistory();

  return (
    <header
      id="header"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div>
        <NavLink
          style={{ color: "#fff", textDecoration: "none", fontWeight: "bold" }}
          to="/"
        >
          <Typography variant="subtitle2">KLARA</Typography>
        </NavLink>
        <Typography variant="subtitle1">{props.title}</Typography>
      </div>
      <div style={{ display: "flex" }}>
        <div>
          {/* <Button
            style={{ color: "white", fontSize: "18px" }}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
          <Button
            style={{ color: "white", fontSize: "18px", marginRight: "0px" }}
            onClick={() => history.goForward()}
          >
            Next
          </Button> */}
          <span className="burger-menu">
            <BurgerMenu />
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
