export function updateDateQuery(date: any) {
  if(!date){
    window.localStorage.removeItem("datequery");
  }
  else {
    window.localStorage.setItem("datequery", date);
  }

}

export function updateLocation(location: any) {

  if(!location){
    window.localStorage.removeItem("currlocation");
  }
  else {
    window.localStorage.setItem("currlocation", location);
  }

}

export function updateTourFormat(format: any) {

  if(!format){
    window.localStorage.removeItem("tourformat");
  }
  else {
    window.localStorage.setItem("tourformat", format);
  }

}

export function updateTourStatus(status: any) {

  if(!status){
    window.localStorage.removeItem("tourstatus");
  }
  else {
    window.localStorage.setItem("tourstatus", status);
  }

}