export const checkValidEmail = (email: any) => {
  if (!email) {
    return false;
  }

  return (
    !email ||
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ||
    false
  );
};