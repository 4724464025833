import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(3),
        width: "350px",
        maxWidth: "100%",
      },
    },

    buttons: {
      margin: theme.spacing(1),
      backgroundColor: "#0077c1",
      color: "white",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#0084d6",
      },
    },
    label: {
      "&$focusedLabel": {
        color: "#0077c1",
      },
      "&$erroredLabel": {
        /* color: "orange" */
      },
    },
    focusedLabel: {},
    erroredLabel: {},
    underline: {
      "&$error:after": {
        /*  borderBottomColor: "orange" */
      },
      "&:after": {
        borderBottom: `2px solid #0077c1`,
      },
    },
  })
);

export default useStyles