import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DefaultEditor } from "react-simple-wysiwyg";
import axios from "axios";
import { UseUser } from "../context/UserContext";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  LinearProgress,
  TextField,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CircularProgress } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import useStyles from "../styles/views/about";

const About = (props: any) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [user, setUser] = useState({} as any);
  const [isUser, setIsUser] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [showEditor, setShowEditor] = useState(false);
  const [faqData, setFaqData] = useState([] as any);
  const [question, setQuestion] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [sortOrderToPut, setSortOrderToPut] = useState(undefined);
  const [updateId, setUpdateId] = useState(undefined);
  const [activateSorting, setActivateSorting] = useState(false);
  const [savedFaqDataState, setSavedFaqDataState] = useState({} as any);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarError, setOpenSnackBarError] = useState(false);
  const [loadingWhenRwquest, setLoadingWhenRequest] = useState(false)
  const [loadingWhenRequestSave, setLoadingWhenRequestSave] = useState(false)

  const getUserFromContext = async () => {
    const getUser = await UseUser();
    if (Object.keys(getUser).length) {
      setUser(getUser);
      setIsUser(true);
    }
  };

  getUserFromContext();

  useEffect(() => {
    if (faqData.length && Object.keys(user).length) setIsLoading(false);
  }, [user, faqData]);

  const onTextChange = (e: any) => {
    setText(e.target.value);
  };

  useEffect(() => {
    props.updateHeaderTitle("FAQ");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await axios({
          method: "GET",
          url: "/faq",
        });

        if (response && response.status === 200) {
          response.data.sort((a: any, b: any) => {
            if (a.sortorder < b.sortorder) {
              return -1;
            }
            if (b.sortorder < a.sortorder) {
              return 1;
            }
            return 0;
          });
          setFaqData(response.data);
        }
      } catch (err) {
        console.log(err.response);
      }
    };
    fetchData();
  }, [showEditor]);

  const saveQuestion = async (text: any) => {
    
    checkQuestion();
    
    if (isUpdating) {
      try {
        const obj = {
          question: question,
          answer: text,
          sortorder: sortOrderToPut,
          id: updateId,
        };
        setLoadingWhenRequestSave(true)
        const response: any = await axios({
          method: "PUT",
          url: "/faq",
          data: obj,
        });

        if (response && response.status === 200) {
          setShowEditor(false);
          setText("");
          setQuestion("");
          setIsUpdating(false);
          setUpdateId(undefined);
          setLoadingWhenRequestSave(false)
        }
      } catch (err) {
        console.log(err.response);
        setLoadingWhenRequestSave(false)
      }
    } else {
      setLoadingWhenRequestSave(true)
      let sortOrder = 0;

      if (faqData.length < 1) {
        sortOrder = 0;
      } else {
        const max = faqData.reduce((prev: any, current: any) =>
          prev.sortorder > current.sortorder ? prev : current
        );

        sortOrder = max.sortorder + 1;
      }

      try {
        const obj = {
          question: question,
          answer: text,
          sortorder: sortOrder,
        };

        const response: any = await axios({
          method: "POST",
          url: "/faq",
          data: obj,
        });

        if (response && response.status === 200) {
          setShowEditor(false);
          setText("");
          setQuestion("");
          setIsUpdating(false);
          setUpdateId(undefined);
          setLoadingWhenRequestSave(false)
        }
      } catch (err) {
        setLoadingWhenRequestSave(false)
        console.log(err.response);
      }
    }
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const cancelSaveQuestion = () => {
    setShowEditor(false);
    setText("");
    setQuestion("");
    setIsUpdating(false);
    setSortOrderToPut(undefined);
  };

  const updateQuestionFn = (faq: any) => {
    if (activateSorting) {
      setActivateSorting(false);
    }
    setText(faq.answer);
    setQuestion(faq.question);
    setShowEditor(true);
    setIsUpdating(true);
    setSortOrderToPut(faq.sortorder);
    setUpdateId(faq.id);
  };

  const updateQuestion = (e: any) => {
    setQuestion(e.target.value);
  };
  const checkQuestion = async () => {
    if (!question) {
      return setErrorMsg("Required");
    }
  };

  const deleteFaqQuestion = async (faq: any) => {
    if (activateSorting) {
      setActivateSorting(false);
    }

    const response: any = await axios({
      method: "DELETE",
      url: `faq/${faq.id}`,
    });

    if (response && response.status === 202) {
      const fetchData = async () => {
        try {
          const response: any = await axios({
            method: "GET",
            url: "/faq",
          });

          if (response && response.status === 200) {
            response.data.sort((a: any, b: any) => {
              if (a.sortorder < b.sortorder) {
                return -1;
              }
              if (b.sortorder < a.sortorder) {
                return 1;
              }
              return 0;
            });

            setFaqData(response.data);
          }
        } catch (err) {
          console.log(err.response);
        }
      };
      fetchData();
    }
  };

  const reArrangeUp = (faq: any) => {
    if (!activateSorting) {
      setSavedFaqDataState(faqData);
      setActivateSorting(true);
    }

    const index = faqData.indexOf(faq);

    const newData = [...faqData];

    newData.splice(index, 1);
    newData.splice(index - 1, 0, faq);

    setFaqData(newData);
  };

  const reArrangeDown = (faq: any) => {
    if (!activateSorting) {
      setSavedFaqDataState(faqData);
      setActivateSorting(true);
    }

    const index = faqData.indexOf(faq);

    const newData = [...faqData];

    newData.splice(index, 1);
    newData.splice(index + 1, 0, faq);

    setFaqData(newData);
  };

  const saveNewOrder = async () => {
    setLoadingWhenRequest(true)
    const newDataToSend = faqData.map((faq: any, i: any) => {
      faq.sortorder = i;
      return faq;
    });

    const responseArr = [] as any;

    newDataToSend.forEach((faq: any) => {
      const data = {
        question: faq.question,
        answer: faq.answer,
        id: faq.id,
        sortorder: faq.sortorder,
      };

      const response = axios({
        method: "PUT",
        url: "/faq",
        data: data,
      });

      responseArr.push(response);
    });

    await axios
      .all(responseArr)
      .then(
        axios.spread((...responses: any) => {
          if (responses.length === responseArr.length) {
            setSuccessMsg("New sorting successfully saved");
            setOpenSnackBar(true);
            setLoadingWhenRequest(false)
            setActivateSorting(false);
          }
        })
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setErrorMsg("Error: You are not Authorized, try again");
        } else if (err.response.status === 403) {
          setErrorMsg("Error: You have no permission, try again");
        } else if (err.response.status === 422) {
          setErrorMsg("Error: You sent bad data, try again");
        } else if (err.request) {
          setErrorMsg("Error: Server responded with and error, try again");
        }
        setOpenSnackBarError(true);
        setActivateSorting(false);
        setLoadingWhenRequest(false)
      });
  };

  const cancelNewOrder = () => {
    setActivateSorting(false);
    setFaqData(savedFaqDataState);
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };
  const handleCloseSnackBarError = () => {
    setOpenSnackBarError(false);
  };

  if (loading) {
    return (
      <div className={classes.loadingLinear}>
        <Typography variant="h6" component="h2">
          Loading...
        </Typography>
        <LinearProgress />
      </div>
    );
  } else if (!isUser) {
    return <div>Can't find User</div>;
  } else {
    return (
      <div className={classes.root}>
        <div style={{ marginTop: "50px" }}>
          <Typography
            variant="h4"
            style={{
              textAlign: "center",
              marginBottom: "50px",
              color: "#5c5c5c",
            }}
          >
            Frequently Asked Questions
          </Typography>
          {user.faqadmin ? (
            <div className={classes.newQuestionWrapper}>
              <Button
                className={classes.buttons}
                variant="contained"
                onClick={() => setShowEditor(true)}
              >
                New question
              </Button>

              <Button
                disabled={!activateSorting || loadingWhenRwquest ? true : false}
                onClick={saveNewOrder}
                style={{ marginLeft: "10px" }}
                className={classes.buttons}
                variant="contained"
              >
                Save Sorting
              </Button>
              <Button
                disabled={!activateSorting || loadingWhenRwquest ? true : false}
                onClick={cancelNewOrder}
                style={{ marginLeft: "10px" }}
                className={classes.buttons}
                variant="contained"
              >
                Cancel Sorting
              </Button>
              {loadingWhenRwquest ? <CircularProgress size={22} style={{marginLeft: '16px', position: 'relative', top: '6px'}} /> : <span></span>}

              <br />
              {showEditor ? (
                <TextField
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.underline,
                    },
                  }}
                  fullWidth
                  onChange={(e: any) => {
                    updateQuestion(e);
                    setErrorMsg("");
                  }}
                  style={{ marginTop: "10px" }}
                  id="outlined-basic"
                  label="Insert FAQ Question"
                  value={question}
                  error={errorMsg ? true : false}
                  helperText={errorMsg}
                />
              ) : (
                <div></div>
              )}
              {showEditor ? (
                <div style={{ marginTop: "10px" }}>
                  {" "}
                  <DefaultEditor value={text} onChange={onTextChange} />{" "}
                </div>
              ) : (
                <div></div>
              )}

              <div className={classes.saveQuestionWrapper}>
                {showEditor ? (
                  <Button
                    disabled={!text || loadingWhenRequestSave}
                    className={classes.buttons}
                    variant="contained"
                    onClick={() => saveQuestion(text)}
                  >
                    Save question
                  </Button>
                ) : (
                  <div></div>
                )}
                {showEditor ? (
                  <Button
                    className={classes.buttons}
                    variant="contained"
                    style={{ marginLeft: "10px" }}
                    onClick={cancelSaveQuestion}
                  >
                    Cancel
                  </Button>
                  
                ) : (
                  <div></div>
                )}
                {loadingWhenRequestSave ? <CircularProgress size={22} style={{marginLeft: '10px', position: 'relative', top: '8px'}}/> : <span></span>}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {faqData.map((faq: any) => {
            return (
              <Accordion key={faq.id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography variant="h6" className={classes.heading}>
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    style={{ color: "#5c5c5c" }}
                    component="div"
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  ></Typography>
                </AccordionDetails>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {user.faqadmin ? (
                    <IconButton onClick={() => deleteFaqQuestion(faq)}>
                      <DeleteIcon style={{ fontSize: "20px" }} />
                    </IconButton>
                  ) : (
                    <span></span>
                  )}

                  {user.faqadmin ? (
                    <div>
                      <IconButton onClick={() => reArrangeDown(faq)}>
                        <ArrowDownwardIcon />
                      </IconButton>

                      <IconButton onClick={() => reArrangeUp(faq)}>
                        <ArrowUpwardIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <span></span>
                  )}

                  {user.faqadmin ? (
                    <IconButton onClick={() => updateQuestionFn(faq)}>
                      <EditIcon style={{ fontSize: "20px" }} />
                    </IconButton>
                  ) : (
                    <span></span>
                  )}
                </div>
              </Accordion>
            );
          })}
        </div>
        <Snackbar
          open={openSnackBar as any}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          <Alert onClose={handleCloseSnackBar} severity="success">
            {successMsg}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackBarError as any}
          autoHideDuration={6000}
          onClose={handleCloseSnackBarError}
        >
          <Alert onClose={handleCloseSnackBarError} severity="error">
            {errorMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
};

export default About;
