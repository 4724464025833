import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>

  createStyles({

    wrapper: {
      width: '600px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },
    
    upsideDown: {
      position: 'absolute',
      transform: 'rotate(-180deg)',
      top: '7px',
      left: '270px'
    }
  })
);

export default useStyles