import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      icon: {
        /* 		color: theme.palette.secondary.main, */
        "&$activeIcon": {
          color: "#0077c1",
        },
        "&$completedIcon": {
          color: "black",
        },
      },
      activeIcon: {},
      completedIcon: {},
      root: {
        width: "100%",
        marginTop: theme.spacing(2),
      },
      backButton: {
        marginRight: theme.spacing(1),
      },
      instructions: {
        display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    })
  );

  export default useStyles;