import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, HashRouter, useLocation } from 'react-router-dom'
import User from './Views/User'
import Tours from './Views/Tours'
import MyBookings from './Views/MyBookings'
import TourDetails from './Views/TourDetails'
import About from './Views/About'
import SecurityRules from './Views/SecurityRules'
import CreateSlot from './Views/CreateSlot'
import Changelog from './Views/Changelog'
import Header from './components/Header'
import Footer from './components/Footer'
import Settings from './Views/Settings'
import Four04Page from './Views/Four04Page'
import ParticipantLookup from './Views/ParticipantLookup';
import Agreement from './Views/Agreement';
import { updateDateQuery, updateLocation, updateTourFormat, updateTourStatus} from './utilities/localstorage'

import { UserProvider } from "./context/UserContext";
import CreateLocation from './Views/CreateLocation'
import SettingsV2 from './Views/SettingsV2.jsx'


function App(props: any) {
	const [title, setTitle] = useState('');

	const updateHeaderTitle = (titleText: any) => {
		setTitle(titleText);
	};

	if(!window.location.href.includes('tours')) {
		updateDateQuery(null);
		updateLocation(null);
		updateTourFormat(null);
		updateTourStatus(null);
	};

	return (
		<div className="App">
			<UserProvider>
				<HashRouter>
					{!window.location.href.includes("/agreement/") && <Header title={title} />}
					<main>
						<Switch>
							<Route exact path="/" render={(props) => (<User {...props} updateHeaderTitle={updateHeaderTitle} />)} />
							<Route exact path="/agreement/:id" render={(props) => (<Agreement url={window.location.href} {...props as any} />)} />
							<Route path="/bookings" render={(props) => (<MyBookings {...props as any} updateHeaderTitle={updateHeaderTitle} />)} />
							<Route exact path="/admin/tours" render={(props) => (<Tours {...props} updateHeaderTitle={updateHeaderTitle} />)} />
							<Route path="/admin/tours/tourdetails/:id" render={(props) => (<TourDetails {...props} updateHeaderTitle={updateHeaderTitle} />)} />
							<Route exact path="/about/faq" render={(props) => (<About {...props as any} updateHeaderTitle={updateHeaderTitle} />)} />
							<Route path="/about/changelog" render={(props) => (<Changelog {...props as any} updateHeaderTitle={updateHeaderTitle} />)} />
							<Route path="/about/securityrules" render={(props) => (<SecurityRules {...props as any} updateHeaderTitle={updateHeaderTitle} />)} />
							<Route path="/admin/tours/createslot" render={(props) => (<CreateSlot {...props as any} updateHeaderTitle={updateHeaderTitle} />)} />
							<Route path="/admin/search" render={(props) => (<ParticipantLookup {...props} updateHeaderTitle={updateHeaderTitle} />)} />
							{/* <Route path="/admin/settings" render={(props) => (<Settings {...props as any} updateHeaderTitle={updateHeaderTitle} />)} /> */}
							<Route path="/admin/settings" render={(props) => (<SettingsV2 {...props as any} updateHeaderTitle={updateHeaderTitle} />)} />
							<Route path="/superadmin/locations/create" render={(props) => (<CreateLocation {...props as any} updateHeaderTitle={updateHeaderTitle} />)} />
							<Route render={(props) => (<Four04Page {...props as any} updateHeaderTitle={updateHeaderTitle} />)} />

						</Switch>
					</main>
					{!window.location.href.includes("/agreement/") && <Footer />}
				</HashRouter>
			</UserProvider>
		</div>
	)
}

export default App






