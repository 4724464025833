import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import moment from "moment";
import useStyles from '../../styles/components/summaryandconfirm'
import { CircularProgress } from "@material-ui/core";

const SummaryAndConfirm = (props: any) => {
  const classes = useStyles();

  useEffect(() => {
    
    props.updateInfoText('You are almost done! Please read the "Tour summary" and the "What will happen now" secions below and hit "CONFIRM"')
  }, [])


  return (
    <div>
      <div
        style={{ textAlign: "left", maxWidth: "600px", margin: "0 auto 2rem" }}
      >
        <Typography variant="subtitle1" component="h2">
          <b>Tour summary</b>
        </Typography>
        <Typography variant="subtitle2" component="h2">
          Participant's email: <b>{props.booking.user.username}</b>
        </Typography>
        <Typography variant="subtitle2" component="h2">
          Participant's full name: <b>{props.booking.user.fullname}</b>
        </Typography>
        <Typography variant="subtitle2" component="h2">
          Participant's manager: <b>{props.booking.user.manager}</b>
        </Typography>
        <Typography variant="subtitle2" component="h2">
          Participant's company name: <b>{props.booking.user.companyname}</b>
        </Typography>
        <Typography variant="subtitle2" component="h2">
          Selected location:{" "}
          <b>{props.booking.locationAndSlot.location.name}</b>
        </Typography>
        <Typography variant="subtitle2" component="h2">
          Selected time slot:{" "}
          <b>
            
              {moment(props.booking.locationAndSlot.slot.slottime).format('YYYY-MM-DD - HH:mm')}
          </b>
        </Typography>
        <br />
        <br />
        <Typography variant="subtitle1" component="h2">
          <b>What will happen now?</b>
        </Typography>
        <Typography variant="subtitle2">
          Once you have created the booking by clicking confirm, a confirmation
          e-mail will be sent to the tour participant with the following
          information:
          <br />
          {props.booking.locationAndSlot.slot.format === "Online"
            ? props.booking.locationAndSlot.location.emailtemplateonline
            : props.booking.locationAndSlot.location.emailteamplateoffline}
          <br />
          Date and time:{" "}
          <b>
          {moment(props.booking.locationAndSlot.slot.slottime).format('YYYY-MM-DD - HH:mm')}
          </b>
          <br />
          Location: <b>{props.booking.locationAndSlot.location.name}</b>
          <br />
          Tour format: <b>{props.booking.locationAndSlot.slot.format === 'Offline' ? 'On site' : props.booking.locationAndSlot.slot.format}</b>
          <br />
          {props.booking.locationAndSlot.slot.format === "Online"
            ? "Tour Link: " + props.booking.locationAndSlot.slot.meetinglink.slice(0, 30) + '...'
            : ""}
          <br />
        </Typography>
      </div>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        
      <Button
        onClick={() => {props.prevStep(); props.showWelcomeMessageFn(true)}}
        className={classes.buttons}
        variant="contained"
      >
        Back
      </Button>
      <Button
        className={classes.buttons}
        onClick={props.confirm}
        variant="contained"
        disabled={props.loadingWhenRwquest ? true : false}
      >
        Confirm
      </Button>
      {props.loadingWhenRwquest ? <CircularProgress size={24} /> : <div style={{width: '24px'}}></div> }
      </div>
      
    </div>
  );
};

export default SummaryAndConfirm;
