import React, { useState } from "react";
import { pdfjs } from "react-pdf";
import Typography from "@material-ui/core/Typography";
//@ts-ignore
import PDF from '../components/pdf/PDF'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Agreement(props: any) {
  const [isDocSigned, setIsDocSigned] = useState(false);

  const url = `${process.env.REACT_APP_REDIRECT_URL}Safety and security information.pdf`;

  const lastIndex = props.url.lastIndexOf("/");
  const id = props.url.slice(lastIndex + 1);

  const isDocSignedFn = (bool: any) => {
    setIsDocSigned(bool)
  }

  if (isDocSigned) {
    return (
      <Typography style={{ marginTop: '100px', display: "flex", justifyContent: "center" }} variant="h5">
        <div style={{ display: 'flex', justifyContent: 'center', width: "75%", textAlign: "left" }}>
          Thank you, you are now welcome to pick up your card at the Startbox between office hours 08-16:30.
        </div>
      </Typography>
    );
  } else {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PDF id={id as any} isDocSignedFn={isDocSignedFn}/>
      </div>
    );
  }
}

export default Agreement;
