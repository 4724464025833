import React, { useState, useEffect, useRef } from 'react';
import { UseUser } from "../context/UserContext";
import {
  Typography,
  LinearProgress,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import useStyles from '../styles/views/deletecoworker';
import HelpIcon from '@material-ui/icons/Help';
import axios from 'axios';
import { checkValidEmail } from '../utilities/validation';
import moment from 'moment';

const ParticipantLookup = ({ updateHeaderTitle }: { updateHeaderTitle: Function }) => {
  const classes = useStyles();
  const emailRef = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [access, setAccess] = useState<boolean>(false);
  const [user, setUser] = useState<object>({});
  const [locations, setLocations] = useState<any>([]);
  const [currentLocation, setCurrentLocation] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [bookings, setBookings] = useState<any>([]);
  const [isLoadingBookings, setIsLoadingBookings] = useState<boolean>(false);

  useEffect(() => {
    updateHeaderTitle('Search participant');
  }, []);

  useEffect(() => {
    (async () => {
      if (!Object.keys(user).length) return;
      const requests: any[] = [];

      // @ts-ignore
      for (let location of user.admineditorlocations) {
        const request = axios(`/admin/locations/${location}?includeSlots=true`);
        requests.push(request);
      }

      const responses = await Promise.all(requests);
      const locations = responses.map((response) => {
        return response.data;
      });

      setLocations(locations);
    })();
  }, [user]);

  useEffect(() => {
    if (!locations.length) return;
    setCurrentLocation(locations[0].name);
  }, [locations]);

  useEffect(() => {
    if (!Object.keys(user).length || !locations.length) return;
    setIsLoading(false);
  }, [user, locations]);

  const fetchTableData = async () => {
    //@ts-ignore
    const emailTemp = emailRef.current.value;
    if (!checkValidEmail(emailTemp)) return setError("E-mail must be valid.");

    setIsLoadingBookings(true);
    //@ts-ignore
    const location = locations.find(loc => loc.name === currentLocation);
    if (!location) return setError("Could not find location, try again.");
    const response = await axios(`/admin/bookings/${location.id}/${emailTemp}`);
    const sorted = response.data.data.sort((a: any, b: any) => {
      return (a.slottime > b.slottime) ? -1 : ((a.slottime < b.slottime) ? 1 : 0);
    });
    console.log(sorted)
    setBookings(sorted);
    setIsLoadingBookings(false);
  };

  const getUserFromContext = async () => {
    const userContext = await UseUser();
    setUser(userContext);
    //@ts-ignore
    if (userContext.admineditorlocations) {
      //@ts-ignore
      if (userContext.admineditorlocations.length > 0) {
        setAccess(true);
      }
    };
    setIsLoading(false);
  };

  const handleTextChange = (value: any) => {
    if (checkValidEmail(value)) setError("");
  };

  getUserFromContext();

  if (isLoading) {
    return (
      <div className={classes.loadingLinear}>
        <Typography variant="h6" component="h2">
          Loading...
      </Typography>
        <LinearProgress />
      </div>
    )
  } else if (!isLoading && !access) {
    return (
      <div>
        <Typography variant="h4">You do not have the correct admin rights to show this page.</Typography>
        <Typography variant="h6"><Link to="/" style={{ color: '#0077c1' }}>Go back to main page</Link></Typography>
      </div>
    )
  } else {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ display: "flex", margin: "20px 0", justifyContent: "center", alignItems: "center" }}>
            <HelpIcon style={{ display: "flex", alignSelf: "flex-center", marginRight: "10px", color: "#0077c1" }} />
            <div>
              <Typography style={{ color: "#5c5c5c" }} variant="subtitle2">
                On this page your can search for a participant to see if they have attended their tour.
              </Typography>
            </div>
          </div>
          <form
            style={{ display: "flex", justifyContent: "space-between", width: "40%", alignItems: "flex-end", position: "relative" }}
            onSubmit={(e) => {
              e.preventDefault();
              fetchTableData();
            }}>
            <FormControl
              className={classes.formControl}
            >
              <InputLabel>Location</InputLabel>
              <Select
                labelId="locationSelect"
                id="locationSelect"
                value={currentLocation}
                //@ts-ignore
                onChange={(e) => { setCurrentLocation(e.target.value) }}
              >
                {
                  locations.map((location: any) => {
                    return (
                      <MenuItem key={location.id} value={location.name} id={location.id}>
                        {location.name}
                      </MenuItem>
                    );
                  })
                }
              </Select>
            </FormControl>
            <TextField
              inputRef={emailRef}
              style={{ position: "absolute", minHeight: "70px", left: "33%", top: 0 }}
              error={error ? true : false}
              onChange={(e) => handleTextChange(e.target.value)}
              label="Email Address"
              helperText={error && error}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button type="submit" className={classes.showBookings} variant="contained" color="primary" size="medium">Search</Button>
              <Tooltip
                title='Pressing this button will display a list of all tours where the entered participant is booked for.'
                placement="top"
              >
                <HelpIcon style={{ color: "#828282" }} />
              </Tooltip>
            </div>
          </form>
          <TableContainer component={Paper} style={{ marginTop: "40px" }}>
            <Table className={classes.table}>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell><b>Tour date</b></TableCell>
                  <TableCell><b>Participant email</b></TableCell>
                  <TableCell><b>Participant name</b></TableCell>
                  <TableCell><b>Participated</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  isLoadingBookings ? (
                    <TableRow>
                      <TableCell>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : bookings.length ? (
                    bookings.map((booking: any) => (
                      <TableRow key={booking.id}>
                        <TableCell align="left">
                          {moment(booking.slottime).format("YYYY-MM-DD - HH:mm")}
                        </TableCell>
                        <TableCell component="th" scope="row">{booking.user}</TableCell>
                        <TableCell component="th" scope="row">{`${booking.givenname} ${booking.surname}`}</TableCell>
                        <TableCell component="th" scope="row">
                          {
                            booking.attended ? (
                              <Tooltip
                                title="Co-worker has participated the tour"
                                placement="top-start"
                                arrow
                              >
                                <Check style={{ color: "#3ea354" }} />
                              </Tooltip>
                            ) : (
                                <Tooltip
                                  title="Co-worker has not participated the tour"
                                  placement="top-start"
                                  arrow
                                >
                                  <Close style={{ color: "#ff4f4f", }} />
                                </Tooltip>
                              )
                          }
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                        <TableRow>
                          <TableCell>No bookings to display</TableCell>
                        </TableRow>
                      )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }
};

export default ParticipantLookup;