import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";

import useStyles from '../styles/components/footer'


const Footer = (props: any) => {
  const classes = useStyles();

  return (
    <footer
      style={{
        position: "relative",
        left: "0px",
        bottom: "0px",
        width: "100%",
        height: "70px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
      }}
    >
      <div></div>
      <Typography variant="subtitle1">© EDGE 2022</Typography>
      <Link className={classes.versionLink} to="/about/changelog">
        <Fab size="small" disabled aria-label="like">
          <Typography variant="caption">V2.3</Typography>
        </Fab>
      </Link>
    </footer>
  );
};

export default Footer;
