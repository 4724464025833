import React, { useState, useEffect } from "react";
import axios from "axios";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import HelpIcon from "@material-ui/icons/Help";

import {
  Table,
  Button,
  LinearProgress,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TableBody,
  IconButton,
  Typography,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Tooltip,
  Snackbar,
} from "@material-ui/core";

import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { UseUser } from "../context/UserContext";

import moment from "moment";
import useStyles from "../styles/views/mybookings";

const MyBookings = (props: any) => {
  const classes = useStyles();

  const [isUser, setIsUser] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [user, setUser] = useState({} as any);
  const [openDialog, setOpenDialog] = useState(false);
  const [bookingToDelete, setBookingToDelete] = useState({
    id: undefined,
    username: undefined,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [currLocation, setCurrLocation] = useState("");
  const [locations, setLocations] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [filteredTourTimes, setFilteredTourTimes] = useState("all");

  const getUserFromContext = async () => {
    const getUser = await UseUser();

    setUser(getUser);

    setIsUser(true);
    setIsLoading(false);
  };
  getUserFromContext();

  useEffect(() => {
    setTableLoading(true);
    props.updateHeaderTitle("Co-worker bookings");
    const setUserAndBookingsFn = async () => {
      try {
        const locationsToUse: any = await axios({
          method: "get",
          url: "/user/locations",
        });

        setLocations(locationsToUse.data);

        const locationsArr = ["All"] as any;

        //@ts-ignore
        locationsToUse.data.forEach((booking: any) => {
          locationsArr.push(booking.name);
        });
        setCurrLocation(locationsArr[0]);

        const userBookings = await axios("/user/bookings");

        userBookings.data.sort(function (a: any, b: any) {
          a = moment(a.slottime).format("YYYY-MM-DD");
          b = moment(b.slottime).format("YYYY-MM-DD");

          if (a < b) {
            return -1;
          }
          if (b < a) {
            return 1;
          }
          return 0;
        });
        console.log(userBookings.data);
        setRawData(userBookings.data);
        setTableData(filterBookings(userBookings.data, locationsArr[0]));
        setTableLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          setErrorMsg("Error: You are not Authorized, try again");
        } else if (err.response.status === 403) {
          setErrorMsg("Error: You have no permission, try again");
        } else if (err.response.status === 422) {
          setErrorMsg("Error: You sent bad data, try again");
        } else if (err.request) {
          setErrorMsg("Error: Server responded with and error, try again");
        }
        setTableLoading(false);
      }
    };

    // här kör vi callet till custommessage

    setUserAndBookingsFn();
  }, []);

  /* const handleClickOpen = () => {
    setOpenDialog(true);
  }; */
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    //@ts-ignore
    //setFilteredTourTimes("all");
    setCurrLocation(event.target.value as any);
  };
  const handleChangeTourTime = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    //@ts-ignore
    setFilteredTourTimes(event.target.value as any);
  };

  useEffect(() => {
    setTableData(filterBookings(rawData, currLocation));
  }, [currLocation, filteredTourTimes]);

  const handleClose = async () => {
    setErrorMsg("");
    setOpenDialog(false);
  };
  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const filterBookings = (data: any, location: any) => {
    if (location === "All") {
      return data;
    }

    const newData = data.filter((booking: any) => {
      if (filteredTourTimes === "all") {
        return location === booking.locationname;
      }
      return (
        location === booking.locationname &&
        filteredTourTimes ===
          moment(booking.slottime).format("YYYY-MM-DD - HH:mm")
      );
    });

    return newData;
  };

  const deleteBooking = async (bool: Boolean) => {
    if (bool) {
      try {
        const response = await axios({
          method: "DELETE",
          url: `/user/bookings/${bookingToDelete.id}`,
        });

        if (response && response.status === 204) {
          setOpenSnackBar(true);
          try {
            const userBookings = await axios("/user/bookings");

            userBookings.data.sort(function (a: any, b: any) {
              a = moment(a.slottime).format("YYYY-MM-DD");
              b = moment(b.slottime).format("YYYY-MM-DD");

              if (a < b) {
                return -1;
              }
              if (b < a) {
                return 1;
              }
              return 0;
            });
            setRawData(userBookings.data);
            setTableData(filterBookings(userBookings.data, currLocation));
          } catch (err) {
            if (err.response.status === 401) {
              setErrorMsg("Error: You are not Authorized, try again");
            } else if (err.response.status === 403) {
              setErrorMsg("Error: You have no permission, try again");
            } else if (err.response.status === 422) {
              setErrorMsg("Error: You sent bad data, try again");
            } else if (err.request) {
              setErrorMsg("Error: Server responded with and error, try again");
            }
          }

          setBookingToDelete({ id: undefined, username: undefined });
          handleClose();
        }
      } catch (err) {
        if (err.response.status === 401) {
          setErrorMsg("Error: You are not Authorized, try again");
        } else if (err.response.status === 403) {
          setErrorMsg("Error: You have no permission, try again");
        } else if (err.response.status === 422) {
          setErrorMsg("Error: You sent bad data, try again");
        } else if (err.request) {
          setErrorMsg("Error: Server responded with and error, try again");
        }

        //setErrorMsg()
      }
    } else {
      handleClose();
    }
  };

  const newBooking = () => {
    props.history.push("/");
  };
  const createData = (
    username: any,
    slottime: any,
    attended: any,
    termsaccepted: any,
    manager: any,
    slotid: any,
    id: any,
    slotstatus: any,
    locationname: any,
    givenname: any,
    surname: any
  ) => {
    return {
      username,
      slottime,
      attended,
      termsaccepted,
      manager,
      slotid,
      id,
      slotstatus,
      locationname,
      givenname,
      surname,
    };
  };
  const bookingToDeleteBooking = (booking: any) => {
    // Implement user/booking delete when endpoing arrives
    setBookingToDelete(booking as any);

    setOpenDialog(true);
  };

  const rows = tableData.map((booking: any) => {
    //@ts-ignore
    return createData(
      booking.user,
      booking.slottime,
      booking.attended,
      booking.termsaccepted,
      booking.manager,
      booking.slotid,
      booking.id,
      booking.slotstatus,
      booking.locationname,
      booking.givenname,
      booking.surname
    );
  });

  const headCells = [
    "Location",
    "Tour date",
    "Participant email",
    "Participant Name",
    "Participated",
    /* "Terms accepted", */
    "Tour status",
    "Delete",
  ];

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const setTempGivenname = (user: any) => {
    
    const firstNameIndex = user.indexOf(".");
    const lastNameIndex = user.indexOf("@");
    let firstname = user.slice(0, firstNameIndex);

    const regExp = /[0-9]/g;

    if(regExp.test(firstname)) {
      firstname = firstname.replace(regExp, '')
    }

    let lastname = user.slice(firstNameIndex + 1, lastNameIndex);
    lastname = lastname.charAt(0).toUpperCase() + lastname.slice(1);
    firstname = firstname.charAt(0).toUpperCase() + firstname.slice(1);

    return `${firstname} ${lastname}`;
  };

  if (loading) {
    return (
      <div className={classes.loadingLinear}>
        <Typography variant="h6" component="h2">
          Loading...
        </Typography>
        <LinearProgress />
      </div>
    );
  } else if (!isUser) {
    return <div>Can't find User</div>;
  } else {
    return (
      <>
        <div>
          <div className={classes.welcomeMessage}></div>
          <div className={classes.welcomeMessage}>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                color: "#5c5c5c",
              }}
            >
              <HelpIcon
                style={{
                  alignSelf: "flex-end",
                  marginRight: "10px",
                  color: "#0077c1",
                }}
              ></HelpIcon>
              <Typography
                style={{ color: "#5c5c5c", marginTop: "10px" }}
                variant="subtitle2"
              >
                Here you can sign up your co–worker for the welcome and security
                introduction. To start the process, click the "NEW BOOKING"
                button.
              </Typography>
            </div>
          </div>
          <div className={classes.tableHeaderWrapper}>
            <div className={classes.refreshAndLocationWrapper}>
              <FormControl className={classes.formControl}>
                <InputLabel
                  className={classes.inputLabel}
                  id="demo-simple-select-label"
                >
                  Location
                </InputLabel>

                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  labelId="locationSelect"
                  id="locationSelect"
                  className={classes.underlineSelect}
                  //@ts-ignore
                  value={currLocation}
                  onChange={handleChange}
                >
                  <MenuItem key="All" value="All">
                    All
                  </MenuItem>
                  {locations.map((location: any) => {
                    return (
                      <MenuItem key={location.id} value={location.name}>
                        {location.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <Button
              variant="contained"
              className={classes.newBookingButton}
              onClick={newBooking}
            >
              New Booking
            </Button>
          </div>
          <TableContainer
            className={classes.tableContainer}
            elevation={1}
            component={Paper}
          >
            <Table
              stickyHeader
              className={classes.table}
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  {headCells.map((head, index) => (
                    <TableCell
                      className={classes.tableHeader}
                      key={index}
                      align="left"
                    >
                      <b>{head}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableLoading ? (
                  <TableRow>
                    <TableCell align="left">
                      <div>
                        <Typography variant="h6" component="h2">
                          Loading...
                        </Typography>
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : rows.length < 1 ? (
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">
                      <h3>No Bookings</h3>
                    </TableCell>
                    {/* <TableCell align="left"></TableCell> */}
                    <TableCell align="left"></TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const rowThatNotPass = moment(row.slottime).isBefore(
                      moment().subtract("1", "month")
                    );

                    if (rowThatNotPass) {
                      return;
                    }

                    return (
                      <TableRow key={row.id}>
                        <TableCell align="left">{row.locationname}</TableCell>
                        <TableCell align="left">
                          {moment(row.slottime).format("YYYY-MM-DD - HH:mm")}
                        </TableCell>
                        <TableCell align="left" style={row.username.toLowerCase() === "deleted" ? { opacity: 0.5 } : undefined}>{row.username}</TableCell>

                        {row.givenname ? (
                            <TableCell align="left" style={row.givenname.toLowerCase() === "deleted" ? { opacity: 0.5 } : undefined}>
                              {row.givenname.toLowerCase() === "deleted" ? "deleted" : `${row.givenname} ${row.surname}`}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {setTempGivenname(row.username)}
                            </TableCell>
                          )
                        }

                        {row.attended ? (
                          <TableCell align="left">
                            <Tooltip
                              title="Co-worker has participated the tour"
                              placement="top-start"
                              arrow
                            >
                              <CheckIcon className={classes.attendButton} />
                            </Tooltip>
                          </TableCell>
                        ) : (
                          <TableCell align="left">
                            <Tooltip
                              title="Co-worker has not participated the tour"
                              placement="top-start"
                              arrow
                            >
                              <CloseIcon className={classes.notAttendButton} />
                            </Tooltip>
                          </TableCell>
                        )}
                        {row.slotstatus ? (
                          <TableCell align="left">
                            <Tooltip
                              title="Tour has been completed"
                              placement="top-start"
                              arrow
                            >
                              <CheckIcon className={classes.attendButton} />
                            </Tooltip>
                          </TableCell>
                        ) : (
                          <TableCell align="left">
                            <Tooltip
                              title="Tour has not been completed"
                              placement="top-start"
                              arrow
                            >
                              <CloseIcon className={classes.notAttendButton} />
                            </Tooltip>
                          </TableCell>
                        )}

                        <TableCell align="left">
                          <Tooltip
                            title="Delete booking"
                            placement="top-start"
                            arrow
                          >
                            {row.attended ||
                            row.slotstatus ||
                            moment(row.slottime).isBefore(moment()) ? (
                              <div></div>
                            ) : (
                              <IconButton
                                style={{ cursor: "pointer" }}
                                onClick={() => bookingToDeleteBooking(row)}
                                size="small"
                                aria-label="delete"
                                className={classes.iconButton}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div>
          <Dialog
            classes={{ paper: classes.dialogPaper }}
            fullWidth={true}
            maxWidth={"md"}
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete booking?"}
            </DialogTitle>
            <DialogContent>
              <Typography variant="subtitle2">
                You are about to delete the booking for{" "}
                <b>{bookingToDelete.username}</b> <br /> {`Are you sure?`}{" "}
                <br />
                <br />
                <span className={classes.errorMsg}>{errorMsg}</span>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.dialogButtons}
                onClick={() => deleteBooking(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                className={classes.dialogButtons}
                onClick={() => deleteBooking(true)}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={openSnackBar as any}
            autoHideDuration={6000}
            onClose={handleCloseSnackBar}
          >
            <Alert onClose={handleCloseSnackBar} severity="success">
              Booking deleted!
            </Alert>
          </Snackbar>
        </div>
      </>
    );
  }
};

export default MyBookings;
