import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
const UserContext = React.createContext({}); //User empty object to give argument it asked for, not sure if this interfers with anything.
export const UserFetch = React.createContext({});

export async function UseUser() {
  return useContext(UserContext);
}

//@ts-ignore
export function UserProvider({ children }) {
  const [user, setUser] = useState({});

  const fetchUserData = async () => {
    try {
      const response = await axios.get('/user/mydata');
      setUser(response.data);
    } catch(error) {
      //@ts-ignore
      if(error.message === "no_tokens_found: No refresh token found in the cache. Please sign-in.") {
        localStorage.clear();
        window.location.reload();
      }
    };
  }
  

  useEffect(() => {
    (async () => {
      await fetchUserData();
    })();
  }, []);

  return (
    <UserFetch.Provider value={fetchUserData}>
      <UserContext.Provider value={user}>
        { children }
      </UserContext.Provider>
    </UserFetch.Provider>
  )
}