import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import MyBookings from "Views/MyBookings";
import useStyles from '../styles/components/excelexport'


const ExcelExport = (props: any) => {
  const classes = useStyles();

  const fileExtension = ".xlsx";

  const exportToExcel = async (csvData: any /* fileName: any */) => {
    const bookings = csvData.bookings;

    const rawTourData = csvData;
    const tourDate =
    
    moment(rawTourData.slottime).format('YYYY-MM-DD - HH:mm')
    const date = rawTourData.slottime.slice(0, 10);
    //@ts-ignore
    const arr: any = [];

    arr.push(["Tour Details"]);
    arr.push([""]);
    arr.push([
      "Tour Time",
      "Status",
      "Number of bookings",
      "Number of slots",
      "Tour format",
      "Comments",
      "Created by",
      "Edited by",
      "Meeting link",
    ]);
    arr.push([
      tourDate,
      !rawTourData.slotstatus ? "Open" : "Closed",
      rawTourData.nbrofbookings,
      rawTourData.slotcount,
      rawTourData.format,
      rawTourData.comment,
      rawTourData.createdby,
      rawTourData.lastchangedby,
      rawTourData.meetinglink,
    ]);

    arr.push([""]);
    arr.push(["Tour Bookings"]);
    arr.push([""]);
    bookings.forEach((booking: any, index: any) => {
      if (index === 0) {
        arr.push([
          "Co-worker",
          "Company name",
          "Manager",
          "Booker",
          "Participated",
          "Booking deleted",
          "Signed document",
          "Serix",
          "CDS Ok",
        ]);
      }

      arr.push([
        booking.user,
        booking.companyname,
        booking.manager,
        booking.booker,
        !booking.attended ? "No" : "Yes",
        !booking.deleted ? "No" : "Yes",
        !booking.signeddoc ? "No" : "Yes",
        !booking.serix ? "No" : "Yes",
        !booking.CDSOk ? "No" : "Yes",
      ]);
    });

    let wb = XLSX.utils.book_new();
    wb.Props = {
      //@ts-ignore
      Title: tourDate,
      //@ts-ignore
      Subject: `Tour details`,
      Author: "INGKA",
      CreatedDate: new Date(),
    };

    wb.SheetNames.push(date);
    let ws_data = arr;
    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets[date] = ws;
    let wbout = await XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    function s2ab(s: any) {
      let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
      let view = new Uint8Array(buf); //create uint8array as viewer
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
      return buf;
    }

    FileSaver.saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `${tourDate}${fileExtension}`
    );
  };

  return (
    <>
      <Typography variant="caption" component="h2">
        <Tooltip title="Export to Excel" placement="top-start" arrow>
          <IconButton
            onClick={(e) => exportToExcel(props.csvData /* fileName */)}
            className={classes.exportToExcelButton}
          >
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};

export default ExcelExport;
