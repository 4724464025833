import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: "100%",
  },
  menuItem: {
    textDecoration: "none",
    "&:visited": {
      textDecoration: "none",
    },
  },
  linkText: {
    paddingBottom: "4px",
    display: "flex",
    alignItems: "center",
    marginTop: "0px",
    paddingLeft: "30px",
    color: "black",
  },
  linkTextTitle: {
    fontSize: "16px",
    marginTop: "20px",
    marginLeft: "0px",
    fontWeight: "bold",
    color: "black",
    "&.Mui-disabled": {
      backgroundColor: "#fff",
      color: "#000",
      pointerEvents: "none",
      opacity: 1,
    },
  },
  nameHeader: {
    marginBottom: "6px",
    width: "100%",
    color: "#004a78",
  },
  icons: {
    paddingRight: "4px",
    color: "#004a78",
  },
  divider: {
    marginTop: "10px",
  },
  logoutButtonWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  logoutButton: {
    width: "100%",
    backgroundColor: "#0077c1",
    color: "white",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#0089de",
    },
  },
});

export default useStyles