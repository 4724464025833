import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingLinear: {
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    circularProgress: {
      marginRight: "22px",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    },
    refreshAndLocationWrapper: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
    inputLabel: {
      "&.Mui-focused": {
        color: "#0077c1",
      },
    },
    underlineSelect: {
      "&:before": {
        borderColor: "#0077c1",
      },
      "&:after": {
        borderColor: "#0077c1",
      },
    },
    leftHeaderText: {
      padding: "8px",
    },
    tableHeader: {
      backgroundColor: "#f5f5f5",
    },
    iconButton: {
      color: "#ff4f4f",
    },
    refreshButton: {
      color: "#0077c1",
      marginRight: "14px",
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    tableContainer: {
      //paddingTop: theme.spacing(2),
      //marginTop: theme.spacing(8),
    },
    table: {
      minWidth: 650,
    },
    tableHeaderWrapper: {
      marginTop: "20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    newBookingButton: {
      color: "white",
      backgroundColor: "#0077c1",
      "&:hover": {
        backgroundColor: "#0084d6"
      },
      fontSize: "18px",
    },
    dialog: {
      width: "70%",
    },
    dialogButtons: {
      color: "#0077c1",
    },
    attendButton: {
      color: "green",
    },
    notAttendButton: {
      color: "#ff4f4f",
    },
    errorMsg: {
      color: "#ff4f4f",
    },
    dialogPaper: {
      minHeight: "250px",
      maxHeight: "250px",
    },
    welcomeMessage: {
      marginTop: "20px",
    },
  })
);

export default useStyles