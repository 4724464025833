import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    exportToExcelButton: {
      marginRight: "10px",
      backgroundColor: "#3ea354",
      color: "white",
      "&:hover": {
        backgroundColor: "#3b8a4c",
      },
    },
  })
);

export default useStyles