import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 250,
    },
    dialogPaper: {
      minHeight: "250px",
      maxHeight: "250px",
      width: "600px",
    },
    sendMessageDialog: {
      minHeight: "300px",
      maxHeight: "300px",
    },
    dialogEdit: {
      maxHeight: "670px",
    },
    errorMsg: {
      color: "#ff4f4f",
    },
    loadingLinear: {
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    loadingLinear2: {
      width: "80%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    root: {
      flexGrow: 1,
    },
    formControl: {
      minWidth: 200,
      marginBottom: "8px",
    },
    inputLabel: {
      "&.Mui-focused": {
        color: "#0077c1",
      },
    },
    underlineSelect: {
      "&:before": {
        borderColor: "#0077c1",
      },
      "&:after": {
        borderColor: "#0077c1",
      },
    },
    copyToClipboard: {
      marginLeft: "10px",
      paddingTop: "2px",
      color: "#3ea354",
    },

    paperDetailHeaderWrapperAll: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginBottom: "50px",
    },
    tableContainer: {
      maxHeight: '700px',
    },
    
    activeB: {},
    notActiveB: {},
    activeCell: {
      color: "#0077c1",
      cursor: "pointer",
      display: "flex",
      width: "100%",
      userSelect: "none",
    },
    notActiveCell: {
      cursor: "pointer",
      display: "flex",
      width: "100%",
      userSelect: "none",
    },
    disableArrow: {
      color: "#f5f5f5",
      pointerEvents: "none",
    },
    tableHeader: {
      backgroundColor: "#f5f5f5",
    },
    enableArrow: {
      pointerEvents: "none",
    },
    tableCell: {
      padding: "0px",
    },
    paper: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      marginTop: theme.spacing(6),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paperDetail: {
      paddingTop: theme.spacing(6),

      color: theme.palette.text.secondary,
    },
    paperDetailHeaderWrapperLeft: {
      marginRight: theme.spacing(4),
    },
    paperDetailHeaderWrapperRight: {},
    paperDetailHeaderWrapperContent: {
      marginBottom: theme.spacing(6),
    },
    paperDetailHeader: {
      //paddingRight: theme.spacing(6),
    },
    table: {
      minWidth: 650,
    },
    tableHeaderWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "20px",
    },
    attendButton: {
      color: "#3ea354",
    },
    notAttendButton: {
      color: "#ff4f4f",
    },
    deleteButton: {
      backgroundColor: "#ff4f4f",
      color: "white",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#ff2b2b",
      },
    },
    deleteButtonList: {
      backgroundColor: "white",
      color: "#ff4f4f",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        color: "#ff2b2b",
      },
    },
    dialogButtons: {
      color: "#0077c1",
    },
    filterButtonsWrapper: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "8px",
      alignItems: "flex-end",
    },
    iconTypoWrapper: {
      display: "flex",
    },
    icon: {
      marginRight: "20px",
    },
    copyIcon: {
      marginLeft: "10px",
      fontSize: "20px",
      cursor: "pointer",
    },
    paperDetailHeaderWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    infoPaperDetail: {
      color: theme.palette.text.secondary,
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    deleteDoneWrapper: {
      display: "flex",
    },
    doneButton: {
      marginRight: "10px",
      backgroundColor: "#cca46a",
      color: "white",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#c9aa7d",
      },
    },
    sendMessagesButton: {
      marginRight: "10px",
      backgroundColor: "#0077c1",
      color: "white",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#0084d6",
      },
    },
    editButton: {
      marginRight: "10px",
      backgroundColor: "#9800b8",
      color: "white",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#af00d4",
      },
    },
    detailsTextAreaWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    textBox: {
      width: "340px",
      marginBottom: "10px",
    },
    label: {
      "&$focusedLabel": {
        color: "#0077c1",
      },
      "&$erroredLabel": {
        /* color: "orange" */
      },
    },
    focusedLabel: {},
    erroredLabel: {},
    underline: {
      "&$error:after": {
        /*  borderBottomColor: "orange" */
      },
      "&:after": {
        border: `1px solid #0077c1`,
      },
    },
    saveCommentButton: {
      width: "200px",
      alignSelf: "flex-end",
      backgroundColor: "#0077c1",
      color: "white",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#0084d6",
      },
    },
    tourCommentAndButton: {
      display: "flex",
      width: "100%",
    },
    tourComment: {
      width: "100%",
    },
    insideDialogWrapperUpp: {

      width: "100%",
      marginBottom: "30px",
      marginTop: "50px",
    },
    insideDialogWrapperDown: {
      display: "flex",
      width: "90%",
      marginBottom: "10px",
    },
    textBoxInside: {
      marginRight: "20px",
      width: "100%",
    },
    insideDialogSendMessageButtonSendMessage: {
      width: "200px",
      alignSelf: "flex-end",
      backgroundColor: "#0077c1",
      color: "white",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#0084d6",
      },
    },
    insideDialogSendMessageButtonResendLink: {
      alignSelf: "flex-end",
      backgroundColor: "#0077c1",
      color: "white",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#0084d6",
      },
    },
    tourCommentOnFirstPageWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },
    tourCommentOnFirstPageContent: {
      display: "block",
      width: "300px",
      overflowWrap: "break-word",
      textAlign: "left",
      padding: "16px",
      whiteSpace: "pre-line",
    },
    tourCommentWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    tourCommentPaper: {},
    statusOpen: {
      color: "#3ea354",
    },
    statusClosed: {
      color: "#ff4f4f",
    },
    refreshButton: {
      color: "white",
      marginRight: "10px",
      backgroundColor: "#ebad00",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#e0a500",
      },
    },
    detailsAndRefreshWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "stretch",
    },
    sendMessageUnderLineMessage: {
      textAlign: "right",
      marginRight: "86px",
    },
    resendLinkButtonAndIcon: {
      display: "flex",
      marginTop: "30px",
    },
    refreshParticipantCDSButton: {
      color: "#0077c1",
    },
    userCommentButton: {
      marginTop: "4px"
    }
  })
);

export default useStyles