import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./BurgerMenu.css";
import Icon from "@material-ui/core/Icon";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import CachedIcon from "@material-ui/icons/Cached";
import ApartmentIcon from '@material-ui/icons/Apartment';
import Search from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import DescriptionIcon from "@material-ui/icons/Description";
import clsx from "clsx";

import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import SettingsIcon from "@material-ui/icons/Settings";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ListAltIcon from '@material-ui/icons/ListAlt';
import { UseUser } from "../context/UserContext";


import useStyles from '../styles/components/burgermenu'


type Anchor = "top" | "left" | "bottom" | "right";

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [adminRole, setAdminRole] = useState("");
  const [user, setUser] = useState({}) as any;

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const getUserFromContext = async () => {
    const user = await UseUser();

    //@ts-ignore
    if (!user.admineditorlocations || !user.adminreaderlocations) {
      return;
    }
    //@ts-ignore
    if (user.admineditorlocations.length > 0) {
      setAdminRole("adminEditor");
      //@ts-ignore
    } else if (user.adminreaderlocations.length > 0) {
      setAdminRole("adminReader");
    } else {
      setAdminRole("");
    }
    setUser(user);
  };
  getUserFromContext();


  const superAdminLinks = [
    {
      name: "Create location",
      href: "/superadmin/locations/create",
      icon: (
        <Icon>
          <ApartmentIcon fontSize="small" />
        </Icon>
      ),
    }
  ];  

  const adminLinks = [
    {
      name: "Tours",
      href: "/admin/tours",
      icon: (
        <Icon>
          <CachedIcon fontSize="small" />
        </Icon>
      ),
    },
    {
      name: "Create tour",
      href: "/admin/tours/createslot",
      icon: (
        <Icon>
          <AddCircleOutlineIcon fontSize="small" />
        </Icon>
      ),
    },
    // {
    //   name: "Delete co-worker",
    //   href: "/admin/delete",
    //   icon: (
    //     <Icon>
    //       <DeleteOutline fontSize="small" />
    //     </Icon>
    //   ),
    // },
    {
      name: "Search participant",
      href: "/admin/search",
      icon: (
        <Icon>
          <Search fontSize="small" />
        </Icon>
      ),
    },
    {
      name: "Settings",
      href: "/admin/settings",
      icon: (
        <Icon>
          <SettingsIcon fontSize="small" />
        </Icon>
      ),
    },
  ];

  const userLinks = [
    {
      name: "Assign co-worker",
      href: "/",
      icon: (
        <Icon>
          <NoteAddIcon fontSize="small" />
        </Icon>
      ),
    },
    {
      name: "Co-worker bookings",
      href: "/bookings",
      icon: (
        <Icon>
          <ListAltIcon fontSize="small" />
        </Icon>
      ),
    },
  ];
  const generalLinks = [
    {
      name: "FAQ",
      href: "/about/faq",
      icon: (
        <Icon>
          <InfoIcon fontSize="small" />
        </Icon>
      ),
    },
    {
      name: "Security Rules",
      href: "/about/securityrules",
      icon: (
        <Icon>
          <WarningIcon fontSize="small" />
        </Icon>
      ),
    },
    {
      name: "Changelog",
      href: "/about/changelog",
      icon: (
        <Icon>
          <DescriptionIcon fontSize="small" />
        </Icon>
      ),
    },
  ];

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setState({ ...state, right: open });
  };

  // const logout = () => {
  //   authContext.logOut();
  // };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem className={classes.nameHeader}>
          <Typography variant="subtitle1">{`${user.givenname} ${user.surname}`}</Typography>
        </ListItem>
        <Divider />
        <ListItem disabled className={classes.linkTextTitle}>
          <Typography variant="subtitle1">Me</Typography>
        </ListItem>

        {userLinks.map((link: any, i: number) => {
          return (
            <NavLink
              id="home"
              exact
              activeClassName="active"
              style={{ textDecoration: "none" }}
              key={`userlink_${i}`}
              onClick={() => {
                setState({ ...state, right: false });
              }}
              className={classes.menuItem}
              to={link.href}
            >
              <ListItem className={classes.linkText}>
                <span className={classes.icons}>{link.icon}</span>
                <Typography variant="caption">{link.name}</Typography>
              </ListItem>
            </NavLink>
          );
        })}

        {
          user.issuperadmin === true ? (
            <>
              <Divider style={{ marginTop: "6px" }} />
              <ListItem disabled className={classes.linkTextTitle}>
                <Typography variant="subtitle1">Super Admin</Typography>
              </ListItem>
              {
                superAdminLinks.map((link, i) => {
                  return (
                    <NavLink
                      exact
                      activeClassName="active"
                      key={`superadminlink_${i}`}
                      onClick={() => {
                        setState({ ...state, right: false });
                      }}
                      className={classes.menuItem}
                      to={link.href}
                    >
                    <ListItem className={classes.linkText}>
                      <span className={classes.icons}>{link.icon}</span>
                      <Typography variant="caption">{link.name}</Typography>
                    </ListItem>
                  </NavLink>
                  )
                })
              }
            </>
          ) : null
        }

        <Divider style={{ marginTop: "6px" }} />
        {adminRole === "adminEditor" || adminRole === "adminReader" ? (
          <ListItem disabled className={classes.linkTextTitle}>
            <Typography variant="subtitle1">Admin</Typography>
          </ListItem>
        ) : (
            ""
          )}

        {adminRole === "adminEditor" ? (
          adminLinks.map((link: any, i: number) => {
            return (
              <div key={`adminlink${i}`}>
                <NavLink
                  id="home"
                  exact
                  activeClassName="active"
                  key={`adminlink_${i}`}
                  onClick={() => {
                    setState({ ...state, right: false });
                  }}
                  className={classes.menuItem}
                  to={link.href}
                >
                  <ListItem className={classes.linkText}>
                    <span className={classes.icons}>{link.icon}</span>
                    <Typography variant="caption">{link.name}</Typography>
                  </ListItem>
                </NavLink>
              </div>
            );
          })
        ) : adminRole === "adminReader" ? (
          <div key={`adminlink111`}>
            <NavLink
              id="home"
              exact
              activeClassName="active"
              key={`adminlink_$1112`}
              onClick={() => {
                setState({ ...state, right: false });
              }}
              className={classes.menuItem}
              to={adminLinks[0].href}
            >
              <ListItem className={classes.linkText}>
                <span className={classes.icons}>{adminLinks[0].icon}</span>
                <Typography variant="caption">{adminLinks[0].name}</Typography>
              </ListItem>
            </NavLink>
          </div>
        ) : (
              ""
            )}

        {adminRole === "adminEditor" || adminRole === "adminReader" ? (
          <Divider style={{ marginTop: "6px" }} />
        ) : (
            ""
          )}
        <ListItem disabled className={classes.linkTextTitle}>
          <Typography variant="subtitle1">General</Typography>
        </ListItem>
        {generalLinks.map((link: any, i: number) => {
          return (
            <NavLink
              id="home"
              exact
              activeClassName="active"
              key={`generallink_${i}`}
              onClick={() => {
                setState({ ...state, right: false });
              }}
              className={classes.menuItem}
              to={link.href}
            >
              <ListItem className={classes.linkText}>
                <span className={classes.icons}>{link.icon}</span>
                <Typography variant="caption">{link.name}</Typography>
              </ListItem>
            </NavLink>
          );
        })}
      </List>

      <Divider />
      {/* <div className={classes.logoutButtonWrapper}>
        <Button
          className={classes.logoutButton}
          variant="contained"
          onClick={logout}
        >
          Logout
        </Button>
      </div> */}
    </div>
  );

  return (
    <div>
      {(["right"] as Anchor[]).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <img width="30px" src={require("../assets/menu-white.svg")} />
          </Button>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};
