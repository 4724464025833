import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  updateDateQuery,
  updateLocation,
  updateTourFormat,
  updateTourStatus,
} from "../utilities/localstorage";

import {
  Paper,
  FormControl,
  MenuItem,
  Select,
  TableRow,
  TableHead,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  LinearProgress,
  TableContainer,
  InputLabel,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import axios from "axios";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { UseUser } from "../context/UserContext";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import useStyles from "../styles/views/tours";

const Tours = (props: any) => {
  const classes = useStyles();

  const [adminSites, setAdminSites] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [user, setUser] = useState({});
  const [currLocation, setCurrLocation] = useState(
    localStorage.currlocation || ""
  );
  const [locations, setLocations] = useState([]);
  const [tourStatus, setTourStatus] = useState("");
  const [tourFormat, setTourFormat] = useState(
    localStorage.tourformat || "all"
  );
  const [queryDate, setQueryDate] = useState(
    localStorage.datequery || moment().format("YYYY-MM-DD").slice(0, 7)
  );
  const [loadingMonth, setLoadingMonth] = useState(false);
  console.log(tourStatus);
  const myAdminSites: any = [];
  const getUserFromContext = async () => {
    //setIsLoading(true);

    const getUser = await UseUser();

    setUser(getUser);

    //@ts-ignore
  };
  getUserFromContext();
  useEffect(() => {
    setLoadingMonth(true);
    console.log(queryDate);
    updateDateQuery(queryDate);

    const responseArr: any = [];
    const func = async () => {
      if (Object.keys(user).length === 0) {
        return;
      }
      if (localStorage.tourstatus !== "all") {
        if (localStorage.tourstatus) {
          setTourStatus(JSON.parse(localStorage.tourstatus));
        } else {
          setTourStatus("all");
        }
      } else {
        setTourStatus("all");
      }

      //@ts-ignore
      user.admineditorlocations.forEach((location: any) => {
        myAdminSites.push({
          adminType: "editor",
          locationId: location,
        });
      });

      //@ts-ignore
      user.adminreaderlocations.forEach((location: any) => {
        //@ts-ignore
        if (user.admineditorlocations.includes(location)) {
          return;
        }
        myAdminSites.push({
          adminType: "reader",
          locationId: location,
        });
      });

      if (myAdminSites.length < 1) {
        setIsLoading(false);
        return;
      }

      myAdminSites.forEach((site: any) => {
        console.log(queryDate);
        console.log(site.locationId);

        const url = `/admin/locations/${site.locationId}?includeSlots=true&date=${queryDate}&month=true`;

        const response = axios({
          method: "GET",
          url: url,
        });

        responseArr.push(response);
      });
      axios
        .all(responseArr)
        .then(
          axios.spread((...responses: any) => {
            responses.sort(function (a: any, b: any) {
              if (a.data.name < b.data.name) {
                return -1;
              }
              if (b.data.name < a.data.name) {
                return 1;
              }
              return 0;
            });
            setLocations(responses);
            console.log(responses);

            setIsLoading(false);
            setLoadingMonth(false);
            if (!localStorage.currlocation) {
              setCurrLocation(responses[0].data.name);
            }

            setAdminSites(myAdminSites);
          })
        )
        .catch((err: any) => {
          setTableLoading(false);
          setLoadingMonth(false);
        });
    };

    func();
  }, [user, queryDate]);

  const getRowDetails = (row: any) => {
    const findLocationId = locations.find((x: any) => {
      return x.data.name === currLocation;
    });
    let id = "";

    if (findLocationId) {
      //@ts-ignore
      id = findLocationId.data.id;
    }
    props.history.push({
      pathname: `/admin/tours/tourdetails/${row.slotid}+${queryDate}`,
      //@ts-ignore
      state: { row, id, adminSites, queryDate },
    });
  };

  const CreateNewTour = (loc: any) => {
    props.history.push({ pathname: "/admin/tours/createslot" });
  };

  const checkIfProcessed = (bookings: any) => {
    const found = bookings.find((booking: any) => {
      return (
        !booking.serix ||
        !booking.attended ||
        !booking.signeddoc ||
        !booking.cds ||
        !booking.anonymize
      );
    });
    if (bookings.length === 0) {
      return <span>No bookings</span>;
    }
    if (!found) {
      return <CheckIcon className={classes.attendButton} />;
    }
    return <CloseIcon className={classes.notAttendButton} />;
  };

  function createData(
    slottime: any,
    format: any,
    nbrofbookings: any,
    slotcount: any,
    slotid: any,
    expires: any,
    bookings: any,
    locationName: any,
    createdby: any,
    lastchangedby: any,
    slotstatus: any,
    meetinglink: any,
    hasalreadybeenclosed: any,
    comment: any,
    serix: any
    // tour status
  ) {
    return {
      slottime,
      format,
      nbrofbookings,
      slotcount,
      slotid,
      expires,
      bookings,
      locationName,
      createdby,
      lastchangedby,
      slotstatus,
      meetinglink,
      hasalreadybeenclosed,
      comment,
      serix,
    };
  }

  let rows: any;

  if (locations) {
    const slotsToMap = locations.find(
      (location: any) => location.data.name === currLocation
    );

    if (slotsToMap) {
      //@ts-ignore

      slotsToMap.data.slots.sort((a: any, b: any) => {
        a = moment(a.slottime).format("YYYY-MM-DD");
        b = moment(b.slottime).format("YYYY-MM-DD");

        if (a < b) {
          return -1;
        }
        if (b < a) {
          return 1;
        }
        return 0;
      });

      rows =
        //@ts-ignore
        slotsToMap.data.slots
          .filter((slot: any) => {
            if (tourStatus === "all" && tourFormat === "all") {
              return slot.slottime;
            }
            if (tourStatus === "all" && tourFormat !== "all") {
              return slot.slotstatus !== "all" && slot.format === tourFormat;
            }
            if (tourFormat === "all" && tourStatus !== "all") {
              return slot.format !== "all" && slot.slotstatus === tourStatus;
            }

            return slot.slotstatus === tourStatus && slot.format === tourFormat;
          })

          //@ts-ignore
          .map((slot: any) => {
            //@ts-ignore
            return createData(
              slot.slottime,
              slot.format,
              slot.nbrofbookings,
              slot.slotcount,
              slot.id,
              slot.expires,
              slot.bookings,
              slot.createdby,
              slot.lastchangedby,
              slot.slotstatus,
              slot.hasalreadybeenclosed,
              slot.bookings,
              slot.serix
            );
          });
    }
  }

  const headCells = [
    "Tour date",
    "Amount participants",
    "Max participants",
    "Tour format",
    "Tour status",
    "Processed",
  ];

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateLocation(event.target.value);
    //@ts-ignore
    setCurrLocation(event.target.value as any);
  };
  const handleChangeTourStatus = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    console.log(event.target.value);
    if (event.target.value === "all") {
      updateTourStatus("all");
    } else if (event.target.value === false) {
      updateTourStatus(JSON.stringify(false));
    } else {
      updateTourStatus(JSON.stringify(true));
    }
    //@ts-ignore
    setTourStatus(event.target.value as any);
  };
  const handleChangeTourFormat = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    updateTourFormat(event.target.value);
    //@ts-ignore
    setTourFormat(event.target.value as any);
  };
  const changeDate = (date: any) => {
    let dateToSet: any = moment(date).format("YYYY-MM-DD");
    dateToSet = dateToSet.slice(0, 7);
    setQueryDate(dateToSet);
  };

  useEffect(() => {
    props.updateHeaderTitle("Tour list");
  }, []);

  if (loading) {
    return (
      <div className={classes.loadingLinear}>
        <Typography variant="h6" component="h2">
          Loading...
        </Typography>
        <LinearProgress />
      </div>
    );
  } else if (adminSites.length < 1) {
    return (
      <>
        <Typography variant="h4">
          You do not have the correct admin rights to show this page.
        </Typography>
        <Typography variant="h6">
          <Link to="/" style={{ color: "#0077c1" }}>
            Go back to main page
          </Link>
        </Typography>
      </>
    );
  } else {
    return (
      <div>
        {/*  <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h4" component="h2">
                  Tours
                </Typography>
              </Paper>
            </Grid>
          </Grid> */}
        <div className={classes.tableHeaderWrapper}>
          <div className={classes.refreshAndLocationWrapper}>
            {/* {loading ? (
              <div className={classes.circularProgress}>
                <CircularProgress />
              </div>
            ) : (
              <IconButton
                onClick={() => setIsLoading(true)}
                aria-label="refresh"
                className={classes.refreshButton}
              >
                <RefreshIcon />
              </IconButton>
            )} */}
            <FormControl className={classes.formControl}>
              <InputLabel
                className={classes.inputLabel}
                id="demo-simple-select-label"
              >
                Location
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                labelId="locationSelect"
                id="locationSelect"
                className={classes.underlineSelect}
                //@ts-ignore
                value={currLocation}
                onChange={handleChange}
              >
                {locations.map((location: any) => {
                  return (
                    <MenuItem key={location.data.id} value={location.data.name}>
                      {location.data.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel
                className={classes.inputLabel}
                id="demo-simple-select-label"
              >
                Tour format
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                labelId="locationSelect"
                id="locationSelect"
                className={classes.underlineSelect}
                //@ts-ignore
                value={tourFormat}
                onChange={handleChangeTourFormat}
              >
                <MenuItem key="tourFormatAll" value="all">
                  All
                </MenuItem>
                <MenuItem key="tourFormatOnline" value="Online">
                  Online
                </MenuItem>
                <MenuItem key="tourFormatOffline" value="Offline">
                  On site
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel
                className={classes.inputLabel}
                id="demo-simple-select-label"
              >
                Tour status
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                labelId="locationSelect"
                id="locationSelect"
                className={classes.underlineSelect}
                //@ts-ignore
                value={tourStatus}
                onChange={handleChangeTourStatus}
              >
                <MenuItem key={"AllStatus"} value="all">
                  All
                </MenuItem>
                <MenuItem key={"OpenStatus"} value={false as any}>
                  Open
                </MenuItem>
                <MenuItem key="closedStatus" value={true as any}>
                  Closed
                </MenuItem>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <form noValidate>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    className={classes.textFieldDate}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.label,
                        focused: classes.focusedLabel,
                        error: classes.erroredLabel,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.underline,
                      },
                    }}
                    variant="inline"
                    disableToolbar={false}
                    format="yyyy-MM"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date"
                    value={queryDate}
                    onChange={changeDate}
                    autoOk={true}
                    allowKeyboardControl={true}
                    disablePast={false}
                    views={["month"]}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </form>
            </MuiPickersUtilsProvider>
          </div>
          {loadingMonth ? (
            <CircularProgress
              style={{ position: "relative", top: "-14px" }}
              size={18}
            />
          ) : (
            <div></div>
          )}
          <Button
            variant="contained"
            className={classes.newBookingButton}
            onClick={CreateNewTour}
          >
            new tour
          </Button>
        </div>

        <TableContainer
          elevation={1}
          className={classes.tableContainer}
          component={Paper}
        >
          <Table
            stickyHeader
            className={classes.table}
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {headCells.map((head, index) => (
                  <TableCell
                    className={classes.tableHeader}
                    key={index}
                    align="left"
                  >
                    <b>{head}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableLoading ? (
                <TableRow key={"id2"}>
                  <TableCell align="left">
                    <div>
                      <Typography variant="h6" component="h2">
                        Loading...
                      </Typography>
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              ) : rows.length > 0 ? (
                rows.map((row: any) => (
                  <Tooltip
                    key={"tooltip" + row.slotid}
                    title="Click for details"
                    placement="left"
                    arrow
                  >
                    <TableRow
                      style={{ cursor: "pointer" }}
                      onClick={() => getRowDetails(row)}
                      hover
                      key={row.slotid}
                    >
                      <TableCell align="left">
                        {moment(row.slottime).format("YYYY-MM-DD - HH:mm")}
                      </TableCell>
                      <TableCell align="left">{row.nbrofbookings}</TableCell>
                      <TableCell align="left">{row.slotcount}</TableCell>
                      <TableCell align="left">
                        {row.format === "Offline" ? "On site" : "Online"}
                      </TableCell>

                      <TableCell align="left">
                        {!row.slotstatus ? "Open" : "Closed"}
                      </TableCell>
                      <TableCell align="left">
                        {checkIfProcessed(row.bookings)}
                      </TableCell>

                      {/* <TableCell align="left">
                 <Link to={{pathname: `/admin/tours/tourdetails/${row.slotid}`, state: {row}}}>
                    <IconButton aria-label="details" className={classes.iconButton}>
                    <Visibility />
                      
                    </IconButton>
                  </TableCell> */}
                    </TableRow>
                  </Tooltip>
                ))
              ) : (
                <TableRow key={"id2"}>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">No tours to be displayed.</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
};

export default Tours;
