const trimOverflowingCurlyBraces = (html) => {
  const leftCurlyBraceRegex = /\{{3,}/g;  
  const rightCurlyBraceRegex = /\}{3,}/g;

  html = html.replaceAll(leftCurlyBraceRegex, "{{");
  html = html.replaceAll(rightCurlyBraceRegex, "}}");

  return html;
}

const replaceMeetingLinkBeforeSendingTemplate = (bodyHtml) => {
  return bodyHtml.replaceAll("{{meetinglink}}", "{{{meetinglink}}}");
};

export const replaceMeetingLinkBeforeRendering = (bodyHtml) => {
  return bodyHtml.replaceAll("{{{meetinglink}}}", "{{meetinglink}}");
};

export const validateTemplate = (templateHtml, locationName, meetingLink) => {
  const errors = [];
  if(templateHtml.includes("{{location}}") && locationName === "") errors.push("Fill out a location name above the editor or remove {{location}} from the template.");
  if(templateHtml.includes("{{meetinglink}}") && meetingLink === "") errors.push("Fill out a meeting link above the editor or remove {{meetinglink}} from the template.");

  return errors;
}

export const insertBodyIntoBaseTemplate = (meetingLink, bodyHtml) => {
  const trimmedHtml = trimKeywordsFromHtml(bodyHtml);
  const withoutCurlyBraces = trimOverflowingCurlyBraces(trimmedHtml)
  const replacedBodyHtml = replaceMeetingLinkBeforeSendingTemplate(withoutCurlyBraces);

  return `
    <html>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <meta name="viewport" content="width=device-width">
        <title>Ingka Klara Notification</title>
        <style>
          @font-face {
            font-family: 'Noto IKEA';
            font-style: normal;
            font-weight: 400;
            font-display: fallback;
            src: local('Noto IKEA'), url("./fonts/NotoSansIkea-Regular.woff") format("woff");
          }
      
          body {
            font-family: 'Noto IKEA', Verdana;
            font-size: 15px;
            -webkit-font-smoothing: antialiased;
          }
        </style>
      </head>
    
      <body style="line-height: 1.4; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; font-size: 15px; -webkit-font-smoothing: antialiased; margin: 0px;" bgcolor="#f6f6f6">
        ${replacedBodyHtml}
      </body>
    </html>
  `;
};

export const trimKeywordsFromHtml = (html) => {
  const keywordRegex = /\{.*?\{.*?\}.*?\}/g;
  const htmlTagRegex = /<(.*?)>/g;

  const matches = html.match(keywordRegex);
  const trimmedMatches = matches.map((match) => {
    return match.replaceAll(htmlTagRegex, "");
  })

  for(const [i, trimmedMatch] of trimmedMatches.entries()) {
    html = html.replace(matches[i], trimmedMatch);
  }

  return html;
};