import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import useStyles from '../../styles/components/checkuseremail'
import { checkValidEmail } from '../../utilities/validation';
import { CircularProgress } from "@material-ui/core";

const CheckUserEmail = (props: any) => {
  const classes = useStyles();
  const [inputEmail, setInputEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState("");
  const [loadingWhenRwquest, setLoadingWhenRequest] = useState(false)

  useEffect(() => {
    props.updateInfoText(`To assign a participant for the safety and security introduction, please follow the steps below`)
  }, [])

  const checkUser = async () => {
    if (!inputEmail) {
      return setErrorMsg("Required");
    }

    const checkEmail = checkValidEmail(inputEmail);

    if (!checkEmail) {
      setErrorMsg("E-mail must be valid");
      return;
    }
    setLoadingWhenRequest(true)

    try {

      const userResult = await axios(`/user/userdata/${inputEmail}`);
      if (userResult && userResult.status === 200) {
        
        //@ts-ignore
        //userResult.data.cds = true

        props.updateInfoText('The participant email is registered in the IKEA system and the fields below shoud be automatically populated. Click next to continue.')
        setLoadingWhenRequest(false)
        props.nextStep(userResult.data, true);
        props.showWelcomeMessageFn(false)
        
      }
    } catch (err) {
      if (err.message.includes("404")) {
        //props.nextStep({ username: inputEmail }, false);
        //props.updateInfoText('You are about to assign a participant that is not registered in the IKEA system. Please fill in the empty fields below and hit the next button.')
        //props.showWelcomeMessageFn(false)
        setLoadingWhenRequest(false)
        return setErrorMsg("Only valid IKEA email addresses are allowed");
      } else {
        setErrorMsg("Something went wrong, please try again");
        props.showWelcomeMessageFn(false)
      }
      setLoadingWhenRequest(false)
    }

    // fetch successfull but user not found
  };

  return (
    <>
      <form
        className={classes.root}
        onSubmit={(e) => {
          e.preventDefault();
          checkUser();
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
          InputProps={{
            classes: {
              root: classes.underline,
              /* error: classes.error */
            },
          }}
          onChange={(e: any) => {
            setErrorMsg("");
            setInputEmail(e.target.value);
          }}
          value={inputEmail}
          id="standard-basic"
          label="Insert participant email"
          type="email"
          error={errorMsg ? true : false}
          helperText={errorMsg}
        />
      </form>
      <div style={{ marginTop: '40px' }}>
        <Paper variant="outlined" style={{ padding: '30px', border: '2px solid #0077c180' }}>
          <Typography style={{ color: "#5c5c5c" }} variant="subtitle2">
            PLEASE NOTE* Before the safety and security introdcution you need
              to add the following to{" "}
            <a
              style={{ color: "#0077c1" }}
              href="https://ikea.serix.seriline.com/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              Serix (seriline.com)
              </a>
            < br />
            <ul>
              <li>A photo of your co-worker from shoulders and up. The photo should be taken with a light background making your face very visible.  </li>
              <li>A four-digit pin code that will be linked to your co-worker's card.</li>
            </ul>
            The tour is only held <b>online</b>.
          </Typography>
        </Paper>
      </div>
      <div style={{ marginTop: '40px', display: 'flex', alignItems: 'center' }}>
        <Button onClick={() => props.showWelcomeMessageFn(true)} disabled className={classes.buttons} variant="contained">
          Back
      </Button>
        <Button
          className={classes.buttons}
          onClick={checkUser}
          variant="contained"
          disabled={loadingWhenRwquest ? true : false}
        >
          Next
      </Button>
        {loadingWhenRwquest ? <CircularProgress size={24} /> : <div style={{ width: '24px' }}></div>}
      </div>
      {}
    </>
  );
};

export default CheckUserEmail;
