import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Snackbar,
  Button,
  Tooltip,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Select,
  MenuItem,
  LinearProgress,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';

import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import { checkValidEmail } from 'utilities/validation';
import {
  insertBodyIntoBaseTemplate,
  replaceMeetingLinkBeforeRendering,
  validateTemplate,
} from '../services/email';
import { UserFetch, UseUser } from '../context/UserContext';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainSection: {
    margin: '1rem 0',
    display: 'flex',
    flexDirection: 'column',
  },
  headerSection: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    '&.MuiTypography-h5': {
      marginBottom: '0.25rem',
      marginTop: '1rem',
    },
    '&.MuiTypography-h6': {
      marginRight: '1rem',
    },
  },
  creationButton: {
    margin: '1rem 0',
    backgroundColor: '#0077c1',
    color: 'white',
    '&:hover': {
      backgroundColor: '#0089de',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  lastBody: {
    marginBottom: '1.5rem',
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
    borderTop: '1px solid #efefef',
  },
  column: {
    margin: '0 1rem',
    flexBasis: '33.33%',
  },
  helper: {
    flexBasis: '33.33%',
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  loadingLinear: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));


const SettingsV2 = ({ updateHeaderTitle }) => {
  const classes = useStyles();

  const [user, setUser] = useState({});

  const [locationSettings, setLocationSettings] = useState([]);
  const [currentLocationSettings, setCurrentLocationSettings] = useState(
    {}
  );

  const [onlineEditorContent, setOnlineEditorContent] =
    useState(undefined);

  const [currentAdminEditor, setCurrentAdminEditor] = useState('');
  const [currentAdminReader, setCurrentAdminReader] = useState('');
  const [testEmail, setTestEmail] = useState('');

  const [testEmailErrors, setTestEmailErrors] = useState([]);

  const [loadingTestEmail, setLoadingTestEmail] = useState(false);
  const [loadingLocationUpdate, setLoadingLocationUpdate] = useState(false);
  const [toastSuccessMessage, setToastSuccessMessage] = useState('');
  const [toastFailureMessage, setToastFailureMessage] = useState('');

  const fetchUserData = useContext(UserFetch);

  const [errors, setErrors] = useState({
    location: '',
    editor: '',
    reader: '',
    meetingLink: '',
    onlineEditorContent: '',
  });

  const getUser = async () => {
    const userdata = await UseUser();
    setUser(userdata);
  };

  getUser();

  useEffect(() => {
    updateHeaderTitle('Settings');
  }, []);

  useEffect(() => {
    (async () => {
      if (Object.keys(user).length === 0) return;
  
      if (user.admineditorlocations.length === 0) return;

      const _locationSettings = await Promise.all(
    
        user.admineditorlocations.map(async (locationId) => {
          const { data } = await axios.get(`/admin/locations/${locationId}`);
          return data;
        })
      );
      setLocationSettings(_locationSettings);
      setCurrentLocationSettings(_locationSettings[0]);
      await getEmailTemplate(_locationSettings[0].id);
    })();
  }, [user]);

  useEffect(() => {
    let errorMessage = '';
    for (const key in errors) {
  
      if (errors[key]) errorMessage += `${errors[key]}\n`;
    }

    if (Boolean(errorMessage)) setToastFailureMessage(errorMessage);
  }, [errors]);

  const getEmailTemplate = async (locationId) => {

    const { data } = await axios.get(
      `/admin/email/template?locationId=${locationId}&template=online`
    );

    const replacedTemplate = replaceMeetingLinkBeforeRendering(data.template);
    const contentBlock = htmlToDraft(replacedTemplate);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setOnlineEditorContent(EditorState.createWithContent(contentState));
    }
  };

  const selectLocation = async (e) => {
    setOnlineEditorContent(undefined);
    const currentLocationSettings = locationSettings.find(
  
      (location) => e.target.value === location.name
    );
    setCurrentLocationSettings(currentLocationSettings);
    await getEmailTemplate(currentLocationSettings.id);
  };

  const onEditorContentChange = (editorState) => {
    //debounce?
    setOnlineEditorContent(editorState);
  };

  const addAdminEditor = () => {
    if (!checkValidEmail(currentAdminEditor))
      return setErrors({
        ...errors,
        editor: 'Invalid email. Enter a valid Ingka email.',
      });
    if (currentLocationSettings.admineditors.includes(currentAdminEditor))
      return setErrors({ ...errors, editor: 'Email already added.' });

    setErrors({ ...errors, editor: '' });

    setCurrentLocationSettings({
      ...currentLocationSettings,
      admineditors: [
        ...currentLocationSettings.admineditors,
        currentAdminEditor,
      ],
    });
    setCurrentAdminEditor('');
  };

  const addAdminReader = () => {
    if (!checkValidEmail(currentAdminReader))
      return setErrors({
        ...errors,
        reader: 'Invalid email. Enter a valid Ingka email.',
      });
    if (currentLocationSettings.adminreaders.includes(currentAdminReader))
      return setErrors({ ...errors, reader: 'Email already added.' });

    setErrors({ ...errors, reader: '' });

    setCurrentLocationSettings({
      ...currentLocationSettings,
      adminreaders: [
        ...currentLocationSettings.adminreaders,
        currentAdminReader,
      ],
    });
    setCurrentAdminReader('');
  };

  const removeAdminEditor = (email) => {
    if (currentLocationSettings.admineditors.length === 1)
      return setErrors({
        ...errors,
        editor: 'There must be at least one admin editor per location.',
      });
    const filteredEditors = currentLocationSettings.admineditors.filter(
  
      (admin) => admin !== email
    );

    setCurrentLocationSettings({
      ...currentLocationSettings,
      admineditors: filteredEditors,
    });
  };

  const removeAdminReader = (email) => {
    const filteredReaders = currentLocationSettings.adminreaders.filter(
  
      (admin) => admin !== email
    );
    setCurrentLocationSettings({
      ...currentLocationSettings,
      adminreaders: filteredReaders,
    });
  };

  const sendTestEmail = async () => {
    try {
      setLoadingTestEmail(true);
      const bodyHtml = draftToHtml(
        convertToRaw(onlineEditorContent.getCurrentContent())
      );
      const newTemplateHtml = insertBodyIntoBaseTemplate(
        currentLocationSettings.defaultmeetinglink,
        bodyHtml
      );

      const templateValidationErrors = validateTemplate(
        bodyHtml,
        currentLocationSettings.name,

        currentLocationSettings.defaultmeetinglink
      );
      if (!testEmail) {
        templateValidationErrors.push(
          'Enter an email to receive the preview email.'
        );
      } else if (!checkValidEmail(testEmail)) {
        templateValidationErrors.push(
          'Invalid email. Enter a valid Ingka email.'
        );
      }
      if (templateValidationErrors.length) {
        setLoadingTestEmail(false);

        setTestEmailErrors(templateValidationErrors);
        return;
      } else {
        setTestEmailErrors([]);
      }

      const templateData = {
        testEmail: testEmail,
        meetingLink: currentLocationSettings.defaultmeetinglink,
        locationName: currentLocationSettings.name,
        templateHtml: newTemplateHtml,
      };

      await axios.post('/admin/email/preview', templateData);
      setToastSuccessMessage(`Preview sent to ${testEmail}.`);
    } catch (error) {
      if (error.response.status === 422) {
        setToastFailureMessage(
          'Invalid template, make sure your keywords are valid. e.g. {{date}}'
        );
      } else {
        setToastFailureMessage('Something unexpected happened...');
      }
    }
    setLoadingTestEmail(false);
  };

  const enterKeyAddAdminEditor = (e) => {
    if (e.keyCode === 13) addAdminEditor();
  };

  const enterKeyAddAdminReader = (e) => {
    if (e.keyCode === 13) addAdminReader();
  };

  const onChangeAdminEditors = (e) => {
    setCurrentAdminEditor(e.target.value);
  };

  const onChangeAdminReaders = (e) => {
    setCurrentAdminReader(e.target.value);
  };

  const onMeetingLinkChange = (e) => {
    setCurrentLocationSettings({
      ...currentLocationSettings,
      defaultmeetinglink: e.target.value,
    });
  };

  const validateInputForUpdate = () => {
    let passed = true;
    let _errors = { ...errors };

    if (!Boolean(currentLocationSettings.name)) {
      passed = false;
      _errors = { ..._errors, location: 'Missing location name.' };
    } else {
      _errors = { ..._errors, location: '' };
    }

    if (currentLocationSettings.admineditors.length < 1) {
      passed = false;
      _errors = {
        ..._errors,
        editor: 'There must be at least one admin editor per location.',
      };
    } else {
      _errors = { ..._errors, editor: '' };
    }

    // if (Boolean(currentLocationSettings.defaultmeetinglink) && checkValidEmail(currentLocationSettings.defaultmeetinglink)) {
    //   _errors = { ..._errors, meetingLink: '' };
    // } else {
    //   passed = false;
    //   _errors = { ..._errors, meetingLink: 'Enter a valid URL.' };
    // }

    const bodyHtml = draftToHtml(
      convertToRaw(onlineEditorContent.getCurrentContent())
    );

    //Can't do string comparison because an empty template will look like "<p></p>" with some hidden unicode...
    if (bodyHtml.length === 8) {
      passed = false;
      _errors = {
        ..._errors,
        onlineEditorContent: 'Email template can not be empty.',
      };
    } else {
      _errors = { ..._errors, onlineEditorContent: '' };
    }

    setErrors(_errors);

    return passed;
  };

  const updateLocation = async () => {
    if (!validateInputForUpdate()) return;
    setLoadingLocationUpdate(true);

    const bodyHtml = draftToHtml(
      convertToRaw(onlineEditorContent.getCurrentContent())
    );
    const newTemplateHtml = insertBodyIntoBaseTemplate(currentLocationSettings.defaultmeetinglink, bodyHtml);

    const body = {
      id: currentLocationSettings.id,
      name: currentLocationSettings.name,
      admineditors: currentLocationSettings.admineditors,
      adminreaders: currentLocationSettings.adminreaders,
      defaultmeetinglink: currentLocationSettings.defaultmeetinglink,
      country: user.country,
      template: newTemplateHtml,
    };

    const response = await axios.put('/admin/locations', body);
    await fetchUserData();

    setLoadingLocationUpdate(false);
    setToastSuccessMessage(
      `Settings for ${currentLocationSettings.name} has been updated.`
    );
  };

  if (Object.keys(currentLocationSettings).length < 1) {
    return (
      <div className={classes.loadingLinear}>
        <Typography variant="h6" component="h2">
          Loading locations...
        </Typography>
        <LinearProgress />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className={classes.header} variant="h5">
              Location name
            </Typography>
            <Typography className={classes.lastBody} variant="body2">
              Select the location you want to edit.
            </Typography>
          </div>
        </div>
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          labelId="locationSettingsSelect"
          id="locationSettingsSelect"
      
          value={currentLocationSettings.name}
          onChange={selectLocation}
        >
          {locationSettings.map((location) => {
            return (
              <MenuItem key={location.id} value={location.name}>
                {location.name}
              </MenuItem>
            );
          })}
        </Select>
      </div>

      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className={classes.header} variant="h5">
              Permissions
            </Typography>
            <Typography variant="body2">
              Assign admin edtiors & admin readers to this location.
            </Typography>
            <Typography className={classes.lastBody} variant="body2">
              Add one or more co-worker(s) by entering their email(s) and adding
              them individually.
            </Typography>
          </div>
        </div>
        <div className={classes.headerSection}>
          <Typography className={classes.header} variant="h6">
            Admin Editors
          </Typography>
          <Tooltip
            title='An "Admin Editor" is allowed do do everyting in the system. An admin editor is allowed set and change permissions, create and edit the tours and change all the location settings.'
            placement="top-start"
          >
            <HelpIcon style={{ color: '#828282' }} />
          </Tooltip>
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: '1rem' }}>
          <TextField
            value={currentAdminEditor}
            style={{ width: 'inherit' }}
            id="admin-editor-email"
            label="Co-worker email"
            placeholder="Enter co-worker email"
            onKeyDown={enterKeyAddAdminEditor}
            onChange={onChangeAdminEditors}
            error={Boolean(errors.editor)}
            helperText={Boolean(errors.editor) && errors.editor}
            variant="filled"
            required
          />
          <Button
            onClick={addAdminEditor}
            className={classes.creationButton}
            style={{ width: '100px', margin: '0 0 0 1rem', height: '56px' }}
          >
            Add
          </Button>
        </div>
        <List style={{ marginBottom: '1rem', width: '30%' }}>
          {currentLocationSettings.admineditors.map((email) => {
            return (
              <ListItem
                key={email}
                divider={true}
              >
                <ListItemText primary={email} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="start"
                    aria-label="delete"
                    onClick={() => removeAdminEditor(email)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <div className={classes.headerSection}>
          <Typography className={classes.header} variant="h6">
            Admin Readers
          </Typography>
          <Tooltip
            title='An "Admin Reader" is only allowed do see the tours that has been created. An admin reader is not allowed to change permissions, tours or location settings.'
            placement="top-start"
          >
            <HelpIcon style={{ color: '#828282' }} />
          </Tooltip>
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: '1rem' }}>
          <TextField
            value={currentAdminReader}
            style={{ width: 'inherit' }}
            id="admin-reader-email"
            label="Co-worker email"
            error={Boolean(errors.reader)}
            placeholder="Enter co-worker email"
            onKeyDown={enterKeyAddAdminReader}
            onChange={onChangeAdminReaders}
            helperText={Boolean(errors.reader) && errors.reader}
            variant="filled"
          />
          <Button
            onClick={addAdminReader}
            className={classes.creationButton}
            style={{ width: '100px', margin: '0 0 0 1rem', height: '56px' }}
          >
            Add
          </Button>
        </div>
        <List style={{ width: '30%' }}>
          {currentLocationSettings.adminreaders.map((email) => {
            return (
              <ListItem key={email} divider={true}>
                <ListItemText primary={email} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeAdminReader(email)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>

      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className={classes.header} variant="h5">
              Default meeting link
            </Typography>
            <Typography variant="body2">
              Enter a meeting link for all online tours to use per default.
            </Typography>
            <Typography className={classes.lastBody} variant="body2">
              This is required for tours held online.
            </Typography>
          </div>
        </div>
        <TextField
          onChange={onMeetingLinkChange}
          value={currentLocationSettings.defaultmeetinglink}
          multiline
          id="meeting-link"
          label="Meeting link"
          placeholder="Enter your meeting link"
          error={Boolean(errors.meetingLink)}
          helperText={
            Boolean(errors.meetingLink) && <span>{errors.meetingLink}</span>
          }
          variant="filled"
        />
      </div>

      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className={classes.header} variant="h5">
              Email
            </Typography>
            <Typography variant="body2">
              This editor contains a template that resembles the email that are
              sent out to co-workers after they have signed up for a tour.
              Adjust it to your liking and preview it by entering your email
              below the editor and sending it, the email will be based on what
              is currently displayed.
            </Typography>
            <Typography
              style={{ marginTop: '1rem' }}
              className={classes.header}
              variant="h6"
            >
              Dynamic keywords
            </Typography>
            <Typography variant="body2">
              By using the following keywords in the template below, you can add
              information to the template that may differ from email to email,
              such as when a tour is being held.
            </Typography>
            <Typography className={classes.lastBody} variant="body2">
              These are optional but can be used to display useful information
              for the participant.
            </Typography>
            <Accordion defaultExpanded style={{ marginBottom: '1rem' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div className={classes.column}>
                  <Typography className={classes.heading}>Keywords</Typography>
                </div>
                <div className={classes.column}>
                  <Typography variant="body2">Description</Typography>
                </div>
                <div className={classes.column}>
                  <Typography variant="body2">Example</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.column}>
                  {'{{location}}'}
                </Typography>
                <Typography className={classes.column} variant="body2">
                  Where the tour is being held.
                </Typography>
                <Typography className={classes.column} variant="body2">
                  Hubhult Meetingplace
                </Typography>
                <div style={{ width: '48px', height: '48px' }} />
              </AccordionDetails>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.column}>{'{{date}}'}</Typography>
                <Typography className={classes.column} variant="body2">
                  When the tour is being held.
                </Typography>
                <Typography className={classes.column} variant="body2">
                  2022-08-18 - 10:00
                </Typography>
                <div style={{ width: '48px', height: '48px' }} />
              </AccordionDetails>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.column}>
                  {'{{meetinglink}}'}
                </Typography>
                <Typography className={classes.column} variant="body2">
                  Hyperlink taking the co-worker to where the meeting will take
                  place, e.g. teams link.
                </Typography>
                <Typography className={classes.column} variant="body2">
                  <a href="https://example.com">
                    Click here to join the meeting
                  </a>
                </Typography>
                <div style={{ width: '48px', height: '48px' }} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>

      {Boolean(onlineEditorContent) ? (
        <Editor
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'embedded',
              'emoji',
              'image',
              'remove',
              'history',
            ],
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 24, 30, 36, 48, 60, 72,
                96,
              ],
            },
          }}
          editorState={onlineEditorContent}
          toolbarStyle={{ border: 0 }}
          wrapperStyle={{
            backgroundColor: '#efefef',
            border: '1px solid #efefef',
          }}
          editorStyle={{ padding: '1rem' }}
          onEditorStateChange={onEditorContentChange}
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ marginRight: '1rem' }} variant="body2">
            Loading email template...
          </Typography>
          <CircularProgress size={24} />
        </div>
      )}
      <div style={{ margin: '1rem 0', display: 'flex', width: '100%' }}>
        <TextField
          style={{ width: 'inherit', marginRight: '1rem' }}
          onChange={(e) => setTestEmail(e.target.value)}
          id="test-email"
          label="Email"
          placeholder="Enter email to receive test email"
          error={testEmailErrors.length > 0}
          helperText={
            testEmailErrors &&
            testEmailErrors.length > 0 && (
              <>
                {testEmailErrors.map((error, i) => {
                  return (
                    <span key={i}>
                      {error}
                      <br />
                    </span>
                  );
                })}
              </>
            )
          }
        />
        <div style={{ display: 'flex', width: '210px', alignItems: 'center' }}>
          <Button
            style={{ width: '210px', height: '37px', marginRight: '1rem' }}
            className={classes.creationButton}
            onClick={sendTestEmail}
            variant="contained"
            disabled={loadingTestEmail ? true : false}
          >
            Send test email
          </Button>
          <div style={{ width: '24px', height: '24px' }}>
            {loadingTestEmail ? <CircularProgress size={24} /> : null}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', width: '240px', alignItems: 'center' }}>
        <Button
          style={{ width: '180px', marginRight: "1rem" }}
          className={classes.creationButton}
          onClick={updateLocation}
          variant="contained"
          disabled={loadingLocationUpdate ? true : false}
        >
          Update location
        </Button>
        <div style={{ width: '24px', height: '24px' }}>
          {loadingLocationUpdate ? <CircularProgress size={24} /> : null}
        </div>
      </div>
      <Snackbar
        open={Boolean(toastSuccessMessage)}
        autoHideDuration={6000}
        onClose={() => setToastSuccessMessage('')}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setToastSuccessMessage('')}
          severity="success"
        >
          {toastSuccessMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={Boolean(toastFailureMessage)}
        autoHideDuration={6000}
        onClose={() => setToastFailureMessage('')}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setToastFailureMessage('')}
          severity="error"
        >
          {toastFailureMessage.split('\n').map((message, i) => {
            return <div key={i}>{message}</div>;
          })}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default SettingsV2;

