import axios from "axios";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { init } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Authentication from './components/auth/Authentication'
import { acquireAccessToken } from './services/msal';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import "./styles/imports.scss";

if(process.env.REACT_APP_ENVIRONMENT === 'production') {
  console.log = function() {}
};

const returnEnvironment = () => {
  switch(process.env.REACT_APP_ENVIRONMENT) {
    case "production": {
      return "production";
    }
    case "development": {
      return "development";
    }
    default: {
      break;
    }
  }
};

if (returnEnvironment() === 'production') {
  init({
    dsn: "https://27130ced64a54094bf975e6d7d7bd6e4@o449781.ingest.sentry.io/5534841",
    beforeSend(event) {
      return event;
    },
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 0, //should be set to 0
  });
}


axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(async (config) => {
  const accessToken = await acquireAccessToken();
  config.headers.authorization = "Bearer " + accessToken;
  return config;
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0077c1",
    },
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Authentication>
      <App />
    </Authentication>
  </MuiThemeProvider>,
  document.getElementById('root')
); 