import React, { useState, useEffect } from "react";
import axios from "axios";

import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import Radio from "@material-ui/core/Radio";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import moment from "moment";
import useStyles from "../../styles/components/timeandlocation";
import { CircularProgress, RadioGroup, Typography } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const TimeAndLocation = (props: any) => {
  const classes = useStyles();
  const [currLocation, setCurrLocation] = useState("" as any);
  const [slot, setSlot] = useState("" as any);
  const [locations, setLocations] = useState([] as any);
  const [locErrorMsg, setLocErrorMsg] = useState("");
  const [slotErrorMsg, setSlotErrorMsg] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [currentSlots, setCurrentSlots] = useState([]);
  const [selectedValue, setSelectedValue] = useState("a");

  useEffect(() => {
    if (!date || !currLocation) return;

    const startOfDay = moment(date).startOf("day");
    const endOfDay = moment(date).endOf("day");

    const filteredSlots = currLocation.slots.filter((slot: any) => {
      if (slot.slotstatus) {
        return null;
      }
      const startPeriod = startOfDay.isSame(moment(), 'day') ? moment() : startOfDay;
      return (
        moment(slot.slottime) >= startPeriod && moment(slot.slottime) <= endOfDay
      );
    });

    setCurrentSlots(filteredSlots);
  }, [date, currLocation]);

  useEffect(() => {
    props.updateInfoText(
      "Choose the location where the participant will be taking the tour. Also pick a valid time slot. When done, hit next."
    );
    const setupLocations = async () => {
      const locations = (await axios("/user/locations")).data;

      setLocations(locations);
      setCurrLocation(locations[0]);
    };
    setupLocations();
  }, []);

  const changeDate = (date: any) => {
    const dateToSet: any = moment(date);
    setSlot("");
    setSelectedValue("");
    setDate(dateToSet);
  };

  const handleLocation = (locationid: any) => {
    const location = locations.find((loc: any) => {
      return loc.id === locationid;
    });

    setSelectedValue("a");
    setCurrLocation(location);
    setSlot("");
  };

  const handleSlot = (e: any, slot: any) => {
    setSelectedValue(slot.id);
    setSlot(slot);
  };

  const disableNoTourDays = (theDate: any) => {
    return !currLocation.slots.find((slot: any) => {
      if (slot.slotstatus || moment(slot.slottime) < moment()) {
        return false;
      };

      return (
        theDate.format("YYYY-MM-DD") ===
        moment(slot.slottime).format("YYYY-MM-DD")
      );
    });
  };

  const handleNext = () => {

    /* if(moment().add(1, 'hour').unix() >= moment(slot.slottime).unix()) {

      setSlotErrorMsg("The tour starts within an hour. Pick another tour time.");
      return
    } */

    if (!currLocation.id) {
      setLocErrorMsg("Required");
    }
    if (!slot) {
      setSlotErrorMsg("Required");
    }
    if (!currLocation.id || !slot) {
      return;
    }
    props.handleInput({ location: currLocation, slot: slot });
    props.nextStep();
  };

  if (currLocation) {
    return (
      <div>
        <div
          style={{
            display: "flex",
            marginTop: "50px",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.inputLabel} id="location-select">
                Select location
              </InputLabel>
              <Select
                className={classes.underlineSelect}
                MenuProps={{
                  disableScrollLock: true,
                }}
                labelId="location-select"
                value={currLocation.id}
                onChange={(e) => handleLocation(e.target.value)}
              >
                {locations[0] &&
                  locations.map((loc: any) => {
                    return (
                      <MenuItem key={loc.id} value={loc.id}>
                        {loc.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              {locErrorMsg ? <p style={{ color: "red" }}>{locErrorMsg}</p> : ""}
            </FormControl>
            <Typography
              style={{ marginRight: "44px", color: "#5c5c5c" }}
              variant="subtitle1"
            >
              Tour date and time
            </Typography>
          </div>

          <div className={classes.calendarTourWrapper}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.underline,
                  },
                }}
                autoOk
                disableToolbar
                disablePast
                variant="static"
                label="Date"
                format="YYYY-MM-DD"
                initialFocusedDate={moment()}
                shouldDisableDate={(date) => disableNoTourDays(date) as any}
                value={date}
                // InputAdornmentProps={{ position: "start" }}
                onChange={changeDate}
              />
            </MuiPickersUtilsProvider>
            {/*  <FormControl
            style={{ marginBottom: "2rem" }}
            className={classes.formControl}
          >
            <InputLabel className={classes.inputLabel} id="time-slot">
              Select time slot
          </InputLabel>
            <Select
              className={classes.underlineSelect}
              MenuProps={{
                disableScrollLock: true,
              }}
              // disabled={!currentSlots.length}
              labelId="time-slot"
              value={slot}
              onChange={(e) => handleSlot(e.target.value)}
            >
              {currentSlots.length ?
                currentSlots.map((slot: any) => {
                  const bookable =

                    slot.nbrofbookings < slot.slotcount &&
                    !slot.slotstatus &&
                    !moment(slot.slottime).isBefore(
                      moment().subtract("0", "day")
                    );
                  if (!bookable) {
                    return "";
                  }
                  return (
                    <MenuItem key={slot.id} value={slot}>

                      {moment(slot.slottime).format('HH:mm')}
                    </MenuItem>
                  );
                }) : (
                  <MenuItem value={"No tours this date"}>
                    No tours this date
                  </MenuItem>
                )}
            </Select>
            {slotErrorMsg ? <p style={{ color: "red" }}>{slotErrorMsg}</p> : ""}
          </FormControl> */}

            {currentSlots.length > 0 ? (
              <List style={{ maxHeight: "300px", overflow: "auto" }}>
                {currentSlots.map((slot: any) => {
                  const bookable =
                    slot.nbrofbookings < slot.slotcount &&
                    !slot.slotstatus &&
                    !moment(slot.slottime).isBefore(
                      moment().subtract("0", "day")
                    );
                  if (!bookable) {
                    return "";
                  }

                  return (
                    <ListItem key={slot.id} style={{ color: "#5c5c5c" }}>
                      <RadioGroup>
                        <FormControlLabel
                          value={slot}
                          control={
                            <Radio
                              color="primary"
                              checked={selectedValue === slot.id}
                              onChange={(e) => handleSlot(e.target.value, slot)}
                              value={slot}
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "A" }}
                            />
                          }
                          label={moment(slot.slottime).format(
                            "YYYY-MM-DD - HH:mm"
                          )}
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
                <List>
                  <Typography variant="subtitle2" style={{ marginRight: "22px" }}>
                    No tours to display this date
                  </Typography>
                </List>
              )}
          </div>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "30px",
          }}
        >
          <Typography style={{alignSelf: 'center', color:'red'}}>{slotErrorMsg}</Typography>
          <div>
          <Button
            onClick={() => {
              props.prevStep();
              props.showWelcomeMessageFn(true);
            }}
            className={classes.buttons}
            variant="contained"
            color="primary"
          >
            Back
          </Button>
          <Button
            className={classes.buttons}
            disabled={!slot}
            onClick={handleNext}
            variant="contained"
            color="primary"
          >
            Next
          </Button>
          </div>
        </div>
        <Divider />
      </div>
    );
  } else {
    return (
      <>
        <CircularProgress />
      </>
    );
  }
};

export default TimeAndLocation;
