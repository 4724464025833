import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
      left: "50%",
      transform: "translate(-50%)",
    },
    showBookings: {
      marginRight: "10px",
      color: "white",
      "&:hover": {
        backgroundColor: "#0084d6",
      },
    },
    anonymizeBookings: {
      marginRight: "10px",
      // color: "white",
      backgroundColor: "#c10000",
      "&:hover": {
        backgroundColor: "#d60000",
      },
    },
    loadingLinear: {
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    formControl: {
      minWidth: 120,
    },
    table: {
      minWidth: 650,
    },
    tableHeader: {
      backgroundColor: "#f5f5f5",
    },
  })
);

export default useStyles;