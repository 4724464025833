import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    label: {
      "&$focusedLabel": {
        color: "#0077c1",
      },
      "&$erroredLabel": {
        /* color: "orange" */
      },
    },
    inputLabel: {
      "&.Mui-focused": {
        color: "#0077c1",
      },
    },
    focusedLabel: {},
    erroredLabel: {},
    underline: {
      "&$error:after": {
        /*  borderBottomColor: "orange" */
      },
      "&:after": {
        borderBottom: `2px solid #0077c1`,
      },
      "&:before": {
        borderColor: "#0077c1",
      },
    },
    loadingLinear: {
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    heading: {},
    newQuestionWrapper: {
      marginBottom: "10px",
    },
    saveQuestionWrapper: {
      marginTop: "10px",
    },
    buttons: {
      color: "white",
      backgroundColor: "#0077c1",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#0084d6",
      },
    },
  })
);

export default useStyles