import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingLinear: {
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    label: {
      "&$focusedLabel": {
        color: "#0077c1",
      },
      "&$erroredLabel": {
        /* color: "orange" */
      },
    },
    focusedLabel: {},
    erroredLabel: {},
    underline: {
      "&$error:after": {
        /*  borderBottomColor: "orange" */
      },
      "&:after": {
        borderBottom: `2px solid #0077c1`,
      },
      "&:before": {
        borderColor: "#0077c1",
      },
    },
    textFieldDate: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
    circularProgress: {
      marginRight: "22px",
    },
    tableContainer: {},
    tableHeader: {
      backgroundColor: "#f5f5f5",
    },
    refreshButton: {
      color: "#0077c1",
    },
    refreshAndLocationWrapper: {
      display: "flex",
      width: "330px",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    underlineSelect: {
      "&:before": {
        borderColor: "#0077c1",
      },
      "&:after": {
        borderColor: "#0077c1",
      },
    },
    inputLabel: {
      "&.Mui-focused": {
        color: "#0077c1",
      },
    },
    iconButton: {
      padding: theme.spacing(0),
      color: "#deb39e",
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(6),
      marginTop: theme.spacing(6),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    table: {
      minWidth: 650,
    },
    tableHeaderWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginTop: "20px",
    },
    newBookingButton: {
      color: "white",
      backgroundColor: "#0077c1",
      "&:hover": {
        backgroundColor: "#0084d6"
      },
      fontSize: "18px",
    },

    formControl: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      minWidth: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    attendButton: {
      color: "#3ea354",
    },
    notAttendButton: {
      color: "#ff4f4f",
    },
  })
);

export default useStyles