// @ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Snackbar,
  Button,
  Tooltip,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';

import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import { checkValidEmail } from 'utilities/validation';
import {
  insertBodyIntoBaseTemplate,
  replaceMeetingLinkBeforeRendering,
  validateTemplate,
} from '../services/email';
import { UserFetch, UseUser } from '../context/UserContext';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainSection: {
    margin: "1rem 0",
    display: 'flex',
    flexDirection: 'column',
  },
  headerSection: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    '&.MuiTypography-h5': {
      marginBottom: "0.25rem",
      marginTop: "1rem",
    },
    '&.MuiTypography-h6': {
      marginRight: "1rem",
    },
  },
  creationButton: {
    margin: '1rem 0',
    backgroundColor: '#0077c1',
    color: 'white',
    '&:hover': {
      backgroundColor: '#0089de',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  lastBody: {
    marginBottom: "1.5rem",
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
    borderTop: '1px solid #efefef',
  },
  column: {
    margin: "0 1rem",
    flexBasis: '33.33%',
  },
  helper: {
    flexBasis: '33.33%',
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const CreateLocation = ({ updateHeaderTitle }) => {
  const classes = useStyles();
  const [locationName, setLocationName] = useState('');
  const [adminEditors, setAdminEditors] = useState([]);
  const [adminReaders, setAdminReaders] = useState([]);

  const [currentAdminEditor, setCurrentAdminEditor] = useState('');
  const [currentAdminReader, setCurrentAdminReader] = useState('');

  const [meetingLink, setMeetingLink] = useState('');
  const [onlineEditorState, setOnlineEditorState] = useState();
  const [testEmail, setTestEmail] = useState('');
  const [user, setUser] = useState({});
  const [testEmailErrors, setTestEmailErrors] = useState([]);

  const [loadingTestEmail, setLoadingTestEmail] = useState(false);
  const [loadingLocationCreation, setLoadingLocationCreation] = useState(false);
  const [toastSuccessMessage, setToastSuccessMessage] = useState('');
  const [toastFailureMessage, setToastFailureMessage] = useState('');

  const [created, setCreated] = useState(false);

  const [errors, setErrors] = useState({
    location: '',
    editor: '',
    reader: '',
    meetingLink: '',
    onlineEditorState: '',
  });

  const fetchUserData = useContext(UserFetch);

  const getUser = async () => {
    const userdata = await UseUser();
    setUser(userdata);
  };

  getUser();

  useEffect(() => {
    if(Object.keys(user).length < 1) return;
    if(adminEditors.includes(user.username)) return;
    setAdminEditors([...adminEditors, user.username]);
  }, [user]);

  useEffect(() => {
    let errorMessage = '';
    for (const key in errors) {
      if (errors[key]) errorMessage += `${errors[key]}\n`;
    }

    if (Boolean(errorMessage)) setToastFailureMessage(errorMessage);
  }, [errors]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        '/admin/email/template?template=online&defaultTemplate=true'
      );

      const replacedTemplate = replaceMeetingLinkBeforeRendering(data.template);
      const contentBlock = htmlToDraft(replacedTemplate);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setOnlineEditorState(EditorState.createWithContent(contentState));
      }
    })();
  }, []);

  const sendTestEmail = async () => {
    try {
      setLoadingTestEmail(true);
      const bodyHtml = draftToHtml(
        convertToRaw(onlineEditorState.getCurrentContent())
      );
      const newTemplateHtml = insertBodyIntoBaseTemplate(meetingLink, bodyHtml);

      const templateValidationErrors = validateTemplate(
        bodyHtml,
        locationName,
        meetingLink
      );
      if (!testEmail) {
        templateValidationErrors.push(
          'Enter an email to receive the preview email.'
        );
      } else if (!checkValidEmail(testEmail)) {
        templateValidationErrors.push(
          'Invalid email. Enter a valid Ingka email.'
        );
      }
      if (templateValidationErrors.length) {
        setLoadingTestEmail(false);
        setTestEmailErrors(templateValidationErrors);
        return;
      } else {
        setTestEmailErrors([]);
      }

      const templateData = {
        testEmail: testEmail,
        meetingLink: meetingLink,
        locationName: locationName,
        templateHtml: newTemplateHtml,
      };

      await axios.post('/admin/email/preview', templateData);
      setToastSuccessMessage(`Preview sent to ${testEmail}.`);
    } catch (error) {
      if (error.response.status === 422) {
        setToastFailureMessage(
          'Invalid template, make sure your keywords are valid. e.g. {{date}}'
        );
      } else {
        setToastFailureMessage('Something unexpected happened...');
      }
    }
    setLoadingTestEmail(false);
  };

  const validateInputForCreation = () => {
    let passed = true;
    let _errors = { ...errors };

    if (!Boolean(locationName)) {
      passed = false;
      _errors = { ..._errors, location: 'Missing location name.' };
    } else {
      _errors = { ..._errors, location: '' };
    }

    if (adminEditors.length < 1) {
      passed = false;
      _errors = {
        ..._errors,
        editor: 'There must be at least one admin editor per location.',
      };
    } else {
      _errors = { ..._errors, editor: '' };
    }

    const bodyHtml = draftToHtml(
      convertToRaw(onlineEditorState.getCurrentContent())
    );

    //Can't do string comparison because an empty template will look like "<p></p>" with some hidden unicode...
    if (bodyHtml.length === 8) {
      passed = false;
      _errors = {
        ..._errors,
        onlineEditorState: 'Email template can not be empty.',
      };
    } else {
      _errors = { ..._errors, onlineEditorState: '' };
    }

    setErrors(_errors);

    return passed;
  };

  const createLocation = async () => {
    if (!validateInputForCreation()) return;
    setLoadingLocationCreation(true);

    const bodyHtml = draftToHtml(
      convertToRaw(onlineEditorState.getCurrentContent())
    );
    const newTemplateHtml = insertBodyIntoBaseTemplate(meetingLink, bodyHtml);

    const body = {
      name: locationName,
      admineditors: adminEditors,
      adminreaders: adminReaders,
      defaultmeetinglink: meetingLink,
      country: user.country,
      template: newTemplateHtml,
    };

    await axios.post('/admin/locations', body);
    await fetchUserData();
    setLoadingLocationCreation(false);
    setToastSuccessMessage(
      `Location created for ${locationName}.`
    );
    setCreated(true);
  };

  const onMeetingLinkChange = (e) => {
    setMeetingLink(e.target.value);
  };

  const onEditorStateChange = (editorState) => {
    //debounce?
    setOnlineEditorState(editorState);
  };

  const onChangeAdminEditors = (e) => {
    setCurrentAdminEditor(e.target.value);
  };

  const onChangeAdminReaders = (e) => {
    setCurrentAdminReader(e.target.value);
  };

  const addAdminEditor = () => {
    if (!checkValidEmail(currentAdminEditor))
      return setErrors({
        ...errors,
        editor: 'Invalid email. Enter a valid Ingka email.',
      });
    if (adminEditors.includes(currentAdminEditor))
      return setErrors({ ...errors, editor: 'Email already added.' });

    setErrors({ ...errors, editor: '' });

    setAdminEditors([...adminEditors, currentAdminEditor]);
    setCurrentAdminEditor('');
  };

  const addAdminReader = () => {
    if (!checkValidEmail(currentAdminReader))
      return setErrors({
        ...errors,
        reader: 'Invalid email. Enter a valid Ingka email.',
      });
    if (adminReaders.includes(currentAdminReader))
      return setErrors({ ...errors, reader: 'Email already added.' });

    setErrors({ ...errors, reader: '' });

    setAdminReaders([...adminReaders, currentAdminReader]);
    setCurrentAdminReader('');
  };

  const enterKeyAddAdminEditor = (e) => {
    if (e.keyCode === 13) addAdminEditor();
  };

  const enterKeyAddAdminReader = (e) => {
    if (e.keyCode === 13) addAdminReader();
  };

  const removeAdminEditor = (email) => {
    if (adminEditors.length === 1)
      return setErrors({
        ...errors,
        editor: 'There must be at least one admin editor per location.',
      });
    const filteredEditors = adminEditors.filter((admin) => admin !== email);
    setAdminEditors(filteredEditors);
  };

  const removeAdminReader = (email) => {
    const filteredReaders = adminReaders.filter((admin) => admin !== email);
    setAdminReaders(filteredReaders);
  };

  useEffect(() => {
    updateHeaderTitle('Create location');
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className={classes.header} variant="h5">
              Location name
            </Typography>
            <Typography className={classes.lastBody} variant="body2">
              Enter the name of the location you're creating.
            </Typography>
          </div>
        </div>
        <TextField
          onChange={(e) => setLocationName(e.target.value)}
          id="name"
          label="Location name"
          placeholder="Enter location name"
          variant="filled"
          error={Boolean(errors.location)}
          helperText={Boolean(errors.location) && errors.location}
          required
        />
      </div>

      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className={classes.header} variant="h5">
              Permissions
            </Typography>
            <Typography variant="body2">
              Assign admin edtiors & admin readers to your new location.
            </Typography>
            <Typography className={classes.lastBody} variant="body2">
              Add one or more co-worker(s) by entering their email(s) and
              adding them individually.
            </Typography>
          </div>
        </div>
        <div className={classes.headerSection}>
          <Typography className={classes.header} variant="h6">
            Admin Editors
          </Typography>
          <Tooltip
            title='An "Admin Editor" is allowed do do everyting in the system. An admin editor is allowed set and change permissions, create and edit the tours and change all the location settings.'
            placement="top-start"
          >
            <HelpIcon style={{ color: '#828282' }} />
          </Tooltip>
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: "1rem" }}>
          <TextField
            value={currentAdminEditor}
            style={{ width: 'inherit' }}
            id="admin-editor-email"
            label="Co-worker email"
            placeholder="Enter co-worker email"
            onKeyDown={enterKeyAddAdminEditor}
            onChange={onChangeAdminEditors}
            error={Boolean(errors.editor)}
            helperText={Boolean(errors.editor) && errors.editor}
            variant="filled"
            required
          />
          <Button
            onClick={addAdminEditor}
            className={classes.creationButton}
            style={{ width: '100px', margin: '0 0 0 1rem', height: '56px' }}
          >
            Add
          </Button>
        </div>
        <List style={{ marginBottom: '1rem', width: '30%' }}>
          {adminEditors.map((email) => {
            return (
              <ListItem
                key={email}
                divider={true}
              >
                <ListItemText primary={email} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="start"
                    aria-label="delete"
                    onClick={() => removeAdminEditor(email)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <div className={classes.headerSection}>
          <Typography className={classes.header} variant="h6">
            Admin Readers
          </Typography>
          <Tooltip
            title='An "Admin Reader" is only allowed do see the tours that has been created. An admin reader is not allowed to change permissions, tours or location settings.'
            placement="top-start"
          >
            <HelpIcon style={{ color: '#828282' }} />
          </Tooltip>
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: "1rem" }}>
          <TextField
            value={currentAdminReader}
            style={{ width: 'inherit' }}
            id="admin-reader-email"
            label="Co-worker email"
            error={Boolean(errors.reader)}
            placeholder="Enter co-worker email"
            onKeyDown={enterKeyAddAdminReader}
            onChange={onChangeAdminReaders}
            helperText={Boolean(errors.reader) && errors.reader}
            variant="filled"
          />
          <Button
            onClick={addAdminReader}
            className={classes.creationButton}
            style={{ width: '100px', margin: '0 0 0 1rem', height: '56px' }}
          >
            Add
          </Button>
        </div>
        <List style={{ width: '30%' }}>
          {adminReaders.map((email) => {
            return (
              <ListItem
                key={email}
                divider={true}
              >
                <ListItemText primary={email} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeAdminReader(email)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>

      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className={classes.header} variant="h5">
              Default meeting link
            </Typography>
            <Typography variant="body2">
              Enter a meeting link for all online tours to use per default.
            </Typography>
            <Typography className={classes.lastBody} variant="body2">
            Enter a valid URL or leave this empty if you want to add it
              retroactively under location settings.
            </Typography>
          </div>
        </div>
        <TextField
          onChange={onMeetingLinkChange}
          multiline
          id="meeting-link"
          label="Meeting link"
          placeholder="Enter your meeting link"
          error={Boolean(errors.meetingLink)}
          helperText={
            Boolean(errors.meetingLink) && <span>{errors.meetingLink}</span>
          }
          variant="filled"
        />
      </div>

      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className={classes.header} variant="h5">
              Email
            </Typography>
            <Typography variant="body2">
                This editor contains a template that resembles the email that
                are sent out to co-workers after they have signed up for a tour.
                Adjust it to your liking and preview it by entering your email
                below the editor and sending it, the email will be based on what
                is currently displayed.
            </Typography>
            <Typography variant="body2">
              This can be changed retroactively under location settings.
            </Typography>
            <Typography style={{marginTop: "1rem"}} className={classes.header} variant="h6">
              Dynamic keywords
            </Typography>
            <Typography variant="body2">
              By using the following keywords in the template below, you can add
              information to the template that may differ from email to email,
              such as when a tour is being held.
            </Typography>
            <Typography className={classes.lastBody} variant="body2">
              These are optional but can be used to display useful information
              for the participant.
            </Typography>
            <Accordion defaultExpanded style={{ marginBottom: '1rem' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div className={classes.column}>
                  <Typography className={classes.heading}>Keywords</Typography>
                </div>
                <div className={classes.column}>
                  <Typography variant="body2">Description</Typography>
                </div>
                <div className={classes.column}>
                  <Typography variant="body2">Example</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.column}>
                  {'{{location}}'}
                </Typography>
                <Typography className={classes.column} variant="body2">
                  Where the tour is being held.
                </Typography>
                <Typography className={classes.column} variant="body2">
                  Hubhult Meetingplace
                </Typography>
                <div style={{ width: '48px', height: '48px' }} />
              </AccordionDetails>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.column}>{'{{date}}'}</Typography>
                <Typography className={classes.column} variant="body2">
                  When the tour is being held.
                </Typography>
                <Typography className={classes.column} variant="body2">
                  2022-08-18 - 10:00
                </Typography>
                <div style={{ width: '48px', height: '48px' }} />
              </AccordionDetails>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.column}>
                  {'{{meetinglink}}'}
                </Typography>
                <Typography className={classes.column} variant="body2">
                  Hyperlink taking the co-worker to where the meeting will take
                  place, e.g. teams link.
                </Typography>
                <Typography className={classes.column} variant="body2">
                  <a href="https://example.com">
                    Click here to join the meeting
                  </a>
                </Typography>
                <div style={{ width: '48px', height: '48px' }} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <Editor
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'embedded',
              'emoji',
              'image',
              'remove',
              'history',
            ],
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 24, 30, 36, 48, 60, 72,
                96,
              ],
            },
          }}
          editorState={onlineEditorState}
          toolbarStyle={{ border: 0 }}
          wrapperStyle={{
            backgroundColor: '#efefef',
            border: '1px solid #efefef',
          }}
          editorStyle={{ padding: '1rem' }}
          onEditorStateChange={onEditorStateChange}
        />
      </div>
      <div style={{ margin: '1rem 0', display: 'flex', width: '100%' }}>
        <TextField
          style={{ width: 'inherit', marginRight: '1rem' }}
          onChange={(e) => setTestEmail(e.target.value)}
          id="test-email"
          label="Email"
          placeholder="Enter email to receive test email"
          error={testEmailErrors.length > 0}
          helperText={
            testEmailErrors &&
            testEmailErrors.length > 0 && (
              <>
                {testEmailErrors.map((error, i) => {
                  return (
                    <span key={i}>
                      {error}
                      <br />
                    </span>
                  );
                })}
              </>
            )
          }
        />
        <div style={{ display: 'flex', width: '210px', alignItems: 'center' }}>
          <Button
            style={{ width: '210px', height: '37px', marginRight: '1rem' }}
            className={classes.creationButton}
            onClick={sendTestEmail}
            variant="contained"
            disabled={loadingTestEmail ? true : false}
          >
            Send test email
          </Button>
          <div style={{ width: '24px', height: '24px' }}>
            {loadingTestEmail ? <CircularProgress size={24} /> : null}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', width: '240px', alignItems: 'center' }}>
        <Button
          style={{ width: '180px', marginRight: "1rem" }}
          className={classes.creationButton}
          onClick={createLocation}
          variant="contained"
          disabled={created || loadingLocationCreation ? true : false}
        >
          Create location
        </Button>
        <div style={{ width: '24px', height: '24px' }}>
          {loadingLocationCreation ? <CircularProgress size={24} /> : null}
        </div>
      </div>

      <Snackbar
        open={Boolean(toastSuccessMessage)}
        autoHideDuration={10000}
        onClose={() => setToastSuccessMessage('')}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setToastSuccessMessage('')}
          severity="success"
        >
          {toastSuccessMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={Boolean(toastFailureMessage)}
        autoHideDuration={10000}
        onClose={() => setToastFailureMessage('')}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setToastFailureMessage('')}
          severity="error"
        >
          {toastFailureMessage.split('\n').map((message, i) => {
            return <div key={i}>{message}</div>;
          })}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CreateLocation;
