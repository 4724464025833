import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  link: {
    color: '#0077c1', 
    textDecoration: 'none',
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      color: "#005285",
    },
  },
 
}),
);

export default useStyles